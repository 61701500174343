import React from 'react'

// * Redux
import { addSubscriptions } from 'store/stripeSlice'
import { useAppDispatch } from 'store/configureStore'

// * Components
import MonthlyPopup from '..'
import { useAppSelector } from 'store/combinedReducer'

export default function MonthlyPopupComp({ onClose }) {
	const [subscriptionAmount, setSubscriptionAmount] = React.useState('')
	const dispatch = useAppDispatch()
	const stripe = useAppSelector(state => state.stripe)

	return (
		<MonthlyPopup onClose={onClose}>
			<MonthlyPopup.Title>Activate Fixed Monthly</MonthlyPopup.Title>
			<MonthlyPopup.Text>Contribute a monthly amount to your selected cause from your card ending in *{stripe.card.last4 || 'XXXX'}</MonthlyPopup.Text>
			<MonthlyPopup.Select>
				<MonthlyPopup.Option active={subscriptionAmount === '25'} onClick={() => setSubscriptionAmount('25')}>
					25
				</MonthlyPopup.Option>
				<MonthlyPopup.Option active={subscriptionAmount === '50'} onClick={() => setSubscriptionAmount('50')}>
					50
				</MonthlyPopup.Option>
				<MonthlyPopup.Option active={subscriptionAmount === '100'} onClick={() => setSubscriptionAmount('100')}>
					100
				</MonthlyPopup.Option>
			</MonthlyPopup.Select>

			<MonthlyPopup.Input
				value={subscriptionAmount}
				onChange={({ target: { value } }) => {
					if (value === '' || /^((?!(0))[0-9]{1,3})$/.test(value)) {
						setSubscriptionAmount(value)
					}
				}}
			/>

			<MonthlyPopup.Button
				disabled={subscriptionAmount.length === 0}
				onClick={() => {
					dispatch(addSubscriptions(subscriptionAmount))
					onClose()
				}}
			>
				Confirm Amount
			</MonthlyPopup.Button>
		</MonthlyPopup>
	)
}
