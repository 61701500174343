import React from 'react'

// * Styles
import { Container, Title, Text, ButtonWrapper, Button } from '../globalStyled'
import { InputWrapper } from './styled'

export default function StripePopup({ onClose, children, ...props }) {
	return (
		<Container data-testid="stripe-popup-container" {...props}>
			<div className="backdrop" onClick={onClose}></div>
			<div className="card">{children}</div>
		</Container>
	)
}

StripePopup.Title = function StripePopupTitle({ children, ...props }) {
	return (
		<Title data-testid="stripe-popup-title" {...props}>
			{children}
		</Title>
	)
}

StripePopup.Text = function StripePopupText({ children, ...props }) {
	return (
		<Text data-testid="stripe-popup-text" {...props}>
			{children}
		</Text>
	)
}

StripePopup.ButtonWrapper = function StripePopupButtonWrapper({ children, ...props }) {
	return (
		<ButtonWrapper data-testid="stripe-popup-button-wrapper" {...props}>
			{children}
		</ButtonWrapper>
	)
}

StripePopup.Button = function StripePopupButton({ children, ...props }) {
	return (
		<Button data-testid="stripe-popup-button" {...props}>
			{children}
		</Button>
	)
}

StripePopup.InputWrapper = function StripePopupInputWrapper({ children, ...props }) {
	return (
		<InputWrapper data-testid="stripe-popup-input-wrapper" {...props}>
			{children}
		</InputWrapper>
	)
}
