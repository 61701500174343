import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

// IndividualFundPick
export const Square = styled.div<{ color: string; active: boolean; src: string }>`
	position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: flex-start;
	padding: 15px 10px;
	border-radius: 12px;
	min-height: 100px;
	background-color: var(--COLOR_SECONDARY_NEUTRAL);
	user-select: none;

	cursor: pointer;

	${({ active, color }) =>
		active &&
		`
outline: 2px solid ${color};
background-color: var(--COLOR_GREY_LIGHT);
box-shadow: var(--SHADOW_SOFT);
`}

	:hover {
		/* opacity: 1; */
		background-color: var(--COLOR_GREY_LIGHT);
		transition: scale ease 300ms, box-shadow ease 300ms;

		#content #header #logo {
			scale: 1.1;
			opacity: 1;
		}

		#tag {
			scale: 1.05;
		}
	}

	#tag {
		transition: scale ease 300ms, box-shadow ease 300ms;
		transition-delay: 100ms;
		scale: 1;
		position: absolute;
		top: 100%;
		margin-top: -10px;
		font-size: var(--TYPE_XSP);
		border-radius: 20px;
		padding: 10px 12px;
		font-weight: 600;

		line-height: 0px;

		color: var(--COLOR_PRIMARY);
		background-color: var(--COLOR_WHITE);
		box-shadow: var(--SHADOW_SOFT);

		${({ active, color }) =>
			active &&
			`
background-color: ${color};
box-shadow: none;
color: var(--COLOR_WHITE);
`}
	}

	#content {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px;
		height: 100%;
		justify-content: flex-start;
		width: 100%;

		#header {
			position: relative;
			display: flex;
			margin-bottom: 10px;
			width: 100%;
			justify-content: space-between;

			#logo {
				transition: scale ease 300ms, box-shadow ease 300ms;
				scale: 1;
				position: relative;
				border: solid 1.5px ${({ color }) => color};
				height: 32px;
				width: 32px;
				flex-shrink: 0;
				border-radius: 50%;
				margin-top: -6px;
				opacity: 0.8;

				${({ active }) => active && `opacity: 1;`}

				&:before {
					content: '';
					position: absolute;
					top: 2px;
					bottom: 2px;
					left: 2px;
					right: 2px;
					border-radius: 100%;
					box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
					background-image: url(${({ src }) => src});
					background-position: 50% 50%;
					background-size: cover;
					background-repeat: no-repeat;
				}
			}

			#title {
				position: relative;
				font-size: var(--TYPE_SP);
				line-height: 1.25em;
				white-space: pre-line;
				font-weight: 500;

				& img {
					width: 15px;
					height: 15px;
					display: inline;
					margin-bottom: -3px;
				}

				& a {
					:hover {
						text-decoration: underline;
						cursor: alias;
					}
				}
			}
		}

		#description {
			position: relative;
			line-height: 1.25em;
			font-size: var(--TYPE_XSP);
			color: var(--COLOR_GREY_DARK);
			text-align: center;
			padding-bottom: 10px;
		}
	}
`

// IndividualFundPick
export const SquareWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 20px;
	grid-column-gap: 10px;
	width: 110%;
	align-self: center;

	.tooltip {
		color: #fff;
		background-color: #333838;
		padding: 3px 10px;
		font-size: 12px;
	}

	@media (max-width: ${BREAK._768}) {
		width: 100%;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: ${BREAK._395}) {
		width: 100%;
		grid-template-columns: repeat(1, 1fr);
	}
`
