import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.div``

export const Heading = styled.h3`
	margin-bottom: 20px;
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`

export const Item = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	align-items: center;
	min-height: 96px;
	width: 100%;
	flex-grow: 1;
	background-color: var(--COLOR_WHITE);
	box-shadow: var(--SHADOW_SOFT);
	border-radius: 12px;
	padding: 20px;

	${({ status }) =>
		status === 'DISCONNECTED' &&
		`
			border: 2px solid var(--COLOR_RED);
			background-color: var(--COLOR_RED_BG);
			padding: 15px 20px;
		`}

	& > button {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		opacity: 0;
		z-index: 1;
	}

	${({ cursorPointer }) =>
		cursorPointer &&
		`cursor: pointer;
		
		:hover {
		opacity: 0.8;
		transition: 300ms;
	}`}
`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`

export const Leading = styled.div`
	svg {
		width: 46px;
	}
	svg path {
		${({ status }) => (status === 'DISCONNECTED' ? `fill: var(--COLOR_RED);` : `fill: var(--COLOR_PRIMARY);`)}
	}
	@media (max-width: ${BREAK._1100}) {
		svg {
			width: 40px;
		}
	}
`

export const Trailing = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	height: 40px;
	width: 40px;
	border-radius: 100%;
	margin-left: auto;
	cursor: pointer;

	${({ status }) => (status === 'DISCONNECTED' ? `background-color: var(--COLOR_RED);` : `background-color: var(--COLOR_GREY_LIGHT);`)}

	& svg {
		width: 100%;
		${({ status }) => (status === 'DISCONNECTED' ? `fill: var(--COLOR_WHITE);` : `fill: var(--COLOR_PRIMARY);`)}
	}

	@media (max-width: ${BREAK._1100}) {
		position: absolute;
		right: 20px;
	}

	@media (max-width: ${BREAK._1100}) {
		position: absolute;
		height: 30px;
		width: 30px;
		right: 10px;
	}
`

export const Group = styled.div`
	margin-left: 15px;

	@media (max-width: ${BREAK._1100}) {
		margin-left: 10px;
	}
`

export const Title = styled.p`
	font-size: var(--TYPE_MP);
	font-weight: 600;
	text-transform: capitalize;
	@media (max-width: ${BREAK._1100}) {
		font-size: var(--TYPE_SL);
	}
	${({ status }) => status === 'DISCONNECTED' && `color: var(--COLOR_RED);`}
`

export const Subtitle = styled.p`
	font-size: var(--TYPE_SP);
	font-weight: 500;
	max-width: 300px;
	white-space: nowrap;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	@media (max-width: ${BREAK._1100}) {
		white-space: pre-wrap;
	}
	${({ status }) => (status === 'DISCONNECTED' ? `color: var(--COLOR_RED);` : `color: var(--COLOR_GREY_DARK);`)}
`

export const NestedLink = styled.p`
	font-size: var(--TYPE_SP);
	font-weight: 500;
	max-width: 200px;
	color: var(--COLOR_RED_TEXT);
`

export const Separator = styled.div`
	position: relative;
	justify-self: center;
	width: calc(100% + 40px);
	height: 2px;
	background-color: var(--COLOR_GREY);
	opacity: 0.3;
`

export const Footer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: 36px;

	@media (max-width: ${BREAK._318}) {
		max-height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
	}
`

export const SubscriptionDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`

export const SubscriptionInfo = styled.div`
	font-size: var(--TYPE_SP);
	font-weight: 600;
	color: var(--COLOR_PRIMARY);
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 16px;
	gap: 8px;
	@media (max-width: ${BREAK._318}) {
		justify-content: center;
	}
`

export const SubscriptionAmount = styled.div`
	font-family: var(--FONT_ZARK);
	font-size: var(--TYPE_H2);
	padding-top: 5px;

	.dollar {
		color: var(--COLOR_GREY);
	}
`

export const Dropdown = styled.div`
	position: absolute;
	z-index: 100;
	right: 3px;

	margin-top: -70px;
	width: 240px;

	#dropdown-background {
		position: relative;
		top: 70px;
		margin-bottom: 20px;
		padding: 5px;
		border-radius: 12px;
		height: 100%;
		box-shadow: var(--SHADOW_SOFT);
		background-color: var(--COLOR_WHITE);
		display: grid;
		gap: 3px;
		z-index: 0;
	}
`

export const DropdownLine = styled.div`
	position: relative;
	justify-self: center;
	width: calc(100% + 10px);
	height: 2px;
	background-color: var(--COLOR_GREY);
	opacity: 0.3;
`

export const DropdownLink = styled.div`
	position: relative;
	height: 52px;
	padding: 15px;
	font-size: var(--TYPE_SL);
	font-weight: 600;
	border-radius: 10px;
	text-transform: capitalize;

	& a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	:hover {
		background-color: var(--COLOR_SECONDARY_LIGHT);
	}
`

export const Warning = styled.div`
	background-color: var(--COLOR_RED_BG);
	padding: 15px 20px;
	color: var(--COLOR_RED_TEXT);
	border: 1px solid var(--COLOR_RED);
	font-size: var(--TYPE_SP);
	line-height: 1.4em;

	& > button {
		border: none !important;
		background-color: transparent !important;
		padding: 0 !important;
		font-size: inherit !important;
		color: inherit !important;
		font-weight: 600;
		text-decoration: underline;
		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}

	a {
		font-weight: 600;
		text-decoration: underline;

		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Button = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	font-size: var(--TYPE_MP);
	font-weight: 500;
	gap: 7px;
	opacity: 1;
	cursor: pointer;
	transition: all 100ms ease-in-out;

	& svg path {
		fill: var(--COLOR_RED_TEXT);
	}

	& > button {
		border: none !important;
		background-color: transparent !important;
		padding: 0 !important;
		font-size: var(--TYPE_SP);
		font-weight: 600;
		color: var(--COLOR_RED_TEXT);
		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}

	:hover {
		transition: all 100ms ease-in-out;
		gap: 10px;
		opacity: 0.8;
	}

	@media (max-width: ${BREAK._1100}) {
		font-size: var(--TYPE_SL);
	}
`

export const InstitutionLogo = styled.div`
	width: 40px;
	height: 46px;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	flex-shrink: 0;
`
