import React from 'react'

// * Redux
import { removeSubscriptions } from 'store/stripeSlice'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Card from '..'
import { MonthlyPopupComp } from 'components/dashboard/cards'
import { useAppSelector } from 'store/combinedReducer'

export default function SubscriptionComp({ monthlyPopupIsOpen, setMonthlyPopupIsOpen, setStripePopupIsOpen }) {
	const dispatch = useAppDispatch()
	const stripe = useAppSelector(state => state.stripe)

	return (
		<>
			{monthlyPopupIsOpen && <MonthlyPopupComp onClose={() => setMonthlyPopupIsOpen(false)} />}
			<Card>
				<Card.Heading>Monthly Payments</Card.Heading>
				<Card.Wrapper>
					{stripe.subscriptionsLoading ? (
						<Card.Loading />
					) : (
						<Card.Item
							cursorPointer={stripe.subscriptions.isActive || stripe.subscriptionsLoading ? false : true}
							onClick={() => {
								!stripe.subscriptions.isActive && !stripe.subscriptionsLoading && (stripe.card.id ? setMonthlyPopupIsOpen(true) : setStripePopupIsOpen(true))
							}}
						>
							<Card.Header>
								<Card.Leading icon="card_repeat" />
								<Card.Group>
									<Card.Title>{!stripe.subscriptions.isActive && 'Activate '}Fixed Monthly</Card.Title>
									<Card.Subtitle>
										{!stripe.subscriptions.isActive
											? !stripe.card.id
												? ''
												: `Will be charged from card *${stripe.cardLoading ? `XXXX` : `${stripe.card.last4}`}`
											: `From card ending in *${stripe.cardLoading ? `XXXX` : `${stripe.card.last4}`}`}
									</Card.Subtitle>
								</Card.Group>
								{stripe.subscriptions.isActive ? (
									<Card.Trailing hover circle>
										<Card.Dropdown>
											<Card.DropdownLink
												onClick={() => {
													dispatch(removeSubscriptions())
												}}
											>
												Deactivate
											</Card.DropdownLink>
										</Card.Dropdown>
									</Card.Trailing>
								) : (
									<Card.Trailing />
								)}
							</Card.Header>

							{stripe.subscriptionsLoading && (
								<>
									<Card.Separator />
									<Card.Footer>
										<div></div>
										<Card.ThreeLoading />
										<div></div>
									</Card.Footer>
								</>
							)}

							{stripe.subscriptions.isActive && (
								<>
									<Card.Separator />
									<Card.Footer>
										<Card.SubscriptionDetails>
											<Card.SubscriptionInfo icon={'/images/icons/icon-small-card.svg'}>
												Active Card: *{stripe.cardLoading ? `XXXX` : `${stripe.card.last4}`}
											</Card.SubscriptionInfo>
											<Card.SubscriptionInfo icon={'/images/icons/icon-small-repeat.svg'}>Cycle: {stripe.subscriptions.renews}</Card.SubscriptionInfo>
										</Card.SubscriptionDetails>
										<Card.SubscriptionAmount>{stripe.subscriptions.amount}</Card.SubscriptionAmount>
									</Card.Footer>
								</>
							)}
						</Card.Item>
					)}
				</Card.Wrapper>
			</Card>
		</>
	)
}
