import React from 'react'

// * Constants
import { STORAGE_KEYS } from 'constants/storageKeys'

// * Styles
import * as S from './styled'

export default function Dash({ children, ...props }) {
	return (
		<S.Section data-testid="dash-section" {...props}>
			{children}
		</S.Section>
	)
}

Dash.Maintenance = function DashMaintenance({ children, ...props }) {
	const [maintenancePopup, setMaintenancePopup] = React.useState(sessionStorage.getItem(STORAGE_KEYS.SHOW_MAINTENANCE_MESSAGE) || 'show')
	React.useEffect(() => {
		sessionStorage.setItem(STORAGE_KEYS.SHOW_MAINTENANCE_MESSAGE, maintenancePopup)
	}, [maintenancePopup])
	return (
		maintenancePopup === 'show' && (
			<S.Maintenance
				data-testid="dash-maintenance"
				title="Close"
				onClick={() => {
					setMaintenancePopup('hide')
				}}
				{...props}
			>
				<p>{children}</p>
			</S.Maintenance>
		)
	)
}

Dash.Contain = function DashContain({ children, ...props }) {
	return (
		<S.Contain data-testid="dash-contain" {...props}>
			{children}
		</S.Contain>
	)
}

Dash.Head = function DashHead({ children, ...props }) {
	return (
		<S.Head data-testid="dash-head" {...props}>
			{children}
		</S.Head>
	)
}

Dash.Body = function DashBody({ children, ...props }) {
	return (
		<S.Body data-testid="dash-body" {...props}>
			{children}
		</S.Body>
	)
}

Dash.Column = function DashColumn({ children, ...props }) {
	return (
		<S.Column data-testid="dash-column" {...props}>
			{children}
		</S.Column>
	)
}
