import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const Header = styled.header`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 20px;
`
export const Headline = styled.h1`
	margin-bottom: 15px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H2);
		margin-bottom: 6px;
	}
`
export const Description = styled.p`
	max-width: 625px;
	margin-bottom: 30px;
	padding: 0px 20px;
`

export const Main = styled.main`
	/* display: grid;
	width: 100%;
	max-width: 1000px;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, 280px);
	gap: 50px;
	flex-wrap: wrap; */
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	@media (max-width: ${BREAK._950}) {
		grid-template-columns: repeat(auto-fit, 280px);
	}
	max-width: 1000px;
	width: 100%;
	justify-content: center;
	gap: 50px;
	flex-wrap: wrap;
`
export const Member = styled.div`
	/* text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center; */
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (min-width: ${BREAK._950}) {
		:nth-child(1) {
			grid-row-start: 1;
			grid-column: 3 / span 4;
		}

		:nth-child(2) {
			grid-row-start: 1;
			grid-column: 7 / span 4;
		}

		:nth-child(3) {
			grid-row-start: 2;
			grid-column: span 4;
		}

		:nth-child(4) {
			grid-row-start: 2;
			grid-column: span 4;
		}

		:nth-child(5) {
			grid-row-start: 2;
			grid-column: span 4;
		}

		:nth-child(6) {
			grid-row-start: 3;
			grid-column: 3 / span 4;
		}

		:nth-child(7) {
			grid-row-start: 3;
			grid-column: 7 / span 4;
		}
	}
`

export const Stack = styled.div`
	display: flex;
	margin-bottom: 40px;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	& :nth-child(1) {
		margin-left: 0px;
	}
`

export const Slack = styled.div`
	display: flex;
	max-width: 600px;
	background-color: var(--COLOR_WHITE);
	padding: 50px 60px;
	border-radius: 20px;
	box-shadow: var(--SHADOW_SOFT);
	flex-direction: row;
	align-items: flex-start;
	text-align: left;
	gap: 50px;
	margin-bottom: -160px;

	img {
		width: 60px;
	}

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 15px;
	}

	@media (max-width: ${BREAK._768}) {
		flex-direction: column;
		text-align: center;
		gap: 20px;
		padding: 30px;
		align-items: center;

		margin-bottom: -80px;

		& > div {
			align-items: center;
		}
	}
`

export const StackImage = styled.div`
	position: relative;
	margin-left: -20px;
	width: 81px;
	height: 81px;
	align-self: center;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 4px white;
	border-radius: 50%;
	overflow: hidden;
	img {
		height: 100%;
		transform: scale(1.1);

		/* transform: translate(50	 50%); */
	}
`
export const Image = styled.div`
	position: relative;
	width: 150px;
	height: 150px;
	margin-bottom: 16px;
	align-self: center;
	box-shadow: var(--SHADOW_SOFT);
	border: solid 5px white;
	border-radius: 50%;
	overflow: hidden;
	img {
		height: 100%;
		transform: scale(1.1);
		/* transform: translate(50	 50%); */
	}
`
export const Name = styled.p`
	font-size: var(--TYPE_ML);
	text-transform: capitalize;
	font-weight: 600;
	margin-bottom: 5px;
`
export const Role = styled.p``

export const Industry = styled.p`
	margin-bottom: 10px;
`

export const Social = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;

	a {
		&:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Button = styled.button`
	font-size: var(--TYPE_ML);
	font-family: var(--FONT_ZARK);
	margin-bottom: 30px;
	padding: 0 40px;
	height: 80px;
	border-radius: 8px;
	background-color: ${({ outline, light }) => (light ? 'var(--COLOR_SECONDARY)' : outline ? 'var(--COLOR_WHITE)' : 'var(--COLOR_PRIMARY)')};
	color: ${({ outline, light }) => (light ? 'var(--COLOR_PRIMARY)' : outline ? 'var(--COLOR_PRIMARY)' : 'var(--COLOR_SECONDARY)')};
	border: ${({ outline }) => (outline ? 'solid 2px var(--COLOR_PRIMARY)' : '')};
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}
`
