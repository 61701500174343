import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

// * Redux
import configureStore from 'store/configureStore'

// * Constants
import * as ROUTES from 'constants/routes'

// * Utils
import { checkToken } from 'utils/setAuthToken'
import { RedirectToDashboard, RedirectToDashboardFromWizard, RedirectToLogin } from 'utils/privateRoute'
import { support_format_webp } from 'utils/checkBrowserSupportForWebp'

// * Components
import Homepage from 'components/pages/Homepage'
import Team from 'components/pages/Team'
import Register from 'components/pages/Register'
import Login from 'components/pages/Login'
import ResetPassword from 'components/pages/ResetPassword'
import Wizard from 'components/pages/Wizard'
import Dashboard from 'components/pages/Dashboard'
import CustomizedSnackbar, { SnackProvider } from 'components/global/Snackbar'
import { SnackbarProvider } from 'notistack'

const store = configureStore

console.log('App.js\t>>\t❔ CHECKING IF USER HAS TOKEN...')
checkToken() ? console.log(`App.js\t>>\t✅ USER HAS VALID TOKEN`) : console.log(`App.js\t>>\t❌ USER HAS NO TOKEN`)
support_format_webp()

export default function App() {
	return (
		<Provider store={store}>
			<SnackProvider>
				<CustomizedSnackbar />
				<Routes>
					<Route path={ROUTES.HOMEPAGE} element={<Homepage />} />
					<Route path={ROUTES.TEAM} element={<Team />} />
					<Route element={<RedirectToDashboard />}>
						<Route path={ROUTES.REGISTER} element={<Register />} />
						<Route path={ROUTES.LOGIN} element={<Login />} />
						<Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
					</Route>

					<Route element={<RedirectToLogin />}>
						<Route element={<RedirectToDashboardFromWizard />}>
							<Route path={ROUTES.WIZARD} element={<Wizard />} />
						</Route>
					</Route>
					<Route element={<RedirectToLogin />}>
						<Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
					</Route>
				</Routes>
			</SnackProvider>
		</Provider>
	)
}
