import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

// * Redux
import { getTotalRoundupTransactions, getRoundupTransactions, makeRoundupTransactionsPrettier } from 'store/plaidSlice'
import { makeMonthlyTransactionsPrettier, getTotalMonthlyTransactions } from 'store/stripeSlice'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Contributions from '../../Contributions'
import { TransactionsSkeletonWrapper } from '../../Contributions/styled'
import { useAppSelector } from 'store/combinedReducer'
import { TabToggle } from 'components/global'
import { getSubscriptionTransactions } from 'store/stripeSlice'

const OPTIONS = {
	ROUNDUP: 'Roundup',
	MONTHLY: 'Monthly',
}
export default function ContributionComp() {
	const [selectedOption, setSelectedOption] = React.useState(OPTIONS.ROUNDUP)
	const dispatch = useAppDispatch()
	const plaid = useAppSelector(state => state.plaid)
	const stripe = useAppSelector(state => state.stripe)

	const prettyRoundupTransactions = React.useMemo(() => makeRoundupTransactionsPrettier(plaid.transactions), [plaid.transactions])
	const prettyMonthlyTransactions = React.useMemo(() => makeMonthlyTransactionsPrettier(stripe.transactions), [stripe.transactions])
	const totalContributions = React.useMemo(() => {
		const monthly = getTotalMonthlyTransactions(stripe.transactions)
		const roundup = getTotalRoundupTransactions(plaid.transactions)
		const total = monthly + roundup
		const result = total?.toFixed(2)
		return result
	}, [stripe.transactions, plaid.transactions])

	React.useEffect(() => {
		dispatch(getRoundupTransactions())
		dispatch(getSubscriptionTransactions())
		console.log('totalContributions', totalContributions)
	}, [])

	return (
		<Contributions>
			<Contributions.Title>Contributions</Contributions.Title>
			<Contributions.Wrapper>
				<Contributions.Head>
					<Contributions.HeadTitle>Total Contributions</Contributions.HeadTitle>
					<Contributions.HeadTotal>{totalContributions || '0'}</Contributions.HeadTotal>
					<TabToggle options={[OPTIONS.ROUNDUP, OPTIONS.MONTHLY]} onChange={option => setSelectedOption(option)} />
				</Contributions.Head>

				{selectedOption === OPTIONS.ROUNDUP ? (
					<>
						{plaid.transactionsLoading ? (
							<>
								{[...new Array(1)].map((_, i) => (
									<ul key={i}>
										<TableRow />
									</ul>
								))}
							</>
						) : (
							<Contributions.Transactions>
								{prettyRoundupTransactions.map(value => (
									<RoundupGroup key={value.YYYYMM}>
										<Contributions.MonthBadge>{value.YYYYMonth}</Contributions.MonthBadge>
										{value.transactions.map((item, index) => (
											<Contributions.TransactionItem key={index}>
												<div className="line-body">
													<p className="first-line">
														<span className="amount">${item.amount}</span> <Contributions.TextBlur>{item.merchant_name || 'Other Charge'}</Contributions.TextBlur>
													</p>
													<p className="second-line">{item.accOfficialName}</p>
												</div>
												<div className="roundup-amount">+{item.roundUpAmountPretty}</div>
											</Contributions.TransactionItem>
										))}
									</RoundupGroup>
								))}
							</Contributions.Transactions>
						)}
					</>
				) : (
					<Contributions.Transactions>
						{prettyMonthlyTransactions.map(value => (
							<MonthlyGroup key={value._id}>
								<Contributions.TransactionItem>
									<div className="line-body">
										<p className="first-line">
											<span className="amount">{value.prettyDate}</span>
										</p>
										<p className="second-line">{value.donorboxEmail ? `Donorbox Contribution ` : `Monthly Contribution`}</p>
									</div>
									<div className="monthly-amount">+${value.amount}</div>
								</Contributions.TransactionItem>
							</MonthlyGroup>
						))}
					</Contributions.Transactions>
				)}
			</Contributions.Wrapper>
		</Contributions>
	)
}

const RoundupGroup = styled.div`
	margin-bottom: 20px;
`

const MonthlyGroup = styled.div`
	margin-bottom: -20px;
`

const TableRow = ({ ...props }) => {
	return (
		<TransactionsSkeletonWrapper>
			<div className="parent-skeleton" {...props}>
				<div className="badge-skeleton">
					<Skeleton inline width={'100%'} height={54} style={{ borderRadius: '50px', marginBottom: '20px' }} />
				</div>
				<div className="list-skeleton">
					<div className="list-item">
						<div className="left-side">
							<Skeleton inline width={160} />
						</div>
						<div className="right-side">
							<Skeleton width={97} height={40} style={{ borderRadius: '50px' }} />
						</div>
					</div>
					<div className="separator-line">
						<Skeleton inline width={'100%'} height={2} />
					</div>
					<div className="list-item">
						<div className="left-side">
							<Skeleton inline width={160} />
						</div>
						<div className="right-side">
							<Skeleton width={97} height={40} style={{ borderRadius: '50px' }} />
						</div>
					</div>
					<div className="separator-line">
						<Skeleton inline width={'100%'} height={2} />
					</div>
					<div className="list-item">
						<div className="left-side">
							<Skeleton inline width={160} />
						</div>
						<div className="right-side">
							<Skeleton width={97} height={40} style={{ borderRadius: '50px' }} />
						</div>
					</div>
				</div>
			</div>
		</TransactionsSkeletonWrapper>
	)
}
