import React from 'react'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'
import * as ROUTES from 'constants/routes'

// * Data
import fundData from 'fixtures/funds.json'

// * Components
import { Fund } from '.'

const Section = styled.section`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 80px 20px 140px 20px;

	@media (max-width: ${BREAK._768}) {
		padding: 40px 20px 70px 20px;
	}
`

const LOCAL = {
	defaultAssetPath: '/images/assets',
}

export default function FundSection() {
	return (
		<Section id="fund">
			<Fund>
				<Fund.Header>
					<Fund.Headline>Zark Fundraisers</Fund.Headline>
					<Fund.Description>
						Zark funds a number of fundraisers. These fundraisers are chosen based on the urgency of the causes they support. Similar initiatives are grouped into
						four main buckets for you to pick from. The collected funds are regularly published on the website and through Zark’s social media.
					</Fund.Description>
				</Fund.Header>
				<Fund.Main>
					{fundData.map(item => (
						<Fund.Item key={item.id}>
							<Fund.Logo>
								{item.funds.map(fund => (
									<Fund.LogoItem key={fund.id} src={`${LOCAL.defaultAssetPath}/${fund.icon}`}>
										<Fund.Detail>
											<Fund.DetailInner>
												<Fund.DetailContent>
													<Fund.DetailLogo src={`${LOCAL.defaultAssetPath}/${fund.icon}`} />
													<Fund.DetailBody>
														<Fund.DetailFundTitle link={fund.websiteLink}>{fund.title}</Fund.DetailFundTitle>
														<Fund.DetailFundSubTitle>{fund.description}</Fund.DetailFundSubTitle>
														<Fund.DetailFundAction to={ROUTES.REGISTER}>Fund Now</Fund.DetailFundAction>
													</Fund.DetailBody>
												</Fund.DetailContent>
											</Fund.DetailInner>
										</Fund.Detail>
									</Fund.LogoItem>
								))}
							</Fund.Logo>
							<Fund.Title>{item.title}</Fund.Title>
							<Fund.Text>{item.text}</Fund.Text>
						</Fund.Item>
					))}
				</Fund.Main>
			</Fund>
		</Section>
	)
}
