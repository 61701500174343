import React from 'react'
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { addAccounts, getLinkToken } from 'store/plaidSlice'
import { useAppSelector } from 'store/combinedReducer'

const NewPlaidLink = ({ children }) => {
	const dispatch = useAppDispatch()
	const auth = useAppSelector(state => state.auth)
	const plaid = useAppSelector(state => state.plaid)

	React.useEffect(() => {
		auth.user._id && dispatch(getLinkToken(auth.user._id))
		console.log('Wizard.tsx >> dispatch(getLinkToken(...))')
	}, [auth.user._id, dispatch])

	const onSuccess = React.useCallback<PlaidLinkOnSuccess>(
		(publicToken, metadata) => {
			const plaidData = {
				public_token: publicToken,
				metadata: metadata,
				accounts: plaid.accounts,
			}

			dispatch(addAccounts(plaidData))
		},
		[plaid.accounts, dispatch]
	)

	const { open, ready } = usePlaidLink({ token: plaid.plaidToken, onSuccess })

	return (
		<button style={{ padding: 0, backgroundColor: 'transparent' }} onClick={() => open()} disabled={!ready}>
			{children}
		</button>
	)
}

export default NewPlaidLink
