import React from 'react'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Data
import faqsData from 'fixtures/faqs.json'

// * Components
import { Faq } from '.'

const Section = styled.section`
	background-color: var(--COLOR_WHITE);
	padding: 120px 20px 100px 20px;

	@media (max-width: ${BREAK._768}) {
		padding: 50px 20px 50px 20px;
	}
`

export default function FaqSection() {
	return (
		<Section id="faq">
			<Faq>
				<Faq.Header>
					<Faq.Headline>Frequently Asked Questions</Faq.Headline>
					<Faq.Description></Faq.Description>
				</Faq.Header>
				<Faq.Main>
					{faqsData.map(item => (
						<Faq.Item key={item.id}>
							<Faq.Question>{item.question}</Faq.Question>
							<Faq.Answer>{item.answer}</Faq.Answer>
						</Faq.Item>
					))}
				</Faq.Main>
			</Faq>
		</Section>
	)
}
