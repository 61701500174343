import * as React from 'react'
import Spinner from '../components/Spinner'
import { Renderer as RendererInterface, Tester } from '../interfaces'

export const Renderer: RendererInterface = ({ story, action, isPaused, isMuted, config, messageHandler }) => {
	const [loaded, setLoaded] = React.useState(false)
	const [muted, setMuted] = React.useState(false)
	const { width, height, loader, storyStyles } = config

	let computedStyles = {
		...styles.storyContent,
		...(storyStyles || {}),
	}

	let vid = React.useRef<HTMLVideoElement>(null)

	React.useEffect(() => {
		if (vid.current) {
			if (isPaused) {
				vid.current.pause()
				// console.log('playback');
				// vid.current.currentTime = 0;
				// vid.current.play().catch(() => { });
			} else {
				// console.log('play');
				vid.current.play().catch(() => {})
			}

			if (isMuted) {
				vid.current.muted = true
			} else {
				vid.current.muted = false
			}
		}
	}, [isPaused, isMuted])

	const onWaiting = () => {
		action('pause', true)
	}

	const onPlaying = () => {
		action('play', true)
	}

	const videoLoaded = () => {
		messageHandler('UPDATE_VIDEO_DURATION', { duration: vid.current.duration })
		setLoaded(true)
		vid.current
			.play()
			.then(() => {
				action('play')
			})
			.catch(() => {
				setMuted(true)
				vid.current.play().finally(() => {
					action('play')
				})
			})
	}

	return (
		<div style={styles.videoContainer}>
			<video
				ref={vid}
				style={computedStyles}
				src={story.url}
				controls={false}
				onLoadedData={videoLoaded}
				playsInline
				onWaiting={onWaiting}
				onPlaying={onPlaying}
				muted={muted}
				autoPlay
				webkit-playsinline="true"
			/>
			{!loaded && (
				<div
					style={{
						width: width,
						height: height,
						position: 'absolute',
						left: 0,
						top: 0,
						background: 'rgba(0, 0, 0, 0.9)',
						zIndex: 9,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#ccc',
					}}
				>
					{loader || <Spinner />}
				</div>
			)}
		</div>
	)
}

const styles = {
	storyContent: {
		width: 'auto',
		maxWidth: '100%',
		maxHeight: '100%',
		margin: 'auto',
	},
	videoContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}

export const tester: Tester = story => {
	return {
		condition: story.type === 'video',
		priority: 2,
	}
}

export default { renderer: Renderer, tester }
