import React from 'react'
import { scaleTime, scaleLinear } from '@visx/scale'
import { extent } from 'd3-array'
import { DataPoint } from './types'
import useDimensions from './useDimensions'

export default function useScale({
	data,
	getYValue,
	getXValue,
	bounds,
}: {
	data: any
	getYValue: (d: DataPoint) => number
	getXValue: (d: DataPoint) => Date
	bounds: any
}) {
	const { maxValue, minValue, innerWidth, innerHeight, margin } = useDimensions({ data, getYValue, bounds })

	const xScale = React.useMemo(
		() =>
			scaleTime({
				range: [margin.left, innerWidth + margin.left],
				domain: extent(data, getXValue),
			}),
		[data, getXValue, innerWidth]
	)

	const yScale = React.useMemo(
		() =>
			scaleLinear({
				range: [innerHeight + margin.top, margin.top],
				domain: [minValue, maxValue],
			}),
		[innerHeight, maxValue, minValue]
	)

	return { xScale, yScale }
}
