import { STORAGE_KEYS } from 'constants/storageKeys'

export function support_format_webp() {
	const elem = document.createElement('canvas')

	if (!!(elem.getContext && elem.getContext('2d'))) {
		// was able or not to get WebP representation
		// console.log('WEBP SUPPORTED: was able or not to get WebP representation')
		localStorage.setItem(STORAGE_KEYS.SUPPORT_WEBP, 'supported')
		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
	} else {
		// very old browser like IE 8, canvas not supported
		// console.log('WEBP NOT SUPPORTED: very old browser like IE 8, canvas not supported')
		localStorage.setItem(STORAGE_KEYS.SUPPORT_WEBP, 'unsupported')
		return false
	}
}
