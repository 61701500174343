import React from 'react'
import { Link as ReachRouterLink, useNavigate } from 'react-router-dom'
import { Formik as FormikWrapper, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

// * Constants
import * as ROUTES from 'constants/routes'

// * Redux
import { resetUserPassword } from 'store/authSlice'
import { useAppSelector } from 'store/combinedReducer'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Form from '..'

const validateEmail = Yup.object({
	email: Yup.string().email('Email is invalid').required('Email is required'),
})

const validatePassword = Yup.object({
	password: Yup.string()
		.required('Password is required')
		.min(5, 'Password must be longer than 5 characters.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'Password must contain 1 uppercase, 1 lowercase')
		.matches(/^(?=.*[!@#\$%\^&\*])/, 'Password must contain 1 special case character ( ex. !@#$%^&* )')
		.matches(/^(?=.{6,20}$)\D*\d/, 'Password must contain 1 number'),
	password2: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Password must match')
		.required('Confirm password is required'),
})

const validateCode = Yup.object({
	phoneCode: Yup.string().required().length(4, 'Must be 4 digits').label('Code'),
})

export default function ResetFlow() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const auth = useAppSelector(state => state.auth)
	const [showError, setShowError] = React.useState(false)
	const [showCode, setShowCode] = React.useState(false)
	const [showReset, setShowReset] = React.useState(false)

	React.useEffect(() => {
		auth.authenticated && navigate(ROUTES.DASHBOARD)
	}, [auth, navigate])

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(resetUserPassword(values, setSubmitting, setShowError, setShowCode, setShowReset))
	}

	return showCode ? (
		<>
			{showReset ? (
				<Form>
					<Form.Header>
						<Form.Title>Reset Password</Form.Title>
					</Form.Header>
					<FormikWrapper
						initialValues={{
							password: '',
							password2: '',
						}}
						validationSchema={validatePassword}
						onSubmit={handleSubmit}
					>
						<FormikForm>
							<Form.PasswordInput name="password" placeholder="New Password" />
							<Form.Input name="password2" type="password" placeholder="Confirm Password" />
							<Form.Button>Reset Password</Form.Button>
							<Form.TextWithLink>
								<ReachRouterLink to={ROUTES.LOGIN}>Return to Log in</ReachRouterLink>
							</Form.TextWithLink>
						</FormikForm>
					</FormikWrapper>
				</Form>
			) : (
				<Form>
					<Form.Header>
						<Form.Title>Enter Code</Form.Title>
						{showError && <Form.InfoBox log="error">Sorry, the pin you entered is old or incorrect.</Form.InfoBox>}
						<Form.Description>A text message with a 4-digit verification code was sent to your phone</Form.Description>
					</Form.Header>
					<FormikWrapper
						initialValues={{
							phoneCode: '',
						}}
						validationSchema={validateCode}
						onSubmit={handleSubmit}
					>
						<FormikForm>
							<Form.CodeInput name="phoneCode" placeholder="####" maxLength={4} autoComplete="off" autoFocus />
							<Form.Button>Verify and Continue</Form.Button>
						</FormikForm>
					</FormikWrapper>
				</Form>
			)}
		</>
	) : (
		<>
			<Form>
				<Form.Header>
					<Form.Title>Reset Password</Form.Title>
					{showError && (
						<Form.InfoBox log="error">
							Sorry, we can’t find an account with this email address. Please try again or <ReachRouterLink to={ROUTES.REGISTER}>create a new account</ReachRouterLink>
							.
						</Form.InfoBox>
					)}
				</Form.Header>
				<FormikWrapper
					initialValues={{
						email: '',
					}}
					validationSchema={validateEmail}
					onSubmit={handleSubmit}
				>
					<FormikForm>
						<Form.Input name="email" type="email" placeholder="Email" />

						<Form.Button>Continue</Form.Button>
						<Form.TextWithLink>
							<ReachRouterLink to={ROUTES.LOGIN}>Return to Log in</ReachRouterLink>
						</Form.TextWithLink>
					</FormikForm>
				</FormikWrapper>
			</Form>
		</>
	)
}
