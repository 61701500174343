import React from 'react'

// * Components
import Details from '..'
import { Spinner } from 'components/dashboard/loaders'
import NewPlaidLink from 'components/global/Plaid/newPlaidLink'
import { useAppSelector } from 'store/combinedReducer'

const LOCAL = {
	defaultAssetPath: '/images/assets',
	imgRoundup: 'feature-roundup-short',
	logoPlaid: 'logo-plaid',
	logoStripe: 'logo-stripe',
}

const RoundupFlow = ({ onPrevStep }) => {
	const plaid = useAppSelector(state => state.plaid)

	return plaid.accountsLoading ? (
		<Spinner />
	) : (
		<>
			<Details>
				<Details.Header>
					<Details.Tag>Step 3 of 3</Details.Tag>
					{/* <button onClick={() => dispatch(getLinkToken(auth.user._id))}>Get link</button> */}
					<Details.Title>{`Activate Round-Up`}</Details.Title>
					<Details.Description>{`Securely link your online banking account.`}</Details.Description>
				</Details.Header>
				<Details.Body>
					<Details.Image webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgRoundup}.webp`} png={`${LOCAL.defaultAssetPath}/${LOCAL.imgRoundup}.png`} />
					<Details.SecureWrapper>
						<Details.SecureText firstLine>We use bank-level security provided</Details.SecureText>
						<Details.SecureText>
							by <Details.SecureImage src={`${LOCAL.defaultAssetPath}/${LOCAL.logoPlaid}.svg`} /> and{' '}
							<Details.SecureImage src={`${LOCAL.defaultAssetPath}/${LOCAL.logoStripe}.svg`} />
						</Details.SecureText>
					</Details.SecureWrapper>
					<Details.ButtonWrapper>
						<Details.Button
							outline
							onClick={() => {
								onPrevStep()
							}}
						>
							Go Back
						</Details.Button>
						<Details.Button onClick={e => e.preventDefault()}>
							Continue
							<NewPlaidLink>{''}</NewPlaidLink>
						</Details.Button>
					</Details.ButtonWrapper>
				</Details.Body>
			</Details>
			<Details.Footer>
				<Details.FootLink>
					Your information is safely encrypted.
					<br />
					See{' '}
					<a rel="noopener noreferrer" target="_blank" href="/documents/Zark Terms of Use.pdf">
						Terms & Conditions
					</a>
					.
				</Details.FootLink>
			</Details.Footer>
		</>
	)
}

export default RoundupFlow
