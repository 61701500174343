import React from 'react'

// * Redux
import { removeCard } from 'store/stripeSlice'
import { removeAccounts } from 'store/plaidSlice'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Card from '..'
import { StripePopupComp } from 'components/dashboard/cards'
import NewPlaidLink from 'components/global/Plaid/newPlaidLink'
import ReconnectPlaidLink from 'components/global/Plaid/reconnectPlaidLink'
import { useAppSelector } from 'store/combinedReducer'

export default function AccountsComp({ stripePopupIsOpen, setStripePopupIsOpen }) {
	const dispatch = useAppDispatch()
	const [checkingAccountExists, setCheckingAccountExists] = React.useState(false)
	const [creditAccountExists, setCreditAccountExists] = React.useState(false)
	const plaid = useAppSelector(state => state.plaid)
	const stripe = useAppSelector(state => state.stripe)

	React.useEffect(() => {
		setCheckingAccountExists(false)
		setCreditAccountExists(false)
		plaid.accounts.forEach(bank => {
			bank.accountSubtype === 'checking' && setCheckingAccountExists(true)
			bank.accountSubtype === 'credit card' && setCreditAccountExists(true)
		})
	}, [plaid.accounts])

	const onRemovePlaidAccount = id => dispatch(removeAccounts(id))

	const onRemoveStripeAccount = () => dispatch(removeCard(stripe.card.id))

	return (
		<>
			{stripePopupIsOpen && <StripePopupComp onClose={() => setStripePopupIsOpen(false)} />}
			<Card>
				<Card.Heading>Round-up Accounts</Card.Heading>
				<Card.Wrapper>
					{stripe.cardLoading || plaid.accountsLoading ? (
						<Card.Loading />
					) : (
						<>
							{stripe.card.id && (
								<Card.Item>
									<Card.Header>
										{stripe.cardRemoving ? <Card.Removing /> : <Card.Leading icon="card" />}
										<Card.Group>
											<Card.Title>Payment Source</Card.Title>
											<Card.Subtitle>
												Card ending in *{stripe.loading ? `XXXX` : `${stripe.card.last4}`}
												{stripe.cardRemoving && ' is being removed...'}
											</Card.Subtitle>
										</Card.Group>
										<Card.Trailing hover circle>
											<Card.Dropdown>
												<Card.DropdownLink onClick={() => onRemoveStripeAccount()}>remove {stripe.card.funding} card</Card.DropdownLink>
											</Card.Dropdown>
										</Card.Trailing>
									</Card.Header>
								</Card.Item>
							)}

							{!checkingAccountExists && !stripe.card.id && creditAccountExists && !plaid.accountsLoading && !stripe.cardLoading && (
								<Card.Warning>
									To start using Round-up, please link a <button onClick={() => setStripePopupIsOpen(true)}>credit card</button> or{' '}
									<NewPlaidLink>checking account</NewPlaidLink> as your payment source.
								</Card.Warning>
							)}
							{plaid.accounts.map(
								item => (
									<Card.Item key={item._id} status={item.status}>
										<Card.Header>
											{item.cardRemoving ? (
												<Card.Removing />
											) : item.logoBase64 ? (
												<Card.InstitutionLogo src={`data:image/*;base64,${item.logoBase64}`} />
											) : (
												<Card.Leading icon="bank" status={item.status} />
											)}
											<Card.Group>
												<Card.Title status={item.status}>
													{item.accountSubtype} (•••{item.last4})
												</Card.Title>
												<Card.Subtitle status={item.status}>{item.accountOfficialName || item.accountName || 'N/A'}</Card.Subtitle>

												{item.status === 'DISCONNECTED' && (
													<Card.Button>
														<ReconnectPlaidLink reLinkToken={item.reLinkToken}>Reconnect account</ReconnectPlaidLink>
													</Card.Button>
												)}
											</Card.Group>
											<Card.Trailing hover circle status={item.status}>
												<Card.Dropdown>
													<Card.DropdownLink onClick={() => onRemovePlaidAccount(item._id)}>
														remove {item.accountSubtype && item.accountSubtype.toLowerCase()}
													</Card.DropdownLink>
												</Card.Dropdown>
											</Card.Trailing>
										</Card.Header>
									</Card.Item>
								)
								// <p>itemId: {item.itemId}</p>
								// <p>userId: {item.userId}</p>
								// <p>accessToken: {item.accessToken}</p>
								// <p>accountId: {item.accountId}</p>
								// <p>reLinkToken: {item.reLinkToken}</p>
							)}
						</>
					)}
					<NewPlaidLink>
						<Card.Item cursorPointer>
							<Card.Header>
								<Card.Leading icon="add_bank" />
								<Card.Group>
									<Card.Title>Add new account</Card.Title>
									<Card.Subtitle>Link securely with Plaid</Card.Subtitle>
								</Card.Group>
								<Card.Trailing />
							</Card.Header>
						</Card.Item>
					</NewPlaidLink>
				</Card.Wrapper>
			</Card>
		</>
	)
}
