import useDimensions from './useDimensions'

export default function useTick({ data, getYValue, bounds }) {
	const { maxValue, minValue } = useDimensions({ data, getYValue, bounds })

	const tickSize = (maxValue - minValue) / 4
	const bottomTick = minValue + tickSize
	const lowTick = bottomTick + tickSize
	const midTick = lowTick + tickSize
	const yAxisTickValues = [maxValue, midTick, lowTick, bottomTick]

	const yAxisTickFormat = value => {
		const int = parseInt(value as any)
		const rounded = Math.round(int * 100) / 100
		return `$${rounded}`
	}

	return { yAxisTickValues, yAxisTickFormat }
}
