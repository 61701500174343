import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// * Components
import Dash from '../../Dashboard'
import { Card } from '../../cards'
import { NavbarComp } from 'components/global'

export default function () {
	return (
		<Dash>
			<NavbarComp />

			<Dash.Contain>
				<Dash.Head>
					<Skeleton
						maxWidth={904}
						height={330}
						style={{
							borderRadius: '12px',
							marginBottom: '20px',
						}}
					/>
				</Dash.Head>
				<Dash.Body>
					<Dash.Column>
						<Card>
							<Skeleton
								width={'100%'}
								height={30}
								style={{
									marginBottom: '20px',
								}}
							/>
							<Card.Wrapper>
								<Skeleton
									width={'100%'}
									height={90}
									style={{
										borderRadius: '12px',
									}}
								/>
								<Skeleton
									width={'100%'}
									height={90}
									style={{
										borderRadius: '12px',
									}}
								/>
								<Skeleton
									width={'100%'}
									height={90}
									style={{
										borderRadius: '12px',
									}}
								/>
							</Card.Wrapper>
						</Card>
					</Dash.Column>
					<Dash.Column>
						<Skeleton
							width={'100%'}
							height={30}
							style={{
								marginBottom: '20px',
							}}
						/>
						<Skeleton
							width={'100%'}
							height={664}
							style={{
								borderRadius: '12px',
							}}
						/>
					</Dash.Column>
				</Dash.Body>
			</Dash.Contain>
		</Dash>
	)
}
