import React from 'react'
import styled from 'styled-components'

// * Components
import { Team } from '.'

// * Data
import teamData from 'fixtures/team.json'

// * Constants
import * as BREAK from 'constants/breakpoints'
import * as ROUTES from 'constants/routes'

const Section = styled.section`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 80px 20px 80px 20px;

	@media (max-width: ${BREAK._768}) {
		padding: 40px 20px 40px 20px;
	}
`

const LOCAL = {
	defaultTeamPath: '/images/team',
}

export default function TeamSection() {
	return (
		<Section id="team">
			<Team>
				<Team.Header>
					<Team.Headline>Our Team</Team.Headline>

					<Team.Description>
						We are a team of experienced young tech professionals from various backgrounds and industries brought together by time’s calling with the sole purpose of
						contributing our time and knowledge to building a platform that will fuel tech advancements of Armenia’s key industries.
					</Team.Description>

					<Team.Button outline to={ROUTES.TEAM}>
						See our team
					</Team.Button>

					<Team.Stack>
						{teamData.map(member => (
							<Team.StackImage
								key={member.id}
								webp={`${LOCAL.defaultTeamPath}/${member.image}.webp`}
								jpg={`${LOCAL.defaultTeamPath}/${member.image}.jpg`}
								alt={member.image}
							/>
						))}
					</Team.Stack>

					<Team.Slack />
				</Team.Header>
			</Team>
		</Section>
	)
}
