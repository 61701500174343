import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const Header = styled.header`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 70px;

	@media (max-width: ${BREAK._1100}) {
		margin-bottom: 35px;
	}
`
export const Headline = styled.h1`
	margin-bottom: 15px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H2);
		margin-bottom: 6px;
	}
`
export const Description = styled.p`
	max-width: 625px;
	white-space: pre-line;
`

export const Main = styled.main`
	max-width: 1100px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 100px;

	@media (max-width: ${BREAK._1100}) {
		grid-template-columns: 1fr;
		gap: 50px;
	}
`
export const Item = styled.div`
	position: relative;
	max-width: 450px;

	@media (max-width: ${BREAK._1100}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
`
export const Logo = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	& :nth-child(1) {
		margin-left: 0px;
	}
	&:hover :nth-child(1) {
		margin-left: 0px;
	}
	&:hover > div {
		margin-left: -10px;

		&::before {
			content: ' ';
			position: absolute;
			border-radius: 100%;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			background: white;
			opacity: 0.8;
			transition: ease 300ms;
		}
	}
	&:hover > div:hover {
		&::before {
			content: ' ';
			position: absolute;
			border-radius: 100%;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			background: white;
			opacity: 0;
			transition: ease 450ms;
		}
	}
`

export const LogoItem = styled.div`
	position: relative;
	margin-left: -20px;
	height: 50px;
	width: 50px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 3px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	transition: 300ms;

	&:hover {
		margin: 0 10px;
		z-index: 90;
	}

	@media (max-width: ${BREAK._768}) {
		border: solid 2px white;
	}
`

export const Title = styled.h3`
	margin-bottom: 20px;
	@media (max-width: ${BREAK._768}) {
		margin-bottom: 10px;
	}
`

export const Text = styled.p`
	white-space: pre-line;
	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_SP);
		max-width: 300px;
	}
`

export const Detail = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`

export const DetailInner = styled.div`
	position: absolute;
	top: 40px;
	z-index: -1;
	transform: translate(-50%, 0px);

	#fund-detail-background {
		position: relative;
		min-width: 320px;
		top: 20px;
		padding: 10px;
		border-radius: 12px;
		height: 100%;
		box-shadow: var(--SHADOW_SOFT);
		background-color: var(--COLOR_WHITE);
		display: grid;
		gap: 3px;
		z-index: 0;
	}
`

export const DetailContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
`

export const DetailLogo = styled.div`
	position: relative;
	margin-left: -20px;
	height: 90px;
	width: 90px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 3px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	flex-shrink: 0;
`

export const DetailBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const DetailFundTitle = styled.p`
	font-weight: 600;
	margin-bottom: 0px;
	font-size: var(--TYPE_SL);

	& img {
		width: 23px;
		height: 23px;
		margin-bottom: 2px;
		display: inline;
	}

	& a {
		display: flex;
		gap: 2px;
		align-items: center;

		:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
`

export const DetailFundDescription = styled.p`
	font-size: var(--TYPE_SP);
	text-align: start;
	white-space: pre-wrap;
	margin-bottom: 5px;
`

export const DetailFundButton = styled.button`
	position: relative;
	font-size: var(--TYPE_SP);
	font-weight: 600;
	padding: 0 0.7rem;
	border-radius: 8px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--COLOR_SECONDARY);
	color: var(--COLOR_PRIMARY);
	:hover {
		opacity: 0.8;
		transition: 300ms;
	}
`
