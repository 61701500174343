import React from 'react'

import { ReactComponent as Play } from './icons/icon-play.svg'
import { ReactComponent as More } from './icons/icon-more.svg'
import { ReactComponent as Bank } from './icons/icon-bank.svg'
import { ReactComponent as AddBank } from './icons/icon-add-bank.svg'
import { ReactComponent as Card } from './icons/icon-card.svg'
import { ReactComponent as CardRepeat } from './icons/icon-card-repeat.svg'
import { ReactComponent as LeftArrow } from './icons/icon-left-arrow.svg'
import { ReactComponent as ExternalLink } from './icons/icon-external-link.svg'
import { ReactComponent as Close } from './icons/icon-close.svg'
import { ReactComponent as Minus } from './icons/icon-minus.svg'
import { ReactComponent as Plus } from './icons/icon-plus.svg'
import { ReactComponent as ArmLetter } from './arm-letter.svg'
import { ReactComponent as ArrowUp } from './icons/icon-arrow-up.svg'
import { ReactComponent as ArrowDown } from './icons/icon-arrow-down.svg'
import { ReactComponent as Badge } from './badge.svg'

export interface IconProps {
	fill?: string
	height?: string
	width?: string
	onClick?: () => void
	id?: string
	title?: string
}

export const IconPlay = Play
export const IconMore = More
export const IconBank = Bank
export const IconAddBank = AddBank
export const IconCard = Card
export const IconCardRepeat = CardRepeat

export const IconLeftArrow = (props: IconProps) => <LeftArrow height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_PRIMARY)'} />
export const IconExternalLink = (props: IconProps) => <ExternalLink height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_PRIMARY)'} />
export const IconClose = (props: IconProps) => (
	<Close onClick={props.onClick} height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_WHITE)'} />
)
export const IconMinus = Minus
export const IconPlus = Plus
export const IconArmLetter = (props: IconProps) => <ArmLetter height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_PRIMARY)'} />
export const IconBadge = (props: IconProps) => <Badge title={props.title} id={props.id} height={props.height} width={props.width} />
export const IconArrowUp = (props: IconProps) => <ArrowUp height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_PRIMARY)'} />
export const IconArrowDown = (props: IconProps) => <ArrowDown height={props.height} width={props.width} fill={props.fill || 'var(--COLOR_PRIMARY)'} />
