import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as S from './styled'

export default function Feature({ children }) {
	return <S.Container data-testid="feature-container">{children}</S.Container>
}

Feature.Header = function FeatureHeader({ children }) {
	return <S.Header data-testid="feature-header">{children}</S.Header>
}

Feature.Headline = function FeatureHeadline({ children }) {
	return <S.Headline data-testid="feature-headline">{children}</S.Headline>
}

Feature.Description = function FeatureDescription({ children, ...props }) {
	return (
		<S.Description data-testid="feature-description" {...props}>
			{children}
		</S.Description>
	)
}

Feature.Main = function FeatureMain({ children }) {
	return <S.Main data-testid="feature-main">{children}</S.Main>
}

Feature.Item = function FeatureItem({ children, ...props }) {
	return (
		<S.Item data-testid="feature-item" {...props}>
			{children}
		</S.Item>
	)
}

Feature.Image = function FeatureImage({ webp, png, ...props }) {
	return (
		<S.Image data-testid="feature-image" {...props}>
			<picture>
				<source srcSet={webp} type="image/webp" />
				<source srcSet={png} type="image/png" />
				<img src={''} alt="feature" />
			</picture>
		</S.Image>
	)
}

Feature.Illustration = function FeatureIllustration({ webp, png, ...props }) {
	return (
		<S.Illustration data-testid="feature-illustration" {...props}>
			<div className="plate">
				<picture>
					<source srcSet={webp} type="image/webp" />
					<source srcSet={png} type="image/png" />
					<img src={''} alt="feature" />
				</picture>
			</div>
		</S.Illustration>
	)
}

Feature.Body = function FeatureBody({ children }) {
	return <S.Body data-testid="feature-body">{children}</S.Body>
}

Feature.Label = function FeatureLabel({ children }) {
	return <S.Label data-testid="feature-label">{children}</S.Label>
}

Feature.Title = function FeatureTitle({ children }) {
	return <S.Title data-testid="feature-title">{children}</S.Title>
}

Feature.Text = function FeatureText({ children }) {
	return <S.Text data-testid="feature-text">{children}</S.Text>
}

Feature.Button = function FeatureButton({ to, href, children }) {
	return (
		<>
			{to && (
				<ReachRouterLink data-testid="feature-button-router-link" to={to}>
					<S.Button data-testid="feature-button">{children}</S.Button>
				</ReachRouterLink>
			)}
			{href && (
				<a data-testid="feature-button-link" href={href} rel="noopener noreferrer" target="_blank">
					<S.Button data-testid="feature-button">{children}</S.Button>
				</a>
			)}
		</>
	)
}
