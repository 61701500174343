import styled from 'styled-components'

export const Selection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
`

export const Item = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-height: 96px;
	padding: 20px;
	border-radius: 12px;
	background-color: var(--COLOR_SECONDARY_NEUTRAL);
	cursor: pointer;

	&.active {
		outline: 2px solid var(--COLOR_SECONDARY);
		background-color: var(--COLOR_GREY_WARM_LIGHT);

		& p {
			color: var(--COLOR_PRIMARY);
		}

		& #item-radio {
			border: 6px solid var(--COLOR_SECONDARY);
		}
	}

	:hover {
		background-color: var(--COLOR_GREY_LIGHT);

		& #item-radio {
			border: 6px solid var(--COLOR_SECONDARY);
		}
	}
`

export const ItemTitle = styled.p`
	font-size: var(--TYPE_MP);
	font-weight: 600;
`

export const ItemSubtitle = styled.p`
	font-size: var(--TYPE_SP);
	color: var(--COLOR_GREY_DARK);
`

export const ItemRadio = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
	width: 22px;
	height: 22px;
	border: 2px solid var(--COLOR_GREY);
	background-color: var(--COLOR_WHITE);
	border-radius: 100%;
	display: flex;
	flex-shrink: 0;
`
