import React from 'react'

import * as S from 'components/onboarding/globalStyled'
import * as T from './index.types'

export default function Details(props: T.DefaultProps) {
	return <S.Container {...props}>{props.children}</S.Container>
}

//** Header Children */
Details.Header = (props: T.ChildElementProps) => {
	return <S.Header data-testid="monthly-header">{props.children}</S.Header>
}

Details.Tag = (props: T.ChildTextProps) => {
	return (
		<S.Tag data-testid="monthly-tag">
			<span>{props.children}</span>
		</S.Tag>
	)
}

Details.Title = (props: T.ChildTextProps) => {
	return <S.Title data-testid="monthly-title">{props.children}</S.Title>
}

Details.Description = (props: T.ChildTextProps) => {
	return <S.Description data-testid="monthly-description">{props.children}</S.Description>
}

//** Body Children */
Details.Body = (props: T.DefaultProps) => {
	return (
		<S.Body data-testid="monthly-body" {...props}>
			{props.children}
		</S.Body>
	)
}

Details.LinkWrapper = (props: T.DefaultProps) => {
	return (
		<S.FundLinkWrapper data-testid="monthly-link-wrapper" {...props}>
			{props.children}
		</S.FundLinkWrapper>
	)
}

Details.Link = (props: T.LinkProps) => {
	return (
		<S.FundLink data-testid="monthly-link" rel="noopener noreferrer" target="_blank" href={props.websiteLink} title={props.title}>
			<img data-testid="monthly-link-image" src={`/images/assets/${props.icon}`} alt={props.title} />
			{props.abbr}{' '}
		</S.FundLink>
	)
}

Details.Image = (props: T.ImageProps) => {
	return (
		<S.Image data-testid="monthly-image">
			<div className="plate">
				<picture>
					<source srcSet={props.webp} type="image/webp" />
					<source srcSet={props.png} type="image/png" />
					<img src={''} alt="feature" />
				</picture>
			</div>
		</S.Image>
	)
}

Details.SecureWrapper = (props: T.DefaultProps) => {
	return <S.SecureWrapper data-testid="monthly-secure-wrapper">{props.children}</S.SecureWrapper>
}

Details.SecureText = (props: T.SecureTextProps) => {
	return (
		<S.SecureText data-testid="monthly-secure-text">
			{props.firstLine && <img id="lock-icon" src="/images/icons/icon-lock.svg" alt="lock icon" />}
			{props.children}
		</S.SecureText>
	)
}

Details.SecureImage = (props: T.SecureImageProps) => {
	return <S.SecureImage {...(props as React.ReactElement)} />
}

Details.ButtonWrapper = (props: T.DefaultProps) => {
	return (
		<S.ButtonWrapper data-testid="monthly-button-wrapper" {...props}>
			{props.children}
		</S.ButtonWrapper>
	)
}

Details.Button = (props: T.ButtonProps) => {
	return (
		<S.Button data-testid="monthly-button" {...props}>
			{props.children}
		</S.Button>
	)
}

//** Footer Children */
Details.Footer = (props: T.DefaultProps) => {
	return (
		<S.Footer data-testid="monthly-footer" {...props}>
			{props.children}
		</S.Footer>
	)
}

Details.FootLink = (props: T.DefaultProps) => {
	return (
		<S.TextWithLink data-testid="monthly-text-with-link" {...props}>
			{props.children}
		</S.TextWithLink>
	)
}
