import React, { useContext } from 'react'
import ProgressCtx from '../context/Progress'
import * as Styled from '../styled'

// export interface ProgressProps {
//     width: number;
//     active: number;
//     count: number
// }

export default props => {
	// export interface ProgressContext {
	//     currentId: number;
	//     videoDuration: number;
	//     bufferAction: boolean;
	//     pause: boolean;
	//     next: Function
	// }
	const { bufferAction, pause } = useContext(ProgressCtx)

	const getProgressStyle = ({ active }) => {
		switch (active) {
			case 2:
				return { width: '100%' }
			case 1:
				return { transform: `scaleX(${props.count / 100})` }
			case 0:
				return { width: 0 }
			default:
				return { width: 0 }
		}
	}

	const { width, active } = props
	return (
		<Styled.ProgressBack width={width} pause={pause} bufferAction={bufferAction}>
			<Styled.ProgressInner style={{ ...getProgressStyle({ active }) }} />
		</Styled.ProgressBack>
	)
}
