import React from 'react'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Types
import * as T from '../index.types'

// * Components
import Footer from '..'

const Section = styled.footer`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 80px 20px 40px 20px;
	@media (max-width: ${BREAK._768}) {
		padding: 40px 20px 20px 20px;
	}
`

const LOCAL = {
	defaultDocumentPath: '/documents',
	docPrivacyPolicy: 'Zark Privacy Policy.pdf',
	docTermsOfUse: 'Zark Terms of Use.pdf',
	socialFacebook: 'https://m.facebook.com/zarkinow/',
	socialInstagram: 'https://www.instagram.com/zarkinow/',
}

export default function FooterSection(props: T.FooterProps) {
	return (
		<Section id="footer">
			<Footer>
				<Footer.Logo logo="/images/brand/zark-logo.svg" icon="/images/brand/zark-icon.svg" />
				{props.hasAnchor && (
					<Footer.Links>
						<Footer.Link rel="noopener noreferrer" target="_blank" href={LOCAL.socialInstagram}>
							Monthly Reports
						</Footer.Link>
						<Footer.Link rel="noopener noreferrer" target="_blank" href={`${LOCAL.defaultDocumentPath}/${LOCAL.docPrivacyPolicy}`}>
							Privacy Policy
						</Footer.Link>
						<Footer.Link rel="noopener noreferrer" target="_blank" href={`${LOCAL.defaultDocumentPath}/${LOCAL.docTermsOfUse}`}>
							Terms and Conditions
						</Footer.Link>
					</Footer.Links>
				)}

				<Footer.Social facebook={LOCAL.socialFacebook} instagram={LOCAL.socialInstagram} />

				<Footer.Copyright>2020 Copyright. All rights reserved.</Footer.Copyright>
			</Footer>
		</Section>
	)
}
