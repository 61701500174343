import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as S from './styled'

// * Types
import * as T from './index.types'

export default function Footer({ children }) {
	return <S.Container data-testid="footer-container">{children}</S.Container>
}

Footer.Logo = function FooterLogo(props: T.FooterLogoProps) {
	return (
		<S.Logo data-testid="footer-logo" logo={props.logo} icon={props.icon}>
			{props.to ? <ReachRouterLink data-testid="footer-logo-link" to={props.to} /> : <a href="#top">{}</a>}
		</S.Logo>
	)
}

Footer.Links = function FooterLinks({ children }) {
	return <S.Links data-testid="footer-links">{children}</S.Links>
}

Footer.Link = function FooterLink({ children, ...props }) {
	return (
		<S.Link data-testid="footer-link" {...props}>
			{children}
		</S.Link>
	)
}

Footer.Social = function FooterSocial({ facebook, instagram }) {
	return (
		<S.Social data-testid="footer-social">
			{facebook && (
				<a href={facebook} rel="noopener noreferrer" target="_blank">
					<img src="/images/icons/social-icon-facebook.svg" alt="facebook" />
				</a>
			)}
			{instagram && (
				<a href={instagram} rel="noopener noreferrer" target="_blank">
					<img src="/images/icons/social-icon-instagram.svg" alt="instagram" />
				</a>
			)}
		</S.Social>
	)
}

Footer.Copyright = function FooterCopyright({ children }) {
	return <S.Copyright data-testid="footer-copyright">{children}</S.Copyright>
}
