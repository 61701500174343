import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const Profile = styled.div<{ src: string }>`
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background-color: var(--COLOR_GREY_LIGHT);
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	flex-shrink: 0;
	transition: all 0.2s ease;

	& #icon-badge {
		position: absolute;
		bottom: -10px;
		right: 0;
		scale: 1;
		transition: scale 0.2s ease-in-out;
		height: 60px;
	}

	& > .overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: black;
		opacity: 0;
		border-radius: 120px;
		transition: all 0.2s ease;
	}

	& > img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		opacity: 0;
		transition: all 0.2s ease;
	}

	@media (max-width: ${BREAK._1100}) {
		width: 60px;
		height: 60px;
		margin-top: -4px;

		& > img {
			width: 25px;
		}

		& #icon-badge {
			height: 30px;
		}
	}
	@media (max-width: ${BREAK._395}) {
		display: none;
	}

	&:hover {
		cursor: pointer;

		& > .overlay {
			opacity: 0.25;
		}

		& > img {
			opacity: 1;
		}

		& #icon-badge {
			scale: 1.05;
		}
	}
`
