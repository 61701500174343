import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const Container = styled.div`
	position: relative;
	padding: 50px 35px 35px 35px;
	border-radius: 12px;
	background-color: var(--COLOR_WHITE);
	box-shadow: var(--SHADOW_SOFT);
	min-height: 330px;
	display: flex;
	flex-direction: column;
	gap: 30px;

	@media (max-width: ${BREAK._1100}) {
		padding: 30px 15px 20px 15px;
		min-height: auto;
		gap: 20px;
	}
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;

	@media (max-width: ${BREAK._1100}) {
		gap: 15px;
	}
`

export const Content = styled.div`
	width: 100%;
`
