import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'

// * Styles
import { Container, Title, Text, ButtonWrapper, Button } from '../globalStyled'
import { Button as ButtonUpload, SliderWrapper } from './styled'

// * Utils
import getCroppedImg, { dataURLtoFile } from 'utils/croppedImage'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { uploadProfile } from 'store/authSlice'

// * Components
import { IconMinus, IconPlus } from 'assets'

export default function ProfilePicturePopup({ onClose, children, ...props }) {
	return (
		<Container data-testid="profile-picture-popup-container" {...props}>
			<div data-testid="fund-popup-container-backdrop" className="backdrop" onClick={onClose}></div>
			<div data-testid="fund-popup-container-card" className="card">
				{children}
			</div>
		</Container>
	)
}

ProfilePicturePopup.Title = function ProfilePicturePopupTitle({ children, ...props }) {
	return (
		<Title data-testid="profile-picture-popup-title" {...props}>
			{children}
		</Title>
	)
}

ProfilePicturePopup.Text = function ProfilePicturePopupText({ children, ...props }) {
	return (
		<Text data-testid="profile-picture-popup-text" {...props}>
			{children}
		</Text>
	)
}

ProfilePicturePopup.ButtonWrapper = function ProfilePicturePopupButtonWrapper({ children, ...props }) {
	return (
		<ButtonWrapper data-testid="profile-picture-popup-button-wrapper" {...props}>
			{children}
		</ButtonWrapper>
	)
}

ProfilePicturePopup.ActivateCrop = function ProfilePicturePopupActivateCrop({ file, onClose }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedArea, setCroppedArea] = useState(null)

	const dispatch = useAppDispatch()

	const onCropComplete = (croppedAreaPercent, croppedAreaPixels) => {
		console.log('croppedAreaPercent', croppedAreaPercent)
		console.log('croppedAreaPixels', croppedAreaPixels)
		setCroppedArea(croppedAreaPixels)
	}

	const onUpload = async () => {
		const canvas = await getCroppedImg(file, croppedArea)
		const canvasDataUrl = await canvas.toDataURL('image/jpg')
		const convertedUrlToFile = dataURLtoFile(canvasDataUrl, 'cropped-image.jpg')
		console.log('convertedUrlToFile', convertedUrlToFile)

		const formData = new FormData()
		formData.append('croppedImage', convertedUrlToFile)

		dispatch(uploadProfile(formData))
		onClose()
	}

	return (
		<>
			<Cropper
				disableAutomaticStylesInjection={true}
				image={file}
				crop={crop}
				zoom={zoom}
				aspect={1}
				onCropChange={setCrop}
				onZoomChange={setZoom}
				onCropComplete={onCropComplete}
			/>
			<SliderWrapper>
				<IconMinus />
				<Slider min={1} max={3} step={0.01} value={zoom} onChange={(e, zoom) => setZoom(zoom)} color="#d3b994" />
				<IconPlus />
			</SliderWrapper>
			<ButtonWrapper data-testid="profile-picture-popup-button-wrapper">
				<ButtonUpload htmlFor={'file'} data-testid="profile-picture-popup-button-upload" outline>
					Reupload
				</ButtonUpload>
				<ProfilePicturePopup.Button onClick={onUpload}>Save</ProfilePicturePopup.Button>
			</ButtonWrapper>
		</>
	)
}

ProfilePicturePopup.ButtonUpload = function ProfilePicturePopupButtonUpload({ setFile, ...props }) {
	return (
		<>
			<ButtonUpload htmlFor={'file'} data-testid="profile-picture-popup-button-upload" outline>
				Upload Photo
			</ButtonUpload>
		</>
	)
}

ProfilePicturePopup.Button = function ProfilePicturePopupButton({ setFile, children, ...props }) {
	return (
		<>
			<Button data-testid="profile-picture-popup-button" {...props}>
				{children}
			</Button>
		</>
	)
}
