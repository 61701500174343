import React from 'react'
import styled from 'styled-components'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Constants
import * as BREAK from 'constants/breakpoints'
import * as ROUTES from 'constants/routes'

// * Data
import teamData from 'fixtures/team.json'

// * Components
import { FooterComp, NavbarComp } from 'components/global'
import { FeatureSection } from 'components/homepage/'
import { Team } from 'components/homepage/team/'
import { Hero } from 'components/homepage/header'

const Section = styled.header`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding-bottom: 140px;

	@media (max-width: ${BREAK._768}) {
		padding-bottom: 80px;
	}
`

const LOCAL = {
	defaultTeamPath: '/images/team',
}
export default function TeamPage() {
	return (
		<>
			<Section>
				<NavbarComp hasAnchor trailing="dropdown" />

				<Hero.TeamContainer>
					<Hero.Title>
						Our <Hero.Underline>Team</Hero.Underline>
					</Hero.Title>
					<Hero.Text size="--TYPE_XLP">Who’s behind Zark</Hero.Text>
					<Hero.PageLink>
						<ReachRouterLink data-testid="hero-page-router-link" to={ROUTES.HOMEPAGE}>
							Home
						</ReachRouterLink>{' '}
						/ Our Team
					</Hero.PageLink>
				</Hero.TeamContainer>

				<Team>
					<Team.Header>
						<Team.Description>
							We come from various tech backgrounds and have come together because of the recent events call for urgency in being better equipped, not just in the
							fundraising arena, but where these funds are actually funneled to. We created this tool as an additional way to contribute and to help everyone in having
							a tangible way to control the flow of their help.
						</Team.Description>
					</Team.Header>
					<Team.Main>
						{teamData.map(member => (
							<Team.Member key={member.id}>
								<Team.Image webp={`${LOCAL.defaultTeamPath}/${member.image}.webp`} jpg={`${LOCAL.defaultTeamPath}/${member.image}.jpg`} alt={member.image} />
								<Team.Name>{member.name}</Team.Name>
								<Team.Role>{member.role}</Team.Role>
								<Team.Industry>{member.industry}</Team.Industry>
								<Team.Social facebook={member.social.facebook} linkedin={member.social.linkedin} />
							</Team.Member>
						))}
					</Team.Main>
				</Team>
			</Section>
			<FeatureSection />
			<FooterComp hasAnchor />
		</>
	)
}
