import React, { useEffect } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { useAppSelector } from 'store/combinedReducer'
import { removeSnackbar } from 'store/snackbarSlice'

import { IconClose } from 'assets'

import './styleOverride.css'
// write a hoc for SnackbarProviderexport
export const SnackProvider = ({ children }) => {
	return (
		<SnackbarProvider
			autoHideDuration={3000}
			maxSnack={5}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			{children}
		</SnackbarProvider>
	)
}

let displayed = []

const CustomizedSnackbars = () => {
	console.log('CustomizedSnackbars rendered')
	const dispatch = useAppDispatch()
	const notifications = useAppSelector(state => state.snackbar.notifications)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const storeDisplayed = id => {
		displayed = [...displayed, id]
	}

	const removeDisplayed = id => {
		displayed = [...displayed.filter(key => id !== key)]
	}
	const notificationsCopy = [...notifications]
	useEffect(() => {
		console.log('CustomizedSnackbars: useEffect running', notifications)
		notificationsCopy.forEach(({ key, message, options = {}, dismissed = false }) => {
			console.log('CustomizedSnackbars: ForEach running', { key, message })

			if (dismissed) {
				console.log('CustomizedSnackbars: Dismissed running', { key })
				// dismiss snackbar using notistack
				closeSnackbar(key)
				return
			}

			// do nothing if snackbar is already displayed
			if (displayed.includes(key)) return

			console.log('CustomizedSnackbars: enqueueSnackbar running', { key, message })
			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				action: key => <IconClose height="25" onClick={() => closeSnackbar(key)} />,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						console.log('CustomizedSnackbars: onClose running', { key, message })
						options.onClose(event, reason, myKey)
					}
				},
				onExited: (event, myKey) => {
					try {
						// remove this snackbar from redux store
						console.log('CustomizedSnackbars: onExited running', { key, message })
						dispatch(removeSnackbar(myKey))
						removeDisplayed(myKey)
					} catch (error) {
						console.log('CustomizedSnackbars error', error)
					}
				},
			})

			// keep track of snackbars that we've displayed
			storeDisplayed(key)
		})
	}, [notifications, closeSnackbar, enqueueSnackbar])

	return null
}

export default CustomizedSnackbars
