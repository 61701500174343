import React from 'react'

// * Styles
import { Container, Content, Wrapper } from './styled'

// * Components
import { FundPopupComp, ProfilePicturePopupComp } from 'components/dashboard/cards'
import { GreetProfile } from './GreetProfile'
import { GreetIcon } from './GreetIcon'
import { GreetPickFunds } from './GreetPickFunds'
import { GreetContent } from './GreetContent'
import { GreetChart } from './GreetChart'

export default function GreetComp() {
	const [fundPopupIsOpen, setFundPopupIsOpen] = React.useState(false)
	const [profilePicturePopupIsOpen, setProfilePicturePopupIsOpen] = React.useState(false)

	const onCloseProfilePicturePopup = () => {
		setProfilePicturePopupIsOpen(false)
	}

	const onOpenProfilePicturePopup = () => {
		setProfilePicturePopupIsOpen(true)
	}

	return (
		<>
			{fundPopupIsOpen && <FundPopupComp setFundPopupIsOpen={setFundPopupIsOpen} />}
			{profilePicturePopupIsOpen && <ProfilePicturePopupComp onClose={onCloseProfilePicturePopup} />}

			<Container>
				<Wrapper>
					<GreetProfile onClick={onOpenProfilePicturePopup} />
					<Content>
						<GreetContent />
						<GreetPickFunds setFundPopupIsOpen={setFundPopupIsOpen} />
					</Content>
					<GreetIcon />
				</Wrapper>

				<GreetChart />
			</Container>
		</>
	)
}
