import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const slice = createSlice({
	name: 'snackbar',
	initialState: {
		notifications: [],
	},
	reducers: {
		addSnack: (_, action) => {
			_.notifications.push(action.payload)
		},

		removeSnack: (_, action) => {
			_.notifications = _.notifications.filter(notification => notification.key !== action.payload.key)
		},
	},
})

export const { addSnack, closeSnack, removeSnack } = slice.actions

export default slice.reducer

// Action Creators
export const enqueueSnackbar = (message, options) => dispatch => {
	const key = uuidv4()
	dispatch({
		type: addSnack.type,
		payload: { key, message, options },
	})

	// setTimeout(() => {
	// 	dispatch(closeSnackbar(key))
	// }, 3000)
}

export const removeSnackbar = key => dispatch => {
	dispatch({
		type: removeSnack.type,
		payload: { key },
	})
}
