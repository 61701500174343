import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: row;
	max-width: 890px;
	margin: auto;
	column-gap: 80px;

	@media (max-width: ${BREAK._1100}) {
		flex-direction: column;
		column-gap: 0px;
		align-items: center;
	}
`
export const Header = styled.header`
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: ${BREAK._1100}) {
		text-align: center;
	}
`

export const Headline = styled.h2`
	@media (max-width: ${BREAK._1100}) {
		max-width: 400px;
	}
`

export const Description = styled.p``

export const Main = styled.main`
	margin-top: -20px;

	@media (max-width: ${BREAK._1100}) {
		margin-top: 20px;
		max-width: 620px;
	}
`

export const Item = styled.div`
	border-bottom: solid 2px var(--COLOR_GREY);
`

export const Question = styled.div`
	font-size: var(--TYPE_LP);
	font-weight: 600;
	cursor: pointer;
	user-select: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 0;
	margin: 2px 0;

	img {
		user-select: none;
	}

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	@media (max-width: ${BREAK._1100}) {
		font-size: var(--TYPE_MP);
	}
`

export const Answer = styled.p`
	white-space: pre-wrap;
	&.closed {
		max-height: 0;
		overflow: hidden;
	}
	&.open {
		max-height: 1200px;
		padding-bottom: 20px;
	}
`
