import React from 'react'
import { Link as ReachRouterLink, useNavigate } from 'react-router-dom'
import { Formik as FormikWrapper, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

// * Constants
import * as ROUTES from 'constants/routes'

// * Redux
import { validateUser } from 'store/authSlice'
import { useAppSelector } from 'store/combinedReducer'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Form from '..'

const validateMost = Yup.object({
	name: Yup.string().max(25, 'Must be 25 characters  or less').required('Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	phone: Yup.string().min(14, 'Please enter your phone number').required('Phone is required'),
	password: Yup.string()
		.required('Password is required')
		.min(5, 'Password must be longer than 5 characters.')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'Password must contain 1 uppercase, 1 lowercase')
		.matches(/^(?=.*[!@#\$%\^&\*])/, 'Password must contain 1 special case character ( ex. !@#$%^&* )')
		.matches(/^(?=.{6,20}$)\D*\d/, 'Password must contain 1 number'),
	password2: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Password must match')
		.required('Confirm password is required'),
	toggleEventCoupon: Yup.boolean(),
})

const validateCode = Yup.object({
	phoneCode: Yup.string().required().length(4, 'Must be 4 digits').label('Code'),
})

export default function RegisterFlow() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const auth = useAppSelector(state => state.auth)
	const [showError, setShowError] = React.useState(false)
	const [showCode, setShowCode] = React.useState(false)

	React.useEffect(() => {
		auth.authenticated && navigate(ROUTES.WIZARD)
	}, [auth, navigate])

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(validateUser(values, 'register', setSubmitting, setShowError, setShowCode))
	}

	return showCode ? (
		<Form>
			<Form.Header>
				<Form.Title>Enter Code</Form.Title>
				{showError && <Form.InfoBox log="error">Sorry, the pin you entered is old or incorrect.</Form.InfoBox>}
				<Form.Description>A text message with a 4-digit verification code was sent to your phone</Form.Description>
			</Form.Header>
			<FormikWrapper
				initialValues={{
					phoneCode: '',
				}}
				validationSchema={validateCode}
				onSubmit={handleSubmit}
			>
				<FormikForm>
					<Form.CodeInput name="phoneCode" placeholder="####" maxLength={4} autoComplete="off" autoFocus />
					<Form.Button>Verify and Sign Up</Form.Button>
				</FormikForm>
			</FormikWrapper>
		</Form>
	) : (
		<>
			<Form>
				<Form.Header>
					<Form.Title>Create a new account</Form.Title>
					{showError && (
						<Form.InfoBox log="error">
							This email address is already being used. Go to <ReachRouterLink to={ROUTES.LOGIN}>Log in</ReachRouterLink>.
						</Form.InfoBox>
					)}
				</Form.Header>
				<FormikWrapper
					initialValues={{
						name: '',
						email: '',
						phone: '',
						password: '',
						password2: '',
					}}
					validationSchema={validateMost}
					onSubmit={handleSubmit}
				>
					<FormikForm>
						<Form.Input name="name" type="name" placeholder="Name" />
						<Form.Input name="email" type="email" placeholder="Email" />
						<Form.MaskedInput name="phone" type="text" placeholder="Phone" mask="(000) 000-0000" />
						<Form.PasswordInput name="password" placeholder="Password" />
						<Form.Input name="password2" type="password" placeholder="Confirm Password" />
						{/* <Form.Checkbox name="toggleEventCoupon" type="checkbox" to={ROUTES.EVENT} /> */}
						<Form.Button>Sign Up</Form.Button>
						<Form.TextWithLink>
							Already have an account? <ReachRouterLink to={ROUTES.LOGIN}>Log In</ReachRouterLink>
						</Form.TextWithLink>
					</FormikForm>
				</FormikWrapper>
			</Form>
			<Form.Footer>
				<Form.TextWithLink>
					By signing up, you agree to Zark’s <br />
					<a rel="noopener noreferrer" target="_blank" href="/documents/Zark Terms of Use.pdf">
						Terms & Conditions
					</a>{' '}
					and{' '}
					<a rel="noopener noreferrer" target="_blank" href="/documents/Zark Privacy Policy.pdf">
						Privacy Policy
					</a>
					.
				</Form.TextWithLink>
			</Form.Footer>
		</>
	)
}
