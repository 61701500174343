import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

// * Constants
import { STORAGE_KEYS } from 'constants/storageKeys'

// * Utils
import ZARK from 'utils/api'

// * Redux
import { enqueueSnackbar } from 'store/snackbarSlice'

const slice = createSlice({
	name: 'auth',
	initialState: {
		user: {
			roundUpsActive: undefined,
			hasProvidedCCforContributions: undefined,
			_id: undefined,
			name: undefined,
			email: undefined,
			selectedFund: undefined,
			profileImage: undefined,
			isTopContributor: false,
		},
		loading: null,
		authenticated: null,
	},
	reducers: {
		userRequested: (_, action) => {
			_.loading = true
		},
		userAuthenticating: (_, action) => {
			_.loading = false
		},
		userResetting: (_, action) => {
			_.loading = false
		},
		userAuthenticated: (_, action) => {
			_.user.email = action.payload.email
			_.user._id = action.payload._id
			_.user.name = action.payload.name
			_.user.selectedFund = action.payload.selectedFund
			_.user.profileImage = action.payload.profileImage
			_.user.isTopContributor = action.payload.isTopContributor
			_.loading = false
			_.authenticated = true
		},
		userAuthenticationFailed: (_, action) => {
			_.loading = false
		},
		userRequestFailed: (_, action) => {
			_.loading = false
		},
		userLoggedOut: (_, action) => {
			_.authenticated = false
		},
		fundRequested: (_, action) => {
			/** */
		},
		fundSelected: (_, action) => {
			_.user.selectedFund = action.payload
		},
		fundRequestFailed: (_, action) => {
			/** */
		},
		profileUploadRequested: (_, action) => {
			/** */
		},
		profileUploaded: (_, action) => {
			_.user.profileImage = action.payload
		},
		profileUploadRequestFailed: (_, action) => {
			/** */
		},
	},
})

const {
	userRequested,
	userAuthenticated,
	userAuthenticationFailed,
	userAuthenticating,
	userResetting,
	userRequestFailed,
	userLoggedOut,
	fundRequested,
	fundSelected,
	fundRequestFailed,
	profileUploadRequested,
	profileUploaded,
	profileUploadRequestFailed,
} = slice.actions

export default slice.reducer

const root = 'users'

const route = {
	v1: `${root}/v1`,
	v2: `${root}/v2`,
}

// Action Creators
export const getUser = () => dispatch => {
	dispatch({ type: userRequested.type })

	ZARK.get(`${route.v2}/self`)
		.then(res => dispatch({ type: userAuthenticated.type, payload: res.data.user }))
		.catch(() => {
			localStorage.removeItem(STORAGE_KEYS.JWT_TOKEN)
			sessionStorage.clear()
			dispatch({ type: userRequestFailed.type })
			dispatch(enqueueSnackbar('You have logged out', { variant: 'warning' }))
			dispatch({ type: userLoggedOut.type })
			window.location.href = '/'
		})
}

export const resetUserPassword = (userData, setFormikSubmitting, setShowError, setShowCode, setShowReset) => dispatch => {
	dispatch({ type: userRequested.type })

	ZARK.post(`${route.v2}/reset-password`, userData)
		.then(res => {
			setShowError(false)

			if (!userData.phoneCode && !userData.phoneCode) {
				setFormikSubmitting(false)
				setShowCode(true)
				dispatch({ type: userAuthenticating.type })
				dispatch(enqueueSnackbar('Check your phone for a 4-digit code', { variant: 'info' }))
				return
			}

			if (userData.phoneCode && !userData.password) {
				setFormikSubmitting(false)
				setShowReset(true)
				dispatch({ type: userResetting.type })
				dispatch(enqueueSnackbar('Enter your new password', { variant: 'info' }))
				return
			}

			if (userData.phoneCode && userData.password) {
				dispatch(enqueueSnackbar('Please wait while we reset your password..', { variant: 'info' }))
				setFormikSubmitting(false)
				setShowCode(false)
				setShowReset(false)

				const { token } = res.data
				localStorage.setItem(STORAGE_KEYS.JWT_TOKEN, token)
				const decoded = jwt_decode(token)
				dispatch({ type: userAuthenticated.type, payload: decoded })
				dispatch(enqueueSnackbar('Your password has been reset', { variant: 'success' }))
				return
			}
		})
		.catch(() => {
			setFormikSubmitting(false)
			setShowError(true)
			dispatch(enqueueSnackbar('Something went wrong while resetting your password', { variant: 'error' }))
			dispatch({ type: userAuthenticationFailed.type })
			return
		})
}

export const validateUser = (userData, userAction, setFormikSubmitting, setHasError, setShowCode) => dispatch => {
	dispatch({ type: userRequested.type })

	ZARK.post(`${route.v2}/${userAction === 'login' ? 'login' : 'register'}`, userData)
		.then(res => {
			setHasError(false)

			if (!userData.phoneCode) {
				setFormikSubmitting(false)
				setShowCode(true)
				dispatch({ type: userAuthenticating.type })
				return dispatch(enqueueSnackbar('Check your phone for a 4-digit code', { variant: 'info' }))
			}

			dispatch(enqueueSnackbar('Please wait while we verify your account...', { variant: 'info' }))
			setFormikSubmitting(false)
			setShowCode(false)

			const { token } = res.data
			console.log('token', token)
			localStorage.setItem(STORAGE_KEYS.JWT_TOKEN, token)
			const decoded = jwt_decode(token)
			dispatch({ type: userAuthenticated.type, payload: decoded })
			if (userAction === 'register') dispatch(enqueueSnackbar('Authenticated, please pick a cause!', { variant: 'success' }))
			if (userAction === 'login') dispatch(enqueueSnackbar('Welcome to your ZARK dashboard!', { variant: 'success' }))
		})
		.catch(() => {
			setFormikSubmitting(false)
			setHasError(true)
			dispatch(enqueueSnackbar('Something went wrong while verifying your account', { variant: 'error' }))
			dispatch({ type: userAuthenticationFailed.type })
		})
}

export const logoutUser = () => dispatch => {
	localStorage.removeItem(STORAGE_KEYS.JWT_TOKEN)
	sessionStorage.clear()
	dispatch({ type: userLoggedOut.type })
	dispatch(enqueueSnackbar('You have logged out', { variant: 'warning' }))
	window.location.href = '/'
}

export const selectFund = fund => dispatch => {
	dispatch({ type: fundRequested.type })
	ZARK.post(`${route.v2}/select-fund`, { fund })
		.then(() => {
			dispatch({ type: fundSelected.type, payload: fund })
			dispatch(enqueueSnackbar(`Fund bucket has been updated to ${fund}`, { variant: 'success' }))
		})
		.catch(err => {
			console.log(err)
			dispatch(enqueueSnackbar('Something went wrong while updating your fund bucket', { variant: 'error' }))
			dispatch({ type: fundRequestFailed.type })
		})
}

export const uploadProfile = formData => dispatch => {
	dispatch({ type: profileUploadRequested.type })
	dispatch(enqueueSnackbar('Updating your profile picture...', { variant: 'info' }))
	ZARK.post(`${route.v2}/upload-profile`, formData)
		.then(res => {
			dispatch({ type: profileUploaded.type, payload: res.data })
			dispatch(enqueueSnackbar('Profile picture has been updated', { variant: 'success' }))
		})
		.catch(err => {
			dispatch(enqueueSnackbar('Something went wrong while updating your profile picture', { variant: 'error' }))
			dispatch({ type: profileUploadRequestFailed.type })
		})
}
