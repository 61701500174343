import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Types
import * as T from './index.types'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Logo = styled.div<T.StyledFooterLogoProps>`
	position: relative;
	height: 60px;
	width: 200px;
	margin-bottom: 50px;
	z-index: 1;

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	& a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: url(${({ logo }) => logo});
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	/** */
	@media (max-width: ${BREAK._768}) {
		height: 50px;
		margin-bottom: 25px;
	}
`

export const Links = styled.div`
	margin-bottom: 40px;
	display: flex;
	flex-direction: row;
	column-gap: 30px;
	flex-wrap: wrap;
	justify-content: center;

	@media (max-width: ${BREAK._768}) {
		margin-bottom: 20px;
	}
`

export const Link = styled.a`
	font-size: var(--TYPE_ML);
	padding: 5px 2px;
	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_SL);
	}
`

export const Social = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-direction: row;
	column-gap: 10px;

	a {
		&:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Copyright = styled.p`
	font-size: var(--TYPE_SL);
	color: var(--COLOR_GREY_DARK);
	text-align: center;
`
