import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const LeftHandSide = styled.div`
	max-width: 150px;
	width: 100%;
`

export const RightHandSide = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-end;

	gap: 20px;
`

export const ChartUpdate = styled.div<{ number: number }>`
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
	max-width: 250px;
	width: 100%;

	p {
		font-size: var(--TYPE_SL);
	}

	& #icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		flex-shrink: 0;
		border-radius: 100%;
		background-color: ${props => (props.number > 0 ? 'var(--COLOR_GREEN_BG_FLAT)' : 'var(--COLOR_RED_BG_FLAT)')};

		& svg {
			height: 30px;
		}
	}

	@media (max-width: ${BREAK._768}) {
		max-width: 100%;
		justify-content: center;
		gap: 10px;

		#icon-wrapper {
			width: 30px;
			height: 30px;

			& svg {
				height: 20px;
			}
		}
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;

	@media (max-width: ${BREAK._768}) {
		flex-direction: column;
		gap: 20px;
	}
`

export const Separator = styled.div`
	position: relative;
	align-self: center;
	width: calc(100% + 70px);
	height: 2px;
	background-color: var(--COLOR_GREY_LIGHT);

	@media (max-width: ${BREAK._1100}) {
		width: calc(100% + 30px);
	}
`
