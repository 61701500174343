import React from 'react'

// * Styles
import * as G from 'components/onboarding/globalStyled'
import * as S from './styled'

export default function Funds({ children, ...props }) {
	return (
		<G.Container data-testid="funds-container" {...props}>
			{children}
		</G.Container>
	)
}

//** Header Children */
Funds.Header = function FundsHeader({ children, ...props }) {
	return (
		<G.Header data-testid="funds-header" {...props}>
			{children}
		</G.Header>
	)
}

Funds.Tag = function FundsTag({ children, ...props }) {
	return (
		<G.Tag data-testid="funds-tag" {...props}>
			<span>{children}</span>
		</G.Tag>
	)
}

Funds.Title = function FundsTitle({ children, ...props }) {
	return (
		<G.Title data-testid="funds-title" {...props}>
			{children}
		</G.Title>
	)
}

Funds.Description = function FundsDescription({ children, ...props }) {
	return (
		<G.Description data-testid="funds-description" {...props}>
			{children}
		</G.Description>
	)
}

//** Selection Children */
Funds.Selection = function FundsSelection({ children, ...props }) {
	return (
		<S.Selection data-testid="funds-selection" {...props}>
			{children}
		</S.Selection>
	)
}

Funds.Item = function FundsItem({ active, children, ...props }) {
	return (
		<S.Item data-testid="funds-item" className={active ? 'active' : 'inactive'} {...props}>
			{children}
		</S.Item>
	)
}

Funds.ItemTitleGroup = function FundsItemTitleGroup({ children, ...props }) {
	return (
		<S.ItemTitleGroup data-testid="funds-item-title-group" {...props}>
			{children}
		</S.ItemTitleGroup>
	)
}

Funds.ItemRadio = function FundsItemRadio({ ...props }) {
	return <S.ItemRadio id="item-radio" {...props} />
}

Funds.ItemLogo = function FundsItemLogo({ children, ...props }) {
	return (
		<S.ItemLogo data-testid="fund-item-logo" {...props}>
			{children}
		</S.ItemLogo>
	)
}

Funds.ItemFundLogo = function FundsFundLogo({ ...props }) {
	return <S.ItemFundLogo data-testid="funds-item-fund-logo" {...props} />
}

Funds.ItemTitle = function FundsItemTitle({ children, ...props }) {
	return (
		<S.ItemTitle data-testid="funds-item-title" {...props}>
			{children}
		</S.ItemTitle>
	)
}

//** Body Children */
Funds.Body = function FundsBody({ children, ...props }) {
	return (
		<G.Body data-testid="funds-body" {...props}>
			{children}
		</G.Body>
	)
}

Funds.Button = function FundsButton({ children, ...props }) {
	return (
		<G.Button data-testid="funds-button" {...props}>
			{children}
		</G.Button>
	)
}
