import React from 'react'

// * Styles
import * as S from './styled'

export default function Members({ children, ...props }) {
	return (
		<S.Container data-testid="members-container" {...props}>
			{children}
		</S.Container>
	)
}

Members.Group = function MembersGroup({ row1Data, row2Data, scrollDistance, children, ...props }) {
	return (
		<>
			<S.Row data-testid="members-row1" move={scrollDistance}>
				{row1Data.map(member => (
					<S.Item>
						<S.ItemProfile />
						<S.ItemContent>
							<S.ItemName>{member.name}</S.ItemName>
							<S.ItemDescr>{member.descr}</S.ItemDescr>
						</S.ItemContent>
					</S.Item>
				))}
			</S.Row>
			<S.Row data-testid="members-row2" move={-scrollDistance}>
				{row2Data.map(member => (
					<S.Item>
						<S.ItemProfile />
						<S.ItemContent>
							<S.ItemName>{member.name}</S.ItemName>
							<S.ItemDescr>{member.descr}</S.ItemDescr>
						</S.ItemContent>
					</S.Item>
				))}
			</S.Row>
		</>
	)
}
