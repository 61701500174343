import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import authReducer from './authSlice'
import plaidReducer from './plaidSlice'
import stripeReducer from './stripeSlice'
import snackReducer from './snackbarSlice'

const rootReducer = combineReducers({
	plaid: plaidReducer,
	stripe: stripeReducer,
	auth: authReducer,
	snackbar: snackReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export const useAppSelector: TypedUseSelectorHook<RootState | any> = useSelector

export default rootReducer
