import styled from 'styled-components'
import { StyleOptions } from './types'

// TabToggle
export const Container = styled.div<{ styleOption: StyleOptions; isMaxWidth: boolean }>`
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: ${props => (props.isMaxWidth ? 'space-between' : 'space-evenly')};
	width: ${props => (props.isMaxWidth ? '100%' : 'max-content')};
	/* height: 40px; */
	/* border: 1px solid #ccc; */
	background-color: var(--COLOR_GREY_LIGHT);
	border-radius: 20px;
	cursor: pointer;
	gap: ${props => (props.styleOption === 'small' ? '1px' : '5px')};
	user-select: none;
`

// TabToggle
export const Tab = styled.div<{ isActive: boolean; index: number; styleOption: StyleOptions; isMaxWidth: boolean }>`
	position: relative;
	margin: 2px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	background-color: ${props => (props.isActive ? 'var(--COLOR_PRIMARY)' : 'transparent')};
	color: ${props => (props.isActive ? 'var(--COLOR_SECONDARY)' : 'var(--COLOR_GREY_DARK)')};
	font-size: ${props => (props.styleOption === 'small' ? 'var(--TYPE_SP)' : 'var(--TYPE_MP)')};
	padding: ${props => (props.styleOption === 'small' ? '1px 20px' : '5px 20px')};
	border-radius: 20px;
	/* border-radius: ${props => (props.isActive ? '20px' : '0')}; */
	transition: background-color 0.2s ease-in-out;

	${props => props.isMaxWidth && `width: 50%;`}

	${props =>
		!props.isActive &&
		`
		:hover {
		background-color: var(--COLOR_WHITE);
	}
	`}
`

export const Separator = styled.div<{ styleOption: StyleOptions }>`
	height: ${props => (props.styleOption === 'small' ? '4px' : '8px')};
	width: ${props => (props.styleOption === 'small' ? '4px' : '8px')};
	border-radius: 50%;
	flex-shrink: 0;

	background-color: var(--COLOR_GREY);
	opacity: 0.5;
`
