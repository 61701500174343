import React, { useState } from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as S from './styled'

// * Components
import { ArrowButton } from 'components/global'

export default function Fund({ children }) {
	return <S.Container data-testid="fund-container">{children}</S.Container>
}

Fund.Header = function FundHeader({ children }) {
	return <S.Header data-testid="fund-header">{children}</S.Header>
}

Fund.Headline = function FundHeadline({ children }) {
	return <S.Headline data-testid="fund-headline">{children}</S.Headline>
}

Fund.Description = function FundDescription({ children }) {
	return <S.Description data-testid="fund-description">{children}</S.Description>
}

Fund.Main = function FundMain({ children }) {
	return <S.Main data-testid="fund-main">{children}</S.Main>
}

Fund.Item = function FundItem({ children }) {
	return <S.Item data-testid="fund-item">{children}</S.Item>
}

Fund.Logo = function FundLogo({ children, ...props }) {
	return (
		<S.Logo data-testid="fund-logo" {...props}>
			{children}
		</S.Logo>
	)
}

Fund.LogoItem = function FundLogoItem({ children, ...props }) {
	return (
		<S.LogoItem data-testid="fund-icon" {...props}>
			{children}
		</S.LogoItem>
	)
}

Fund.Title = function FundTitle({ children }) {
	return <S.Title data-testid="fund-title">{children}</S.Title>
}

Fund.Text = function FundText({ children }) {
	return <S.Text data-testid="fund-text">{children}</S.Text>
}

Fund.Detail = function FundDetail({ children }) {
	const [open, setOpen] = useState(false)
	return (
		<S.Detail data-testid="fund-detail" onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
			{open && children}
		</S.Detail>
	)
}

Fund.DetailInner = function FundDetailInner({ children, ...props }) {
	return (
		<S.DetailInner {...props}>
			<div id="fund-detail-background">{children}</div>
		</S.DetailInner>
	)
}

Fund.DetailContent = function FundDetailContent({ children, ...props }) {
	return (
		<S.DetailContent data-testid="fund-detail-content" {...props}>
			{children}
		</S.DetailContent>
	)
}

Fund.DetailBody = function FundDetailBody({ children, ...props }) {
	return (
		<S.DetailBody data-testid="fund-detail-body" {...props}>
			{children}
		</S.DetailBody>
	)
}

Fund.DetailLogo = function FundDetailLogo({ children, ...props }) {
	return (
		<S.DetailLogo data-testid="fund-detail-logo" {...props}>
			{children}
		</S.DetailLogo>
	)
}

Fund.DetailFundTitle = function FundDetailFundTitle({ link, children, ...props }) {
	return (
		<S.DetailFundTitle {...props}>
			{link ? (
				<a data-testid="fund-detail-fund-title" rel="noopener noreferrer" target="_blank" href={link}>
					{children}
					<img src="/images/icons/icon-link.svg" alt="link icon" />
				</a>
			) : (
				children
			)}
		</S.DetailFundTitle>
	)
}

Fund.DetailFundSubTitle = function FundDetailFundSubTitle({ to, href, children, ...props }) {
	return (
		<S.DetailFundDescription data-testid="fund-detail-fund-description" {...props}>
			{children}
		</S.DetailFundDescription>
	)
}

Fund.DetailFundButton = function FundDetailFundButton({ to, href, children }) {
	return (
		<>
			{to && (
				<ReachRouterLink data-testid="fund-detail-fund-button-router-link" to={to}>
					<S.DetailFundButton data-testid="fund-detail-fund-button">{children}</S.DetailFundButton>
				</ReachRouterLink>
			)}
			{href && (
				<a data-testid="fund-detail-fund-button-link" href={href} rel="noopener noreferrer" target="_blank">
					<S.DetailFundButton data-testid="fund-detail-fund-button">{children}</S.DetailFundButton>
				</a>
			)}
		</>
	)
}

Fund.DetailFundAction = function FundDetailFundAction(props) {
	return (
		<ReachRouterLink data-testid="fund-detail-fund-button-router-link" to={props.to}>
			<ArrowButton children={props.children} />
		</ReachRouterLink>
	)
}
