import React from 'react'
import { GroupItem, GroupWrapper, Logo } from './styled'

// * Data
import fundData from 'fixtures/funds.json'

const GroupedFundPick = ({ fundGroup, setFundGroup }) => {
	React.useEffect(() => {
		setFundGroup('')
	}, [])

	React.useEffect(() => {
		console.log('fundGroup', fundGroup)
	}, [fundGroup])

	const onFundClick = fund => {
		setFundGroup(fund)
	}
	return (
		<GroupWrapper>
			{fundData.map(_item => (
				<GroupItem key={_item.id} title={_item.elevator} active={fundGroup === _item.slug} onClick={() => onFundClick(_item.slug)}>
					<div id={'radio'} />
					<div id={'content'}>
						<p id="title">{_item.title}</p>
						<div id={'logo-wrapper'}>
							{_item.funds.map(_fund => (
								<Logo key={_fund.id} src={`/images/assets/${_fund.icon}`} />
							))}
						</div>
					</div>
				</GroupItem>
			))}
		</GroupWrapper>
	)
}

export default GroupedFundPick
