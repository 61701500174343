import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { addCard } from 'store/stripeSlice'

// * Components
import StripePopup from '..'

export default function StripePopupComp({ onClose }) {
	const dispatch = useAppDispatch()
	const stripePromise = loadStripe('pk_live_7QsJUcLPtH7dY5uJXr1z1mfK')

	const handleStripe = cardId => dispatch(addCard(cardId))

	return (
		<StripePopup onClose={onClose}>
			<StripePopup.Title>Add Payment Method</StripePopup.Title>
			<StripePopup.Text>Please enter your credit card information that you would like to use for contributions.</StripePopup.Text>
			<Elements stripe={stripePromise}>
				<StripeFormWrapper addCardToStripe={handleStripe} closePopup={onClose} />
			</Elements>
		</StripePopup>
	)
}

const StripeFormWrapper = ({ closePopup, addCardToStripe }) => {
	const elements = useElements()
	const stripe = useStripe()

	const handleSubmit = async event => {
		event.preventDefault()

		const cardElement = elements.getElement(CardElement)
		const stripeResult = await stripe.createToken(cardElement)
		if (stripeResult && stripeResult.token) {
			addCardToStripe(stripeResult.token.id)
			closePopup()
		}
	}

	const CARD_OPTIONS = {
		style: {
			base: {
				padding: '15px 20px',
				color: '#32325d',
				fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '18px',
				marginTop: '35px',
				marginBottom: '35px',
				letterSpacing: '0.025em',

				'::placeholder': {
					color: '#aab7c4',
				},
				':-webkit-autofill': {
					color: '#32325d',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
				':-webkit-autofill': {
					color: '#fa755a',
				},
			},
		},
	}

	return (
		<form onSubmit={handleSubmit}>
			<StripePopup.InputWrapper>
				<CardElement options={CARD_OPTIONS} />
			</StripePopup.InputWrapper>
			<StripePopup.ButtonWrapper>
				<StripePopup.Button outline onClick={closePopup}>
					Go Back
				</StripePopup.Button>
				<StripePopup.Button type="submit" disabled={!stripe}>
					Add Card
				</StripePopup.Button>
			</StripePopup.ButtonWrapper>
		</form>
	)
}
