import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'
export const ContainerShadow = styled.div`
	box-shadow: var(--SHADOW_PHONE);
	border-radius: 50px;

	@media (max-width: ${BREAK._768}) {
		order: 2;
	}
`

export const ContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: #111;
	position: relative;
	border-radius: 50px;
	overflow: hidden;
	border: 7px solid #333838;
`

export const ContainerTapArea = styled.div`
	width: 50%;
	z-index: 999;
`

export const ContainerTapOverlay = styled.div`
	position: absolute;
	height: inherit;
	width: inherit;
	display: flex;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(255, 255, 255, 0) 20%);
`

export const ProgressWrapper = styled.div`
	display: flex;
	justify-content: center;
	max-width: 100%;
	flex-wrap: row;
	position: absolute;
	width: 50%;
	padding-top: 30px;
	align-self: center;
	z-index: 1001;
`

export const ProgressBack = styled.div`
	background: #ffffff36;
	height: 8px;
	max-width: 100%;
	margin: 5px;
	border-radius: 16px;
	transition: opacity 400ms ease-in-out;
	overflow: hidden;
	width: ${({ width }) => width * 100}%;
	opacity: ${({ pause, bufferAction }) => (pause && !bufferAction ? 0 : 1)};
`

export const ProgressInner = styled.div`
	background: #fff;
	height: 100%;
	max-width: 100%;
	border-radius: 16;
	transform-origin: center left;

	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;

	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000;
`

export const PlayPauseWrapperPause = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 80px;
	height: 80px;

	background-image: url('images/icons/icon-player-play.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;

	opacity: 0.6;

	z-index: 1006;
	cursor: pointer;
`

export const PlayPauseWrapperPlay = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 80px;
	height: 80px;

	background-image: url('images/icons/icon-player-pause.svg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;

	z-index: 1006;
	cursor: pointer;

	animation: linear fadein 1s normal forwards;

	@keyframes fadein {
		0% {
			opacity: 0.6;
		}
		90% {
			opacity: 0.6;
		}
		100% {
			opacity: 0;
		}
	}

	&:hover {
		opacity: 0.3 !important;
	}
`

export const VolumeWrapper = styled.div`
	position: absolute;
	z-index: 1003;
	cursor: pointer;
	display: block;
	top: 20px;
	right: 30px;
`

export const IconWrapper = styled.div`
	height: 40px;
	width: 40px;
	background-color: #ffffff36;
	opacity: 1;
	border-radius: 100%;
	transition: 250ms linear;
	img {
		width: 100%;
	}

	:hover {
		opacity: 0.8;
	}
`
