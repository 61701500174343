import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as S from './styled'

// * Components
import { ArrowButton } from 'components/global'

export default function Team({ children }) {
	return <S.Container data-testid="team-container">{children}</S.Container>
}

Team.Header = function TeamHeader({ children }) {
	return <S.Header data-testid="team-header">{children}</S.Header>
}
Team.Headline = function TeamHeadline({ children }) {
	return <S.Headline data-testid="team-headline">{children}</S.Headline>
}
Team.Description = function TeamDescription({ children }) {
	return <S.Description data-testid="team-description">{children}</S.Description>
}
Team.Main = function TeamMain({ children }) {
	return <S.Main data-testid="team-main">{children}</S.Main>
}
Team.Member = function TeamMember({ children }) {
	return <S.Member data-testid="team-member">{children}</S.Member>
}

Team.Stack = function TeamStack({ children, ...props }) {
	return (
		<S.Stack data-testid="team-stack" {...props}>
			{children}
		</S.Stack>
	)
}

Team.Slack = function TeamSlack({ children }) {
	return (
		<S.Slack data-testid="team-msg">
			<img src="/images/icons/icon-msg.svg" alt="" />
			<div>
				<h3>Come meet the team</h3>
				<p>Join Zark’s Messenger chat and share your stories with us. We can’t wait to meet you and answer your questions.</p>
				<ArrowButton href="https://m.me/j/AbbI6ToGNVPzh7or/" openInNewTab>
					Join the chat
				</ArrowButton>
			</div>
		</S.Slack>
	)
}

Team.StackImage = function TeamStackImage({ jpg, webp, alt, children, ...props }) {
	return (
		<S.StackImage data-testid="team-stack-image" {...props}>
			<picture>
				<source srcSet={webp} type="image/webp" />
				<source srcSet={jpg} type="image/jpg" />
				<img src={''} alt={alt} />
			</picture>
		</S.StackImage>
	)
}

Team.Image = function TeamImage({ jpg, webp, alt, ...props }) {
	return (
		<S.Image data-testid="team-image" {...props}>
			<picture>
				<source srcSet={webp} type="image/webp" />
				<source srcSet={jpg} type="image/jpg" />
				<img src={''} alt={alt} />
			</picture>
		</S.Image>
	)
}
Team.Name = function TeamName({ children }) {
	return <S.Name data-testid="team-name">{children}</S.Name>
}
Team.Role = function TeamRole({ children }) {
	return <S.Role data-testid="team-role">{children}</S.Role>
}
Team.Industry = function TeamIndustry({ children }) {
	return <S.Industry data-testid="team-industry">{children}</S.Industry>
}
Team.Social = function TeamSocial({ facebook, linkedin }) {
	return (
		<S.Social data-testid="team-social">
			{facebook && (
				<a href={facebook} rel="noopener noreferrer" target="_blank">
					<img src="/images/icons/handle-icon-facebook.svg" alt="facebook" />
				</a>
			)}
			{linkedin && (
				<a href={linkedin} rel="noopener noreferrer" target="_blank">
					<img src="/images/icons/handle-icon-linkedin.svg" alt="linkedin" />
				</a>
			)}
		</S.Social>
	)
}

Team.Button = function TeamButton({ to, children, ...props }) {
	return (
		<ReachRouterLink data-testid="team-button-router-link" to={to}>
			<S.Button data-testid="team-button" {...props}>
				{children}
			</S.Button>
		</ReachRouterLink>
	)
}
