import React from 'react'
import LogRocket from 'logrocket'

// * Redux
import { getUser } from 'store/authSlice'
import { useAppSelector } from 'store/combinedReducer'
import { useAppDispatch } from 'store/configureStore'
import { getAccounts, makeAccountsPrettier } from 'store/plaidSlice'
import { getCard, getSubscriptions } from 'store/stripeSlice'

// * Components
import { AccountsComp, ContributionComp, GreetComp, SubscriptionComp } from 'components/dashboard/cards'
import Dash from 'components/dashboard/Dashboard'
import { NavbarComp } from 'components/global'

export default function DashboardNew() {
	const [stripePopupIsOpen, setStripePopupIsOpen] = React.useState(false)
	const [monthlyPopupIsOpen, setMonthlyPopupIsOpen] = React.useState(false)
	const dispatch = useAppDispatch()
	const plaid = useAppSelector(state => state.plaid)
	const auth = useAppSelector(state => state.auth)

	React.useEffect(() => {
		dispatch(getUser())
		dispatch(getCard())
		dispatch(getAccounts())
		dispatch(getSubscriptions())
	}, [dispatch])

	React.useEffect(() => {
		const LOG_ROCKET_PROJECT_ID = 'egkb3d/zark'
		LogRocket.init(LOG_ROCKET_PROJECT_ID)
		LogRocket.identify(auth?.user?._id, { name: auth?.user?.name, email: auth?.user?.email })
	}, [auth])

	React.useEffect(() => {
		makeAccountsPrettier(plaid.transactions, plaid.accounts)
	}, [plaid.transactions, plaid.accounts])

	return (
		<>
			<Dash.Maintenance>
				We are currently experiencing issues with linking bank accounts through Plaid. We are aware of the issue and are working on it. We apologize for any
				inconvenience this may cause and thank you for your patience.
			</Dash.Maintenance>

			<Dash>
				<NavbarComp trailing="logout" />
				<Dash.Contain>
					<Dash.Head>
						<GreetComp />
					</Dash.Head>
					<Dash.Body>
						<Dash.Column>
							<AccountsComp setStripePopupIsOpen={setStripePopupIsOpen} stripePopupIsOpen={stripePopupIsOpen} />
							<SubscriptionComp setStripePopupIsOpen={setStripePopupIsOpen} monthlyPopupIsOpen={monthlyPopupIsOpen} setMonthlyPopupIsOpen={setMonthlyPopupIsOpen} />
						</Dash.Column>
						<Dash.Column>
							<ContributionComp />
						</Dash.Column>
					</Dash.Body>
				</Dash.Contain>
			</Dash>
		</>
	)
}
