import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Header = styled.header`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 20px;
`

export const Headline = styled.h1`
	margin-bottom: 15px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H2);
		margin-bottom: 6px;
	}
`

export const Description = styled.p`
	max-width: 625px;
`

export const Main = styled.main`
	max-width: 900px;
`

export const Image = styled.div`
	& img {
		width: 100%;
	}
`
