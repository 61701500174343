import React from 'react'

// * Styles
import * as S from './styled'

const ToggleContext = React.createContext()

export default function Faq({ children }) {
	return <S.Container data-testid="faq-container">{children}</S.Container>
}

Faq.Header = function FaqHeader({ children }) {
	return <S.Header data-testid="faq-header">{children}</S.Header>
}

Faq.Headline = function FaqHeadline({ children }) {
	return <S.Headline data-testid="faq-headline">{children}</S.Headline>
}

Faq.Description = function FaqDescription({ children }) {
	return <S.Description data-testid="faq-description">{children}</S.Description>
}

Faq.Main = function FaqMain({ children }) {
	return <S.Main data-testid="faq-main">{children}</S.Main>
}

Faq.Item = function FaqItem({ children }) {
	const [toggleShow, setToggleShow] = React.useState(false)
	return (
		<ToggleContext.Provider value={{ toggleShow, setToggleShow }}>
			<S.Item data-testid="faq-item">{children}</S.Item>
		</ToggleContext.Provider>
	)
}

Faq.Question = function FaqQuestion({ children }) {
	const { toggleShow, setToggleShow } = React.useContext(ToggleContext)

	return (
		<S.Question onClick={() => setToggleShow(!toggleShow)} data-testid="faq-question">
			{children}
			{toggleShow ? <img src="/images/icons/icon-accordion-close.svg" alt="Close" /> : <img src="/images/icons/icon-accordion-open.svg" alt="Open" />}
		</S.Question>
	)
}

Faq.Answer = function FaqAnswer({ children }) {
	const { toggleShow } = React.useContext(ToggleContext)
	return (
		<S.Answer className={toggleShow ? 'open' : 'closed'} data-testid="faq-answer">
			{children}
		</S.Answer>
	)
}
