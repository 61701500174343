import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Components
import { IconLeftArrow } from 'assets'

const Container = styled.a`
	cursor: pointer;

	align-items: center;
	display: flex;
	flex-direction: row;
	width: fit-content;

	font-weight: 600;

	& span {
		opacity: 1;
		transition: opacity 250ms ease-in-out;
	}

	&:hover {
		& span {
			opacity: 0.8;
		}
	}

	font-size: var(--TYPE_LP);
	gap: 6px;
	transition: gap 100ms ease-in-out;

	& svg {
		height: 32px;
	}

	&:hover {
		gap: 10px;
	}

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_SL);
		gap: 4px;

		& svg {
			height: 26px;
		}

		&:hover {
			gap: 8px;
		}
	}
`

interface ArrowButtonProps {
	/** Use fill prop to affect the color of both the arrow and the text */
	fill?: string
	/** Use fillArrow prop to affect the color of the arrow individually */
	fillArrow?: string
	/** Use fillText prop to affect the color of the text individually */
	fillText?: string
	/** Use href prop to identify sections within a document, navigate to external links (make sure to enable openInNewTab for better UX). If you wish to navigate to an app route then use navigateTo prop */
	href?: string
	/** Use openInNewTab with the href prop */
	openInNewTab?: boolean
	/** Use navigateTo prop to navigate to your desired route */
	navigateTo?: string
	/** Use children prop as the label of the button */
	children: string
}

export default function ArrowButton(props: ArrowButtonProps) {
	return props.navigateTo ? (
		<ReachRouterLink to={props.navigateTo}>
			<Container>
				<span style={{ color: props.fill || props.fillText }}>{props.children}</span>
				<IconLeftArrow fill={props.fill || props.fillArrow} />
			</Container>
		</ReachRouterLink>
	) : (
		<Container href={props.href} rel={props.openInNewTab ? 'noopener noreferrer' : ''} target={props.openInNewTab ? '_blank' : ''}>
			<span style={{ color: props.fill || props.fillText }}>{props.children}</span>
			<IconLeftArrow fill={props.fill || props.fillArrow} />
		</Container>
	)
}
