import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as S from './styled'

export default function Hero({ children }) {
	return <S.Container data-testid="hero-container">{children}</S.Container>
}

Hero.TeamContainer = function HeroTeamContainer({ children, ...props }) {
	return <S.TeamContainer {...props}>{children}</S.TeamContainer>
}

Hero.Title = function HeroTitle({ children }) {
	return <S.Title data-testid="hero-title">{children}</S.Title>
}

Hero.Pill = function HeroPill({ children, ...props }) {
	return (
		<S.Pill data-testid="hero-pill" {...props}>
			{children}
		</S.Pill>
	)
}

Hero.Text = function HeroText({ children, ...props }) {
	return (
		<S.Text data-testid="hero-text" {...props}>
			{children}
		</S.Text>
	)
}

Hero.Content = function HeroContent({ children, ...props }) {
	return (
		<S.Content data-testid="hero-content" {...props}>
			{children}
		</S.Content>
	)
}

Hero.Underline = function HeroUnderline({ children, ...props }) {
	return <S.Underline {...props}>{children}</S.Underline>
}

Hero.PageLink = function HeroPageLink({ children, ...props }) {
	return (
		<S.PageLink data-testid="hero-page-link" {...props}>
			{children}
		</S.PageLink>
	)
}

Hero.Image = function HeroImage({ webp, png, ...props }) {
	return (
		<S.Image data-testid="hero-image" {...props}>
			<picture>
				<source srcSet={webp} type="image/webp" />
				<source srcSet={png} type="image/png" />
				<img src={''} alt="hero" />
			</picture>
		</S.Image>
	)
}

Hero.Button = function HeroButton({ to, href, children, ...props }) {
	return (
		<>
			{to && (
				<ReachRouterLink data-testid="hero-button-router-link" to={to}>
					<S.Button data-testid="hero-button">{children}</S.Button>
				</ReachRouterLink>
			)}
			{href && (
				<a data-testid="hero-button-link" href={href} rel="noopener noreferrer" target="_blank">
					<S.Button data-testid="hero-button">{children}</S.Button>
				</a>
			)}
		</>
	)
}
