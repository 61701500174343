import styled from 'styled-components/macro'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Section = styled.section`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 0px 20px 100px 20px;
	min-height: 100vh;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`

export const Container = styled.div`
	max-width: 500px;
	width: 100%;
	background-color: white;
	padding: 50px 55px 30px 55px;
	border-radius: 12px;
	box-shadow: var(--SHADOW_SOFT);
	@media (max-width: ${BREAK._768}) {
		padding: 40px 20px 40px 20px;
	}
`

export interface StyledNavbarProps {
	logo?: string
}
export const Navbar = styled.div<StyledNavbarProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100%;
	margin-bottom: 40px;
	@media (max-width: ${BREAK._768}) {
		margin-bottom: 20px;
	}

	::before {
		content: ' ';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	& a {
		z-index: 1;
		height: 60px;
		width: 200px;
		background-image: url(${({ logo }) => logo});
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Header = styled.div`
	margin-bottom: 25px;
	text-align: center;
`

export const Title = styled.h3`
	margin-bottom: 15px;
`

export const Tag = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;

	width: 100%;
	margin-bottom: 20px;

	& span {
		font-size: var(--TYPE_MP);
		border-radius: 50px;
		padding: 5px 20px;
		background-color: var(--COLOR_GREY_WARM_LIGHT);
	}
`

export const Description = styled.p`
	margin-bottom: 15px;
	color: var(--COLOR_PRIMARY70);

	white-space: pre-line;
`

export interface StyledInfoBoxProps {
	log?: 'error' | 'info' | 'warning' | 'success'
}
export const InfoBox = styled.div<StyledInfoBoxProps>`
	font-size: var(--TYPE_SP);
	line-height: 1.5em;
	text-align: start;
	margin-bottom: 15px;
	padding: 15px 20px;
	a {
		font-weight: 600;
		text-decoration: underline;
	}
	${({ log }) =>
		log === 'error'
			? `color: var(--COLOR_RED_TEXT);
	        background-color: var(--COLOR_RED_BG);
	        border: 1px solid var(--COLOR_RED);`
			: log === 'info'
			? `color: var(--COLOR_BLUE_TEXT);
	        background-color: var(--COLOR_BLUE_BG);
	        border: 1px solid var(--COLOR_BLUE);`
			: log === 'warning'
			? `color: var(--COLOR_YELLOW_TEXT);
	        background-color: var(--COLOR_YELLOW_BG);
	        border: 1px solid var(--COLOR_YELLOW);`
			: log === 'success'
			? `color: var(--COLOR_GREEN_TEXT);
	        background-color: var(--COLOR_GREEN_BG);
	        border: 1px solid var(--COLOR_GREEN);`
			: null}
`

export const Body = styled.form``

export const Input = styled.input<any>`
	width: 100%;
	padding: 0 25px 0 30px;
	font-size: var(--TYPE_MP);
	background: transparent;
	border: none;
	outline: 0;
	z-index: 1;

	::placeholder {
		color: var(--COLOR_GREY_DARK);
	}

	:focus ~ #input-background {
		outline: 2px solid var(--COLOR_SECONDARY);
		${({ error }) => error && `outline: 2px solid var(--COLOR_RED);`}
	}
`

export const OutsideWrapper = styled.div`
	margin-bottom: 20px;

	.error {
		color: var(--COLOR_RED_TEXT);
		margin-top: 10px;
	}
`

export const InputWrapper = styled.div`
	height: 70px;
	position: relative;
	display: flex;

	#input-background {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: var(--COLOR_GREY_WARM_LIGHT);
		z-index: 0;
	}
`

export const CodeInput = styled.input<any>`
	width: 100%;
	padding: 0 25px 0 30px;
	font-size: var(--TYPE_H2);
	text-align: center;
	font-family: var(--FONT_ZARK);
	background: transparent;
	border: none;
	outline: 0;
	z-index: 1;

	::placeholder {
		color: var(--COLOR_GREY_DARK);
	}

	::-webkit-inner-spin-button,
	::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	:focus ~ #input-background {
		outline: 2px solid var(--COLOR_SECONDARY);
	}

	${({ error }) => error && `outline: 2px solid var(--COLOR_RED);`}
`

export const ShowPassword = styled.div`
	background-color: transparent;
	font-size: var(--TYPE_MP);
	font-weight: 500;
	color: var(--COLOR_SECONDARY);
	margin-right: 20px;
	margin-left: -15px;
	z-index: 1;
	place-self: center;
	cursor: pointer;
`

export const ButtonWrapper = styled.div`
	position: relative;
	display: grid;
	flex-direction: row;

	grid-template-columns: 0.6fr 1fr;
	gap: 10px;
`

export interface StyledButtonProps {
	outline?: boolean
}
export const Button = styled.button<StyledButtonProps>`
	position: relative;
	font-size: var(--TYPE_LP);
	font-family: var(--FONT_ZARK);
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	:disabled {
		color: var(--COLOR_GREY_DARK);
		background-color: var(--COLOR_GREY);
		cursor: not-allowed;
	}

	& > button {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		opacity: 0;
		z-index: 1;
	}

	${({ outline }) =>
		outline
			? `color: var(--COLOR_PRIMARY);
	        background-color: var(--COLOR_WHITE);
	        border: 2px solid var(--COLOR_PRIMARY);`
			: `color: var(--COLOR_SECONDARY);
	        background-color: var(--COLOR_PRIMARY);
	        `}
`

export const Footer = styled.div``

export const TextWithLink = styled.div`
	text-align: center;
	margin-top: 20px;
	a {
		font-weight: 700;
		color: var(--COLOR_SECONDARY);
	}
`

export const FundLink = styled.a`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 3px 7px;
	display: flex;
	flex-direction: row;
	font-weight: 600;
	color: var(--COLOR_SECONDARY);
	:hover {
		text-decoration: underline;
		opacity: 0.8;
		transition: opacity 300ms;
	}

	& img {
		margin-right: 5px;
		width: 16px;
	}
`

export const FundLinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	justify-content: center;
`

export const Image = styled.div`
	height: 200px;
	position: relative;

	& .plate {
		position: relative;
		display: flex;

		padding: 30px 35px 40px 35px;
		background: transparent;
		border-radius: 30px;

		& img {
			position: relative;
			margin: auto;
			width: 100%;
			z-index: 1;
		}

		&::after {
			content: ' ';
			position: absolute;
			border-radius: 30px 30px 0 0;
			background-color: var(--COLOR_WHITE);
			box-shadow: var(--SHADOW_ILLUSTRATION);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
		}

		&::before {
			content: ' ';
			position: absolute;
			border-radius: 0;
			background-color: white;
			box-shadow: 0px -30px 20px #ffffff;
			top: 90%;
			bottom: -20px;
			left: -10px;
			right: -10px;
			z-index: 1;
		}
	}
`

export const SecureWrapper = styled.div`
	margin-bottom: 25px;
`

export const SecureText = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 3px;
	z-index: 3;

	& #lock-icon {
		height: 20px;
	}
`

export const SecureImage = styled.img`
	height: 34px;
	margin: 0 5px;
`
