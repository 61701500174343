import React from 'react'

// * Constants
import * as ROUTES from 'constants/routes'
import { STORAGE_KEYS } from 'constants/storageKeys'

// * Components
import { Skeleton } from 'components/dashboard/loaders'
import Onboarding, { FeaturesFlow, FundsFlow, MonthlyFlow, RoundupFlow } from 'components/onboarding'

export default function Wizard() {
	const initialStep = () => Number(sessionStorage.getItem(STORAGE_KEYS.WIZARD_STEP)) || 1
	const [step, setStep] = React.useState(initialStep)

	React.useEffect(() => {
		sessionStorage.setItem(STORAGE_KEYS.WIZARD_STEP, JSON.stringify(step))
	}, [step])

	const nextStep = () => setStep(step => step + 1)
	const prevStep = () => setStep(step => step - 1)

	switch (step) {
		case 1:
			return (
				<Onboarding>
					<Onboarding.Navbar to={ROUTES.HOMEPAGE} />
					<FundsFlow onNextStep={nextStep} />
				</Onboarding>
			)
		case 2: {
			return (
				<Onboarding>
					<Onboarding.Navbar to={ROUTES.HOMEPAGE} />
					<FeaturesFlow onPrevStep={prevStep} onNextStep={nextStep} />
				</Onboarding>
			)
		}
		case 3: {
			return (
				<Onboarding>
					<Onboarding.Navbar to={ROUTES.HOMEPAGE} />
					{sessionStorage.getItem(STORAGE_KEYS.SELECTED_FEATURE) === 'roundup' ? <RoundupFlow onPrevStep={prevStep} /> : <MonthlyFlow onPrevStep={prevStep} />}
				</Onboarding>
			)
		}
		default: {
			return <Skeleton />
		}
	}
}
