import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	position: relative;
	display: grid;
	flex-direction: row;

	grid-template-columns: 0.6fr 1fr;
	gap: 10px;
`

export const Button = styled.label`
	position: relative;
	font-size: var(--TYPE_LP);
	font-family: var(--FONT_ZARK);
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	:hover {
		opacity: 0.8;
		transition: 300ms;
		cursor: pointer;
	}

	${({ outline }) =>
		outline
			? `color: var(--COLOR_PRIMARY);
        background-color: var(--COLOR_WHITE);
        border: 2px solid var(--COLOR_PRIMARY);`
			: `color: var(--COLOR_SECONDARY);
        background-color: var(--COLOR_PRIMARY);
        `}
`

export const SliderWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	margin: 10px 0;
`
