import React from 'react'
import { useAppSelector } from 'store/combinedReducer'
import { IconBadge } from 'assets'
import { Profile } from './styled'

export const GreetProfile = (props: { onClick: () => void }) => {
	const auth = useAppSelector(state => state.auth)

	const image = auth.user.profileImage || '/images/icons/z-icon.svg'

	return (
		<Profile onClick={props.onClick} src={image}>
			<div className="overlay"></div>
			<img src="images/icons/icon-photo-plus.svg" alt="" />
			{auth.user.isTopContributor && <IconBadge title="This is your badge for being one of the top 10 contributors" id="icon-badge" />}
		</Profile>
	)
}
