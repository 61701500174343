import * as React from 'react'

// export interface Renderer extends React.FC<{
//     action: Action;
//     isMuted: boolean;
//     isPaused: boolean;
//     story: Story;
//     config: {
//         width?: NumberOrString;
//         height?: NumberOrString;
//         loader?: JSX.Element;
//         header?: Function;
//         storyStyles?: Object;
//     };
//     messageHandler: (type: string, data: any) => ({ ack: 'OK' | 'ERROR' })
// }> { }
export const Renderer = ({ story, action }) => {
	React.useEffect(() => {
		action('play')
	}, [story])

	return (
		<div style={styles.storyContent}>
			<p style={styles.text}>This story could not be loaded.</p>
		</div>
	)
}

const styles = {
	storyContent: {
		width: '100%',
		maxHeight: '100%',
		margin: 'auto',
	},
	text: {
		textAlign: 'center',
		color: 'white',
		width: '90%',
		margin: 'auto',
	},
} as any

export const tester = () => {
	return { condition: true, priority: 1 }
}

export default { renderer: Renderer, tester }
