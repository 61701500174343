export default function useDimensions({ data, getYValue, bounds }) {
	const width = bounds.width || 100
	const height = bounds.height || 100

	const margin = {
		top: 16,
		left: 0,
		bottom: 0,
		right: 48,
	}

	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	const maxValue = Math.max(...data.map(getYValue))
	const minValue = Math.min(...data.map(getYValue))
	return { width, height, innerHeight, innerWidth, maxValue, minValue, margin }
}
