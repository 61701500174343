import React from 'react'

// * Constants
import * as ROUTES from 'constants/routes'

// * Components
import Onboarding, { LoginFlow } from 'components/onboarding'

export default function Login() {
	return (
		<Onboarding>
			<Onboarding.Navbar to={ROUTES.HOMEPAGE} />
			<LoginFlow />
		</Onboarding>
	)
}
