import React from 'react'
import styled from 'styled-components'

// * Components
import * as ROUTES from 'constants/routes'
import * as BREAK from 'constants/breakpoints'

// * Constants
import { Feature } from '.'

const Section = styled.section`
	background-color: var(--COLOR_WHITE);
	padding: 100px 20px 50px 20px;

	@media (max-width: ${BREAK._768}) {
		padding: 50px 20px 0 20px;
	}
`

const LOCAL = {
	defaultAssetPath: '/images/assets',
	imgRoundup: 'feature-roundup-inner',
	imgMonthly: 'feature-monthly-inner',
	imgPercentage: 'feature-percent-inner',
	imgZarkCard: 'feature-card',
}

export default function FeatureSection() {
	return (
		<Section id="feature">
			<Feature>
				<Feature.Header>
					<Feature.Headline>3 Ways to Contribute</Feature.Headline>
					<Feature.Description></Feature.Description>
				</Feature.Header>

				<Feature.Main>
					<Feature.Item id="roundup">
						<Feature.Body>
							<Feature.Label>For U.S. Residents</Feature.Label>
							<Feature.Title>Round up</Feature.Title>
							<Feature.Text>
								Securely link your existing credit card or bank accounts to Zark and donate the change from each transaction.
								<br />
								<span className="small">*All contributions are tax deductible.</span>
							</Feature.Text>
							<Feature.Button to={ROUTES.REGISTER}>Activate Round-Up</Feature.Button>
						</Feature.Body>
						<Feature.Illustration webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgRoundup}.webp`} png={`${LOCAL.defaultAssetPath}/${LOCAL.imgRoundup}.png`} />
					</Feature.Item>

					<Feature.Item rowReverse id="monthly">
						<Feature.Body>
							<Feature.Label>For Everyone</Feature.Label>
							<Feature.Title>Monthly amount</Feature.Title>
							<Feature.Text>
								Attach a card, pick subscription amount and become a patron to organizations of your choosing in Armenia. An easier way to budget for you charitable
								allowances.
								<br />
								<span className="small">*All contributions are tax deductible.</span>
							</Feature.Text>
							<Feature.Button to={ROUTES.REGISTER}>Set an amount</Feature.Button>
						</Feature.Body>
						<Feature.Illustration rowReverse webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgMonthly}.webp`} png={`${LOCAL.defaultAssetPath}/${LOCAL.imgMonthly}.png`} />
					</Feature.Item>

					<Feature.Item id="zark-card">
						<Feature.Body>
							<Feature.Label>Coming Soon</Feature.Label>
							<Feature.Title>Percentage</Feature.Title>
							<Feature.Text>
								Securely link your card to Zark and add another 1%, 5%, or 10% to the tip from every transaction you make to go to the organizations of your liking in
								Armenia.
								<br />
								<span className="small">*All contributions are tax deductible.</span>
							</Feature.Text>
							<Feature.Button href="https://reserve.zarkinow.com/card">Sign up</Feature.Button>
						</Feature.Body>
						<Feature.Illustration webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgPercentage}.webp`} png={`${LOCAL.defaultAssetPath}/${LOCAL.imgPercentage}.png`} />
					</Feature.Item>
				</Feature.Main>
			</Feature>
		</Section>
	)
}
