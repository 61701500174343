import React from 'react'
import { usePlaidLink, PlaidLinkOnSuccess } from 'react-plaid-link'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { useAppSelector } from 'store/combinedReducer'
import { addAccounts } from 'store/plaidSlice'

const ReconnectPlaidLink = ({ reLinkToken, children }) => {
	const dispatch = useAppDispatch()
	const account = useAppSelector(state => state.account)

	const onSuccess = React.useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
		const plaidData = {
			public_token: publicToken,
			metadata: metadata,
			accounts: account.bankAccounts,
		}

		console.log(plaidData)

		dispatch(addAccounts(plaidData))
			.then(() => console.log('addAccounts SUCCESS'))
			.catch(() => console.log('addAccounts ERROR'))
		console.log(publicToken, metadata)
	}, [])

	const { open, ready } = usePlaidLink({
		token: reLinkToken,
		onSuccess,
		// onEvent
		// onExit
	})

	return (
		<button onClick={() => open()} disabled={!ready}>
			{children}
		</button>
	)
}

export default ReconnectPlaidLink
