import React from 'react'

// * Constants
import { STORAGE_KEYS } from 'constants/storageKeys'
import * as ROUTES from 'constants/routes'

// * Components
import Features from '..'

export default function FeaturesFlow({ onPrevStep, onNextStep }) {
	const [feature, setFeature] = React.useState(sessionStorage.getItem(STORAGE_KEYS.SELECTED_FEATURE))

	React.useEffect(() => {
		sessionStorage.setItem(STORAGE_KEYS.SELECTED_FEATURE, feature)
	}, [feature])

	return (
		<Features>
			<Features.Header>
				<Features.Tag>Step 2 of 3</Features.Tag>
				<Features.Title>Contribution Method</Features.Title>
				<Features.Description>{'You can contribute in 2 ways through Zark\n(You can change this later)'}</Features.Description>
			</Features.Header>

			<Features.Body>
				<Features.Selection>
					<Features.Item
						active={feature === 'roundup'}
						onClick={() => {
							setFeature('roundup')
							console.log(`Selected Roundup`)
						}}
					>
						<Features.ItemTitle>Round-Up (🇺🇸 only)</Features.ItemTitle>
						<Features.ItemSubtitle>
							Zark will tally the total change from your transactions and send a deposit directly to the fundraiser of choice.
						</Features.ItemSubtitle>
						<Features.ItemRadio />
					</Features.Item>
					<Features.Item
						active={feature === 'monthly'}
						onClick={() => {
							setFeature('monthly')
							console.log(`Selected Monthly`)
						}}
					>
						<Features.ItemTitle>Fixed Monthly</Features.ItemTitle>
						<Features.ItemSubtitle>Zark will charge the set amount every month and send a deposit directly to the chosen fundraiser.</Features.ItemSubtitle>
						<Features.ItemRadio />
					</Features.Item>
				</Features.Selection>
				<Features.ButtonWrapper>
					<Features.Button
						outline
						onClick={e => {
							e.preventDefault()
							onPrevStep()
							// console.log('Clicked Go Back')
						}}
					>
						Go Back
					</Features.Button>
					<Features.Button
						disabled={feature !== 'null' ? false : true}
						onClick={e => {
							e.preventDefault()
							onNextStep()
						}}
					>
						Continue
					</Features.Button>
				</Features.ButtonWrapper>
			</Features.Body>
		</Features>
	)
}
