import { TabToggle } from 'components/global'
import React from 'react'
import { useAppSelector } from 'store/combinedReducer'

import { Chart } from './Chart'
import { useAppDispatch } from 'store/configureStore'
import { ChartUpdate, LeftHandSide, RightHandSide, Row, Separator } from './styled'
import { IconArrowDown, IconArrowUp } from 'assets'
import { prepareMonthlyTransactionsForChart } from 'store/stripeSlice'
import { prepareRoundupTransactionsForChart } from 'store/plaidSlice'

const OPTIONS = {
	NORMAL: 'Monthly Chart',
	DIFF: 'Trend Chart',
	SUM: 'Cumulative Chart',
}

export const GreetChart = () => {
	const [selectedOption, setSelectedOption] = React.useState(OPTIONS.NORMAL)
	const [averageTrend, setAverageTrend] = React.useState(0)
	const [showChart, setShowChart] = React.useState(false)

	const stripe = useAppSelector(state => state.stripe)
	const plaid = useAppSelector(state => state.plaid)
	const auth = useAppSelector(state => state.auth)

	const totalContributions = React.useMemo(() => {
		const monthly = prepareMonthlyTransactionsForChart(stripe.transactions)
		const roundup = prepareRoundupTransactionsForChart(plaid.transactions)
		return [...monthly, ...roundup]
	}, [stripe.transactions, plaid.transactions])

	React.useEffect(() => {
		if (totalContributions.length > 0 && !auth.loading) {
			const timeoutId = setTimeout(() => setShowChart(true), 1000)
			return () => clearTimeout(timeoutId)
		}
	}, [totalContributions.length, auth.loading])

	return (
		<>
			{showChart && (
				<>
					{console.log('totalContributions', totalContributions)}
					{/** Add a fake loader with 1-2 sec delay before showing the actual chart */}
					<Separator />
					{/* <LeftHandSide>{`Most recent roundups: ${recentRoundup.number}`}</LeftHandSide> */}
					<RightHandSide>
						<Row>
							{averageTrend && (
								<ChartUpdate number={averageTrend}>
									<div id="icon-wrapper">{averageTrend > 0 ? <IconArrowUp fill="var(--COLOR_GREEN_TEXT)" /> : <IconArrowDown fill="var(--COLOR_RED_TEXT)" />}</div>
									<p>
										Your average trend is {averageTrend === 0 ? '' : averageTrend > 0 ? 'positive' : 'negative'} (<b>${averageTrend}</b>)
									</p>
								</ChartUpdate>
							)}

							<TabToggle options={[OPTIONS.NORMAL, OPTIONS.DIFF, OPTIONS.SUM]} onChange={option => setSelectedOption(option)} styleOption={'small'} />
						</Row>

						<Chart
							type={selectedOption === OPTIONS.NORMAL ? 'normal' : selectedOption === OPTIONS.DIFF ? 'diff' : selectedOption === OPTIONS.SUM ? 'sum' : 'normal'}
							resample={1}
							chartData={totalContributions}
							setAverageTrend={setAverageTrend}
						/>
					</RightHandSide>
				</>
			)}
		</>
	)
}
