import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

const Spinner = () => {
	return (
		<Player
			autoplay
			loop
			src="/images/lotties/fun-loader.json"
			style={{
				height: '30px',
				width: '30px',
			}}
		/>
	)
}

export default Spinner
