export const HOMEPAGE = '/'
export const REGISTER = '/register'
export const LOGIN = '/login'
export const RESET_PASSWORD = '/reset-password'
export const WIZARD = '/wizard'
export const DASHBOARD = '/dashboard'
export const TEAM = '/team'
export const TEMPORARY_RELINK_ACCOUNT = '/relink-bank-account'
export const THANK_YOU_RECONNECT = '/thank-you-reconnect'
export const EVENT = '/event'
