import styled from 'styled-components'

export const Selection = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
	gap: 30px;
`

export const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	border-radius: 12px;
	background-color: var(--COLOR_SECONDARY_NEUTRAL);
	cursor: pointer;

	&.active {
		outline: 2px solid var(--COLOR_SECONDARY);
		background-color: var(--COLOR_GREY_WARM_LIGHT);
	}

	&.active #item-radio {
		border: 6px solid var(--COLOR_SECONDARY);
	}

	:hover {
		background-color: var(--COLOR_GREY_WARM_LIGHT);
	}
`

export const ItemLogo = styled.div`
	display: flex;
	flex-direction: row;
	& :nth-child(1) {
		margin-left: 0px;
	}
	margin-bottom: 10px;
`

export const ItemFundLogo = styled.div`
	margin-left: -20px;
	height: 35px;
	width: 35px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 1px white;
	outline: solid 1px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
`

export const ItemTitleGroup = styled.div`
	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
`

export const ItemTitle = styled.div`
	#title {
		font-size: var(--TYPE_MP);
		font-weight: 600;
	}
`

export const ItemRadio = styled.div`
	width: 22px;
	height: 22px;
	flex-shrink: 0;
	border: 2px solid var(--COLOR_GREY);
	background-color: var(--COLOR_WHITE);
	border-radius: 100%;
	display: flex;
`
