import styled from 'styled-components'

export const ButtonWrapper = styled.div`
	position: relative;
	display: grid;
	flex-direction: row;

	grid-template-columns: 0.6fr 1fr;
	gap: 10px;
`

export const FundLink = styled.a`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	padding: 3px 7px;
	display: flex;
	flex-direction: row;
	font-weight: 600;
	color: var(--COLOR_SECONDARY);
	:hover {
		text-decoration: underline;
		opacity: 0.8;
		transition: opacity 300ms;
	}

	& img {
		margin-right: 5px;
		width: 16px;
	}
`

export const FundLinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	justify-content: center;
`

export const SecureWrapper = styled.div`
	margin-bottom: 25px;
`

export const SecureText = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 3px;
	z-index: 3;

	& #lock-icon {
		height: 20px;
	}
`

export const SecureImage = styled.img`
	height: 34px;
	margin: 0 5px;
`

export const Image = styled.div`
	height: 200px;
	position: relative;

	& .plate {
		position: relative;
		display: flex;

		padding: 30px 35px 40px 35px;
		background: transparent;
		border-radius: 30px;

		& img {
			position: relative;
			margin: auto;
			width: 100%;
			z-index: 1;
		}

		&::after {
			content: ' ';
			position: absolute;
			border-radius: 30px 30px 0 0;
			background-color: var(--COLOR_WHITE);
			box-shadow: var(--SHADOW_ILLUSTRATION);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
		}

		&::before {
			content: ' ';
			position: absolute;
			border-radius: 0;
			background-color: white;
			box-shadow: 0px -30px 20px #ffffff;
			top: 90%;
			bottom: -20px;
			left: -10px;
			right: -10px;
			z-index: 1;
		}
	}
`
