import * as React from 'react'

export const Renderer = props => {
	React.useEffect(() => {
		props.action('play')
	}, [props.story])
	const Content = props.story.originalContent
	return <Content {...props} />
}

// export type Tester = (story: Story) => {
//     condition: boolean;
//     priority: number;
// };
export const tester = story => {
	return {
		condition: !!story.content,
		priority: 2,
	}
}

export default { renderer: Renderer, tester }
