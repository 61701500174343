import React from 'react'

// * Styles
import { Container, Title, Text } from '../globalStyled'
import * as S from './styled'

export default function MonthlyPopup({ onClose, children, ...props }) {
	return (
		<Container data-testid="monthly-popup-container" {...props}>
			<div data-testid="monthly-popup-container-backdrop" className="backdrop" onClick={onClose}></div>
			<div data-testid="monthly-popup-container-card" className="card">
				{children}
			</div>
		</Container>
	)
}

MonthlyPopup.Title = function MonthlyPopupTitle({ children, ...props }) {
	return (
		<Title data-testid="monthly-popup-title" {...props}>
			{children}
		</Title>
	)
}

MonthlyPopup.Text = function MonthlyPopupText({ children, ...props }) {
	return (
		<Text data-testid="monthly-popup-text" {...props}>
			{children}
		</Text>
	)
}

MonthlyPopup.Select = function MonthlyPopupSelect({ children, ...props }) {
	return (
		<S.Select data-testid="monthly-popup-select" {...props}>
			{children}
		</S.Select>
	)
}

MonthlyPopup.Option = function MonthlyPopupOption({ children, ...props }) {
	return (
		<S.Option data-testid="monthly-popup-option" {...props}>
			${children}
		</S.Option>
	)
}

MonthlyPopup.Input = function MonthlyPopupInput({ children, ...props }) {
	return (
		<S.InputWrapper data-testid="monthly-popup-input-wrapper">
			<S.Input data-testid="monthly-popup-input" type="text" placeholder="$_" {...props} />

			<div id="input-background"></div>
		</S.InputWrapper>
	)
}

MonthlyPopup.Button = function MonthlyPopupButton({ children, ...props }) {
	return (
		<S.Button data-testid="monthly-popup-button" {...props}>
			{children}
		</S.Button>
	)
}
