import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Header = styled.header`
	text-align: center;
	margin-bottom: 100px;

	@media (max-width: ${BREAK._1100}) {
		margin-bottom: 50px;
	}

	@media (max-width: ${BREAK._768}) {
		margin-bottom: 20px;
	}
`

export const Headline = styled.h1`
	margin-bottom: 15px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H2);
		margin-bottom: 6px;
	}
`

export const Description = styled.p``

export const Main = styled.main`
	max-width: 1100px;
`

export const Item = styled.div`
	display: flex;
	flex-direction: ${({ rowReverse }) => rowReverse && 'row-reverse'};
	column-gap: 70px;
	align-items: flex-start;
	margin-bottom: 200px;

	&:nth-child(3) {
		margin-bottom: 100px;
	}

	@media (max-width: ${BREAK._1100}) {
		margin-bottom: 100px;

		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media (max-width: ${BREAK._768}) {
		margin-bottom: 50px;
	}
`

export const Body = styled.div`
	max-width: 470px;
	@media (max-width: ${BREAK._1100}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	@media (max-width: ${BREAK._768}) {
		max-width: 370px;
	}
`

export const Label = styled.h5`
	color: var(--COLOR_SECONDARY);
	margin-bottom: 4px;
	font-family: var(--FONT_ZARK);
`

export const Title = styled.h2`
	margin-bottom: 10px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H3);
		margin-bottom: 10px;
	}
`

export const Text = styled.p`
	font-size: var(--TYPE_MP);
	margin-bottom: 15px;

	.small {
		font-size: var(--TYPE_SP);
		color: var(--COLOR_GREY_DARK);
	}

	@media (max-width: ${BREAK._768}) {
		font-size: calc(var(--TYPE_MP)-5);
		margin-bottom: 15px;
	}
`

export const Button = styled.button`
	font-size: var(--TYPE_ML);
	font-family: var(--FONT_ZARK);
	padding: 0 40px;
	height: 80px;
	background-color: var(--COLOR_PRIMARY);
	color: var(--COLOR_SECONDARY);
	display: flex;
	justify-content: center;
	border-radius: 8px;
	align-items: center;

	@media (max-width: ${BREAK._768}) {
		height: 60px;
		font-size: calc(var(--TYPE_ML)-5);
	}

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}
`

export const Image = styled.div`
	height: 450px;
	position: relative;

	img {
		height: 100%;
		position: absolute;
		top: -15%;
	}
	@media (max-width: ${BREAK._1100}) {
		display: none;
	}
`

export const Illustration = styled.div`
	position: relative;
	width: 480px;

	@media (max-width: ${BREAK._1100}) {
		width: auto;
		display: none;
	}

	& .plate {
		position: relative;
		display: flex;

		padding: 15px 18px 20px 18px;
		background: transparent;
		border-radius: 30px;

		& img {
			position: relative;
			margin: auto;
			width: 100%;
			z-index: 3;
		}

		&::before {
			content: ' ';
			position: absolute;
			border-radius: 30px;
			background-color: var(--COLOR_GREY_WARM_LIGHT);
			${({ rowReverse }) =>
				rowReverse
					? `transform: rotate(-5deg);
				top: -35px;
				bottom: 50px;
				left: -30px;
				right: 30px;`
					: `transform: rotate(7deg);
				top: -12px;
				bottom: 30px;
				left: 20px;
				right: -20px;`}
		}

		&::after {
			content: ' ';
			position: absolute;
			border-radius: 30px;
			background-color: var(--COLOR_WHITE);
			box-shadow: var(--SHADOW_ILLUSTRATION);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
`
