import React from 'react'
import styled from 'styled-components'

// * VISX
import useMeasure from 'react-use-measure'
import { defaultStyles, useTooltip, TooltipWithBounds } from '@visx/tooltip'
import { scaleTime, scaleLinear } from '@visx/scale'
import { extent, bisector } from 'd3-array'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { LinePath, AreaClosed } from '@visx/shape'
import { curveBasis } from '@visx/curve'
import { localPoint } from '@visx/event'
import { AxisRight } from '@visx/axis'
import { timeFormat } from 'd3-time-format'

// * Fixures
import { IconArrowDown, IconArrowUp } from 'assets'

// * Types
import { ConditionTypes, DataPoint, TooltipData } from './types'

// * Styles
import { ChartContainer, TooltipDate, TooltipRoundups, TooltipWrapper } from './styled'

// * Utilities
import {
	useConditionalPipe,
	useCalculateLastMonthDiffToTotalAverage,
	useCalculateLastMonthDiffToLastThreeMonthAverage,
	useGetMostRecentRoundup,
} from './utilities'
import useScale from './useScale'
import useDimensions from './useDimensions'
import useTick from './useTick'

const getXValue = (d: DataPoint) => d.date
const getYValue = (d: DataPoint) => d.number

const bisectDate = bisector(getXValue).left

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
})

export const Chart = React.memo(function Chart({
	chartData,
	type,
	resample,
	setAverageTrend,
}: {
	type: ConditionTypes
	resample?: number
	chartData: any
	setAverageTrend: any
}) {
	// const mostRecentRoundup = useGetMostRecentRoundup(chartData)
	// const averageLast3 = useCalculateLastMonthDiffToLastThreeMonthAverage(chartData)
	const averageTotal = useCalculateLastMonthDiffToTotalAverage(chartData)

	React.useEffect(() => {
		setAverageTrend(averageTotal)
	}, [averageTotal])

	const data = useConditionalPipe(chartData, type, resample)
	const [ref, bounds] = useMeasure()

	const { showTooltip, hideTooltip, tooltipData, tooltipLeft = 0, tooltipTop = 0 } = useTooltip<TooltipData>()

	const { xScale, yScale } = useScale({ data, getYValue, getXValue, bounds })
	const { height, innerWidth } = useDimensions({ data, getYValue, bounds })
	const { yAxisTickValues, yAxisTickFormat } = useTick({ data, getYValue, bounds })

	function getPointOnPath(line: SVGPathElement | null, cursorPoint: { x: number; y: number }): { x: number; y: number } | null {
		if (line == null) {
			return null
		}

		const totalLengthOfLine = line.getTotalLength()
		if (totalLengthOfLine <= 0) {
			return null
		}

		const startingPoint = line.getPointAtLength(0)
		const endingPoint = line.getPointAtLength(totalLengthOfLine)

		if (cursorPoint.x < startingPoint.x || cursorPoint.x > endingPoint.x) {
			return null
		}

		let start = 0
		let end = totalLengthOfLine
		let middle = (start + end) / 2

		while (start <= end) {
			const pointAtMiddle = line.getPointAtLength(middle)
			if (pointAtMiddle.x === cursorPoint.x) {
				return pointAtMiddle
			} else if (pointAtMiddle.x < cursorPoint.x) {
				start = middle + 1
			} else {
				end = middle - 1
			}
			middle = (start + end) / 2
		}

		return line.getPointAtLength(middle)
	}

	const handleTooltip = React.useCallback(
		(event: React.TouchEvent<SVGSVGElement> | React.MouseEvent<SVGSVGElement>) => {
			const point = localPoint(event)

			const { x } = point

			const x0 = xScale.invert(x)
			const index = bisectDate(data, x0, 1)
			const d0 = data[index - 1]
			const d1 = data[index]
			let d = d0

			if (d1 && getXValue(d1)) {
				d = x0.valueOf() - getXValue(d0).valueOf() > getXValue(d1).valueOf() - x0.valueOf() ? d1 : d0
			}

			const pointOnPath = getPointOnPath(lineRef.current, point)

			if (!pointOnPath) {
				hideTooltip()
				return
			}

			showTooltip({
				tooltipData: {
					amount: d,
					circleLocation: pointOnPath,
				},
				tooltipLeft: pointOnPath?.x || x,
				tooltipTop: pointOnPath?.y || yScale(getYValue(d)),
			})
		},
		[data, hideTooltip, showTooltip, xScale, yScale]
	)

	const lineRef = React.useRef<SVGPathElement | null>(null)

	return (
		<ChartContainer>
			<svg ref={ref} width={'100%'} height={'100%'} onMouseMove={handleTooltip} onMouseLeave={hideTooltip} style={{ overflow: 'visible' }}>
				<Group>
					<AreaClosed
						curve={curveBasis}
						data={data}
						fill={'var(--COLOR_GREY_LIGHT)'}
						x={d => xScale(getXValue(d)) || 0}
						y={d => yScale(getYValue(d)) || 0}
						yScale={yScale}
					/>
					<Grid
						xScale={xScale}
						yScale={yScale}
						width={innerWidth}
						left={0}
						height={height}
						numTicksRows={4}
						numTicksColumns={0}
						rowTickValues={yAxisTickValues}
						strokeDasharray="4"
						stroke={'var(--COLOR_GREY)'}
					/>

					<AxisRight
						scale={yScale}
						left={innerWidth}
						orientation="right"
						hideAxisLine
						tickValues={yAxisTickValues}
						tickFormat={yAxisTickFormat}
						tickLabelProps={() => ({
							fill: 'var(--COLOR_PRIMARY)',
							fontSize: 12,
							fontWeight: 500,
							lineHeight: 0,
							opacity: 1,
							fillOpacity: 1,
							textAnchor: 'start',
							verticalAnchor: 'middle',
							x: 16,
						})}
						numTicks={4}
						hideTicks
						hideZero
					/>
				</Group>

				<Group>
					<LinePath<DataPoint>
						data={data}
						x={d => xScale(getXValue(d)) || 0}
						y={d => yScale(getYValue(d)) || 0}
						strokeWidth={5}
						stroke="var(--COLOR_PRIMARY)"
						curve={curveBasis}
						innerRef={lineRef}
					/>
					<circle
						cx={xScale(getXValue(data[data.length - 1])) || 0}
						cy={yScale(getYValue(data[data.length - 1])) || 0}
						r={8}
						fill="#ffffff"
						stroke="var(--COLOR_PRIMARY)"
						strokeWidth={4}
						pointerEvents={'none'}
					/>
				</Group>

				{tooltipData ? (
					<Group>
						<circle cx={tooltipData.circleLocation.x} cy={tooltipData.circleLocation.y} r={8} fill="var(--COLOR_PRIMARY)" pointerEvents={'none'} />
					</Group>
				) : null}
			</svg>
			{tooltipData ? (
				<TooltipWithBounds
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{
						...defaultStyles,
						borderRadius: 10,
						padding: 0,
						background: 'var(--COLOR_WHITE)',
						color: 'var(--COLOR_PRIMARY)',
						boxShadow: 'var(--SHADOW_SOFT)',
						overflow: 'hidden',
					}}
				>
					<TooltipWrapper>
						<TooltipDate>{`${timeFormat('%b %Y')(new Date(getXValue(tooltipData.amount)))}`}</TooltipDate>
						<TooltipRoundups>
							<p>Contributions:</p>{' '}
							{getYValue(tooltipData.amount) === 0 ? (
								<></>
							) : getYValue(tooltipData.amount) > 0 ? (
								<IconArrowUp height="20px" fill="var(--COLOR_GREEN)" />
							) : (
								<IconArrowDown height="20px" fill="var(--COLOR_RED)" />
							)}
							<b>{formatter.format(getYValue(tooltipData.amount))}</b>
						</TooltipRoundups>
					</TooltipWrapper>
				</TooltipWithBounds>
			) : null}
		</ChartContainer>
	)
})
