import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
`

export const Row = styled.div`
	display: flex;
	gap: 80px;
	transform: ${({ move }) => `translateX(${move}px)`};
`

export const Item = styled.div`
	background-color: white;
	box-shadow: var(--SHADOW_SOFT);
	padding: 12px;
	border-radius: 50px;
	width: 270px;
	height: 75px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 15px;
`

export const ItemContent = styled.div``

export const ItemName = styled.p`
	font-weight: 600;
	font-size: var(--TYPE_SL);
`

export const ItemDescr = styled.p`
	font-size: var(--TYPE_SL);
`

export const ItemProfile = styled.div`
	height: 52px;
	width: 52px;
	background-color: blue;
	border-radius: 100%;
`
