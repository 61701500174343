import React, { useContext, useState, useRef, useEffect } from 'react'
import GlobalContext from '../context/Global'
import StoriesContext from '../context/Stories'
import ProgressContext from '../context/Progress'
import { Story } from './Story'
import ProgressArray from './ProgressArray'
import * as Styled from '../styled'

export default function Stories() {
	const { width, height, loop, currentIndex, isPaused, isMuted, keyboardNavigation, preventDefault, storyContainerStyles = {} } = useContext(GlobalContext)

	const { stories } = useContext(StoriesContext)

	const [currentId, setCurrentId] = useState(0)
	const [pause, setPause] = useState(true)
	const [mute, setMute] = useState(!stories[currentId].muted)
	const [bufferAction, setBufferAction] = useState(true)
	const [videoDuration, setVideoDuration] = useState(0)

	let mousedownId = useRef()
	let isMounted = useRef(true)

	const IconMuted = () => {
		return (
			<Styled.IconWrapper>
				<img src="/images/icons/icon-muted.svg" alt="muted" />
			</Styled.IconWrapper>
		)
	}

	const IconUnmuted = () => {
		return (
			<Styled.IconWrapper>
				<img src="/images/icons/icon-unmuted.svg" alt="unmuted" />
			</Styled.IconWrapper>
		)
	}

	useEffect(() => {
		if (typeof currentIndex === 'number') {
			if (currentIndex >= 0 && currentIndex < stories.length) {
				setCurrentIdWrapper(() => currentIndex)
			} else {
				console.error('Index out of bounds. Current index was set to value more than the length of stories array.', currentIndex)
			}
		}
	}, [currentIndex])

	useEffect(() => {
		// if (typeof isPaused === 'boolean') {
		// 	setPause(isPaused)
		// }

		document.addEventListener('visibilitychange', function () {
			if (document.visibilityState === 'visible') {
				if (typeof isPaused === 'boolean') {
					setPause(isPaused)
				}
			} else {
				setPause(true)
			}
		})
	}, [isPaused])

	useEffect(() => {
		if (typeof isMuted === 'boolean') {
			setMute(isMuted)
		}
	}, [isMuted])

	useEffect(() => {
		const isClient = typeof window !== 'undefined' && window.document
		if (isClient && typeof keyboardNavigation === 'boolean' && keyboardNavigation) {
			document.addEventListener('keydown', handleKeyDown)
			return () => {
				document.removeEventListener('keydown', handleKeyDown)
			}
		}
	}, [keyboardNavigation])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	const handleKeyDown = e => {
		if (e.key === 'ArrowLeft') {
			previous()
		} else if (e.key === 'ArrowRight') {
			next()
		}
	}

	const toggleState = (action, bufferAction) => {
		setPause(action === 'pause')
		setBufferAction(!!bufferAction)
	}

	const setCurrentIdWrapper = callback => {
		setCurrentId(callback)
		toggleState('pause', true)
	}

	const previous = () => {
		setCurrentIdWrapper(prev => (prev > 0 ? prev - 1 : prev))
	}

	const next = () => {
		if (isMounted.current) {
			if (loop) {
				updateNextStoryIdForLoop()
			} else {
				updateNextStoryId()
			}
		}
	}

	const updateNextStoryIdForLoop = () => {
		setCurrentIdWrapper(prev => (prev + 1) % stories.length)
	}

	const updateNextStoryId = () => {
		setCurrentIdWrapper(prev => {
			if (prev < stories.length - 1) return prev + 1
			return prev
		})
	}

	const debouncePause = e => {
		e.preventDefault()
		mousedownId.current = setTimeout(() => {
			toggleState('pause')
		}, 200)
	}

	const mouseUp = type => e => {
		e.preventDefault()
		mousedownId.current && clearTimeout(mousedownId.current)
		if (pause) {
			toggleState('play')
		} else {
			type === 'next' ? next() : previous()
		}
	}

	const toggleMute = e => {
		e.preventDefault()
		if (mute) {
			setMute(false)
		} else {
			setMute(true)
		}
	}

	const getVideoDuration = duration => {
		setVideoDuration(duration * 1000)
	}

	return (
		<Styled.ContainerShadow>
			<Styled.ContainerWrapper style={{ ...storyContainerStyles, ...{ width, height } }}>
				<ProgressContext.Provider
					value={{
						bufferAction: bufferAction,
						videoDuration: videoDuration,
						currentId,
						pause,
						next,
					}}
				>
					<ProgressArray />
				</ProgressContext.Provider>
				<Story
					action={toggleState}
					bufferAction={bufferAction}
					playState={pause}
					mutedState={mute}
					story={stories[currentId]}
					getVideoDuration={getVideoDuration}
				/>
				{!preventDefault && (
					<Styled.ContainerTapOverlay>
						<Styled.ContainerTapArea onTouchStart={debouncePause} onTouchEnd={mouseUp('previous')} onMouseDown={debouncePause} onMouseUp={mouseUp('previous')} />
						<Styled.ContainerTapArea onTouchStart={debouncePause} onTouchEnd={mouseUp('next')} onMouseDown={debouncePause} onMouseUp={mouseUp('next')} />
						{stories[currentId].type === 'video' && (
							<>{pause ? <Styled.PlayPauseWrapperPause onClick={() => setPause(false)} /> : <Styled.PlayPauseWrapperPlay onClick={() => setPause(true)} />}</>
						)}
						{stories[currentId].type === 'video' && <Styled.VolumeWrapper onClick={toggleMute}>{mute ? IconMuted() : IconUnmuted()}</Styled.VolumeWrapper>}
					</Styled.ContainerTapOverlay>
				)}
			</Styled.ContainerWrapper>
		</Styled.ContainerShadow>
	)
}
