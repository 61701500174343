import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

// * Utils
import ScrollToTop from 'utils/scrollToTop'

// * Styles
import 'styles/normalize.css'
import 'styles/global.css'
import 'styles/playground.css'

// * Components
import App from 'App'

ReactDOM.render(
	<BrowserRouter>
		<ScrollToTop />
		<App />
	</BrowserRouter>,

	document.getElementById('root')
)
