import React from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

// * Constants
import * as ROUTES from 'constants/routes'

// * Utils
import { checkToken } from 'utils/setAuthToken'
import { useAppSelector } from 'store/combinedReducer'
import { useAppDispatch } from 'store/configureStore'
import { getAccounts } from 'store/plaidSlice'
import { getCard } from 'store/stripeSlice'

export function RedirectToLogin() {
	return checkToken() ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />
}

export function RedirectToDashboard() {
	return checkToken() ? <Navigate to={ROUTES.DASHBOARD} /> : <Outlet />
}

export function RedirectToDashboardFromWizard() {
	const dispatch = useAppDispatch()
	const plaid = useAppSelector(state => state.plaid)
	const stripe = useAppSelector(state => state.stripe)
	const navigate = useNavigate()

	const userHasExistingPlaidAccounts = plaid.accounts.length
	const userHasExistingStripeCard = stripe.card.id

	React.useEffect(() => {
		dispatch(getAccounts())
		dispatch(getCard())
	}, [])

	React.useEffect(() => (userHasExistingPlaidAccounts || userHasExistingStripeCard) && navigate(ROUTES.DASHBOARD), [plaid.accounts, stripe.card])

	return <Outlet />
}
