import React from 'react'
import { STORAGE_KEYS } from 'constants/storageKeys'
import PickGroupFunds from './PickGroupFunds'
import PickIndividualFunds from './PickIndividualFunds'
import { Selection } from './styled'
import { TabToggle } from 'components/global'
import { useSelectedGroupedFunds, useSelectedIndividualFunds } from './hooks'

const OPTIONS = {
	GROUPED: 'Grouped',
	INDIVIDUAL: 'Individual',
}

const FundPicker = ({ onFundSelection }) => {
	const [selectedFund, setSelectedFund] = React.useState('')
	const [selectedOption, setSelectedOption] = React.useState(OPTIONS.GROUPED)

	const customHookForGrouped = useSelectedGroupedFunds(selectedFund)
	const customHookForIndividual = useSelectedIndividualFunds(selectedFund)
	React.useEffect(() => {
		if (selectedOption === OPTIONS.INDIVIDUAL) console.log('customHookForIndividual', customHookForIndividual)
		if (selectedOption === OPTIONS.GROUPED) console.log('customHookForGrouped', customHookForGrouped)
		onFundSelection(selectedFund)
		console.log('selectedFund', selectedFund)
		sessionStorage.setItem(STORAGE_KEYS.SELECTED_FUND, selectedFund)
	}, [selectedFund])

	return (
		<Selection>
			<TabToggle options={[OPTIONS.GROUPED, OPTIONS.INDIVIDUAL]} isMaxWidth onChange={option => setSelectedOption(option)} />

			{selectedOption === OPTIONS.INDIVIDUAL && <PickIndividualFunds individualFunds={selectedFund} setIndividualFunds={setSelectedFund} />}
			{selectedOption === OPTIONS.GROUPED && <PickGroupFunds fundGroup={selectedFund} setFundGroup={setSelectedFund} />}
		</Selection>
	)
}

export default FundPicker
