export const STORAGE_KEYS = {
	JWT_TOKEN: 'jwtToken',
	SUPPORT_WEBP: 'webp',
	SELECTED_FUND: 'selectedFund',
	SELECTED_FEATURE: 'selectedFeature',
	SHOW_MAINTENANCE_MESSAGE: 'showMaintenanceMessage',
	SHOW_EVENT_COUPON: 'showEventCoupon',
	ACCOUNT_VERIFIED: 'accountVerified',
	WIZARD_STEP: 'step',
}
