import React, { useState } from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Redux
import { useAppSelector } from 'store/combinedReducer'

// * Types
import * as T from './index.types'

// * Styles
import * as S from './styled'

export default function Navbar({ children }) {
	return <S.Container data-testid="navbar-container">{children}</S.Container>
}

Navbar.Leading = function NavbarLeading(props: T.NavbarLeadingProps) {
	return (
		<S.Leading data-testid="navbar-leading" logo={props.logo} icon={props.icon}>
			<ReachRouterLink data-testid="navbar-logo-link" to={props.to} />
		</S.Leading>
	)
}

Navbar.Center = function NavbarCenter(props: T.NavbarCenterProps) {
	return <S.Center data-testid="navbar-center">{props.children}</S.Center>
}

Navbar.Profile = function NavbarProfile(props: T.NavbarProfileProps) {
	const auth = useAppSelector(state => state.auth)
	const [open, setOpen] = useState(false)
	return (
		<>
			<S.Profile
				data-testid="navbar-profile"
				onMouseOver={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				icon={auth.user.profileImage || '/images/icons/z-icon.svg'}
			>
				<ReachRouterLink data-testid="navbar-profile-link" to={props.to} />
				{open && props.children}
			</S.Profile>
		</>
	)
}

Navbar.VersionApp = function NavbarVersionApp({ children }) {
	return <S.VersionApp>{children}</S.VersionApp>
}

Navbar.Dropdown = function NavbarDropdown(props: T.NavbarDropdownProps) {
	return (
		<S.Dropdown data-testid="navbar-dropdown">
			<div id="dropdown-background">{props.children}</div>
		</S.Dropdown>
	)
}

Navbar.DropdownLink = function NavbarDropdownLink(props: T.NavbarDropdownLinkProps) {
	return (
		<S.DropdownLink data-testid="navbar-dropdown-link" {...props}>
			{props.to && <ReachRouterLink data-testid="navbar-dropdown-router-link" to={props.to} />}
			{props.children}
		</S.DropdownLink>
	)
}

Navbar.DropdownLine = function NavbarDropdownLine() {
	return <S.DropdownLine data-testid="navbar-dropdown-line"></S.DropdownLine>
}

Navbar.Link = function NavbarLink(props: T.NavbarLinkProps) {
	return (
		<S.Link data-testid="navbar-link" href={props.href} {...props}>
			{props.children}
		</S.Link>
	)
}

Navbar.Button = function NavbarButton(props: T.NavbarButtonProps) {
	return (
		<>
			{props.to && (
				<ReachRouterLink data-testid="navbar-button-link" to={props.to}>
					<S.Button data-testid="navbar-button" {...props}>
						{props.children}
					</S.Button>
				</ReachRouterLink>
			)}
			{props.href && (
				<S.ButtonHrefWrapper data-testid="navbar-button-href-wrapper" href={props.href}>
					<S.Button data-testid="navbar-button" {...props}>
						{props.children}
					</S.Button>
				</S.ButtonHrefWrapper>
			)}
		</>
	)
}

Navbar.Trailing = function NavbarTrailing(props: T.NavbarTrailingProps) {
	return <S.Trailing data-testid="navbar-trailing">{props.children}</S.Trailing>
}
