import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	/* gap: 40px; */
	gap: 10px;
	align-items: flex-start;
`

export const LinkWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	justify-content: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
`
export const FundLink = styled.a`
	background-color: var(--COLOR_WHITE);
	padding: 3px 7px;
	display: flex;
	flex-direction: row;
	font-weight: 600;
	color: var(--COLOR_SECONDARY);
	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_SP);
	}
	:hover {
		text-decoration: underline;
		opacity: 0.8;
		transition: opacity 300ms;
	}

	& img {
		margin-right: 5px;
		width: 16px;
	}
`

export const Item = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 70px;
	padding: 10px 20px;
	border-radius: 12px;
	background-color: var(--COLOR_WHITE);
	box-shadow: var(--SHADOW_SOFT);
	cursor: pointer;

	&.active {
		outline: 2px solid var(--COLOR_SECONDARY);
		background-color: var(--COLOR_SECONDARY_LIGHT);
	}

	&.active #item-radio {
		border: 6px solid var(--COLOR_SECONDARY);
	}

	:hover {
		opacity: 0.8;
		transition: opacity 300ms;
	}

	@media (max-width: ${BREAK._768}) {
		flex-direction: column;
		min-height: 96px;
		padding: 10px 10px 15px 10px;

		& #item-radio {
			position: absolute;
			left: 10px;
			top: 10px;
		}
	}
`

export const ItemLogo = styled.div`
	display: flex;
	flex-direction: row;
	& :nth-child(1) {
		margin-left: 0px;
	}
`

export const ItemFundLogo = styled.div`
	margin-left: -20px;
	height: 50px;
	width: 50px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 3px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;

	@media (max-width: ${BREAK._768}) {
		margin-left: -15px;
		height: 40px;
		width: 40px;
		border: solid 2px white;
	}
`

export const ItemTitleGroup = styled.div`
	display: flex;
	flex-direction: row;
	gap: 15px;
	align-items: center;
`

export const ItemTitle = styled.p`
	max-width: 140px;
	font-size: var(--TYPE_MP);
	font-weight: 600;
	text-align: left;
	@media (max-width: ${BREAK._768}) {
		max-width: 100%;
		text-align: center;
		margin-bottom: 5px;
		font-size: var(--TYPE_SL);
	}
`

export const ItemRadio = styled.div`
	width: 22px;
	height: 22px;
	border: 2px solid var(--COLOR_GREY);
	background-color: var(--COLOR_WHITE);
	border-radius: 100%;
	display: flex;
`

export const SquareWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
`

export const CategoryGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

export const CategoryTitle = styled.div`
	font-family: var(--FONT_TEXT);
	display: flex;
	font-size: var(--TYPE_LP);
	margin-bottom: 20px;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`

export const Square = styled.div`
	position: relative;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: flex-start;
	min-height: 130px;
	width: 130px;
	padding: 15px 10px;
	border-radius: 12px;
	background-color: var(--COLOR_SECONDARY_NEUTRAL);
	/* opacity: 0.6; */
	transition: opacity ease 300ms, box-shadow ease 300ms;

	cursor: pointer;

	&.active {
		outline: 2px solid ${({ color }) => color};
		background-color: var(--COLOR_GREY_LIGHT);
		box-shadow: var(--SHADOW_SOFT);
		/* opacity: 1; */
	}

	:hover {
		/* opacity: 1; */
		box-shadow: var(--SHADOW_SOFT);
		transition: opacity ease 300ms, box-shadow ease 300ms;
	}
`

export const SquareLogoOutline = styled.div`
	position: relative;
	border: solid 1px ${({ color }) => color};
	height: 30px;
	width: 30px;
	border-radius: 50%;
	margin-bottom: 6px;
`

export const SquareLogo = styled.div`
	position: absolute;
	top: 2px;
	bottom: 2px;
	left: 2px;
	right: 2px;
	border-radius: 100%;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
`

export const SquareTitle = styled.div`
	position: relative;
	font-size: var(--TYPE_SP);
	line-height: 1.25em;
	white-space: pre-line;
	font-weight: 500;

	& img {
		width: 15px;
		height: 15px;
		display: inline;
		margin-bottom: -3px;
	}

	& a {
		:hover {
			text-decoration: underline;
			cursor: alias;
		}
	}
`

export const SquareTag = styled.div`
	position: relative;
	font-size: var(--TYPE_XSP);
	background-color: ${({ color }) => color};
	border-radius: 20px;
	padding: 10px 12px;
	color: var(--COLOR_WHITE);
	line-height: 0px;
	margin-bottom: 5px;
`

export const SquareSubtitle = styled.div`
	position: relative;
	line-height: 1.25em;
	font-size: var(--TYPE_XSP);
	color: var(--COLOR_GREY_DARK);
	text-align: center;
`

export const SquareContent = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
`
