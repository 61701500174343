import React from 'react'
import styled from 'styled-components'
import { isSafari } from 'react-device-detect'

// * Constants
import * as ROUTES from 'constants/routes'

// * Components
import { Hero } from '.'
import { NavbarComp } from 'components/global'
import Stories from '../igStory'

const Section = styled.header`
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	background: linear-gradient(#f4f3f0, #ffffff);
`

const LOCAL = {
	defaultAssetPath: '/images/assets',
	imgHeroName: 'zark-hero',
	zarkmediaCoaf: 'https://zark-uploads.s3.us-west-2.amazonaws.com/zark-coaf.mp4',
	zarkmediaTumo: 'https://zark-uploads.s3.us-west-2.amazonaws.com/zarkmedia-tumo.webm',
}

export default function HeaderSection() {
	const [story, setStory] = React.useState(0)

	let storyText
	let storyColor

	switch (story) {
		case 0:
			storyText = 'education'
			storyColor = '#AFAFE3'
			break
		case 1:
			storyText = 'education'
			storyColor = '#AFAFE3'
			break
		default:
			storyText = 'education'
			break
	}

	return (
		<Section id="header">
			<NavbarComp hasAnchor trailing="dropdown" />

			<Hero>
				{!isSafari && (
					<Stories
						stories={[
							{
								url: LOCAL.zarkmediaCoaf,
								type: 'video',
							},
							{
								url: LOCAL.zarkmediaTumo,
								type: 'video',
							},
						]}
						loop
						keyboardNavigation
						onStoryEnd={(s, st) => {
							// console.log('story ended', s, st)
						}}
						onAllStoriesEnd={(s, st) => {
							// console.log('all stories ended', s, st)
						}}
						onStoryStart={(s, st) => {
							setStory(s)
							// console.log('story started', s, st)
						}}
						defaultInterval={1500}
						width={280}
						height={536}
					/>
				)}

				<Hero.Content isSafari={isSafari}>
					{!isSafari && <Hero.Pill color={storyColor}>{storyText}</Hero.Pill>}

					<Hero.Title>Round up your change today</Hero.Title>
					<Hero.Text>Contribute a fraction of your daily credit and debit card purchases to support essential educational organizations Armenia.</Hero.Text>
					<Hero.Button to={ROUTES.REGISTER}>Activate my Round-up</Hero.Button>
				</Hero.Content>
			</Hero>
		</Section>
	)
}
