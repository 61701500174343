import { useMemo } from 'react'
import { ConditionTypes, DataFromMock, DataFromRedux, DataPoint } from './types'

// Reusable functions
function formatDataFromRedux(data: DataFromRedux[]): DataPoint[] {
	const result = data.map((item: DataFromRedux) => ({
		number: item.chartAmount,
		date: new Date(item.date),
	}))
	// console.log('formatDataFromRedux: ', result)
	return result
}

function sortData(data: DataPoint[]) {
	const result = data.sort((a, b) => a.date.getTime() - b.date.getTime())
	// console.log('sortData: ', result)
	return result
}

function groupData(data: DataPoint[]) {
	const result = Array.from(
		data
			.reduce((acc, item) => {
				const key = item.date.toDateString()
				if (!acc.has(key)) {
					acc.set(key, { date: item.date, number: 0 })
				}
				acc.get(key).number += item.number
				return acc
			}, new Map<string, DataPoint>())
			.values()
	).map(item => {
		item.number = Math.round(item.number * 100) / 100
		return item
	})
	// console.log('groupData: ', result)
	return result
}

function combineObjects(arr: DataPoint[]) {
	function formatDate(date) {
		const options = { month: 'short', year: 'numeric' }
		// @ts-ignore
		return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
	}

	const map = new Map()

	// Loop through each object in the array
	arr.forEach(obj => {
		const date = formatDate(obj.date)
		const number = obj.number

		// Check if there's already an object in the map with the same date
		if (map.has(date)) {
			// If there is, add the current number to the existing object's number
			const existingObj = map.get(date)
			existingObj.number += number
			map.set(date, existingObj)
		} else {
			// If there isn't, create a new object and add it to the map
			const newObj = { number: number, date: new Date(date) }
			map.set(date, newObj)
		}
	})

	// Convert the map to an array and sort it by date
	// @ts-ignore
	const sortedArr = Array.from(map.values()).sort((a, b) => new Date(a.date) - new Date(b.date))

	// Add missing months and years with a number of 0
	const minDate = new Date(arr[0].date)
	const maxDate = new Date(arr[arr.length - 1].date)
	let currentDate = new Date(minDate)
	while (currentDate <= maxDate) {
		const formattedDate = formatDate(currentDate)
		if (!map.has(formattedDate)) {
			const newObj = { number: 0, date: new Date(formattedDate) }
			sortedArr.push(newObj)
		}
		currentDate.setMonth(currentDate.getMonth() + 1)
	}

	// @ts-ignore
	const sortedFinal = sortedArr.sort((a, b) => new Date(a.date) - new Date(b.date))

	const result = sortedFinal
	// console.log('combineObjects: ', result)
	return result
}

// Pipe & Calculate functions
function calculateLastMonthDiffToLastThreeMonthAverage(data: DataFromRedux[]): number {
	function calculateAverage(data) {
		const lastThreeMonths = data.slice(-4, -1)
		const totalLastThreeMonths = lastThreeMonths.reduce((total, { number }) => total + number, 0)
		const avgLastThreeMonths = totalLastThreeMonths / 3
		const lastMonthNumber = data[data.length - 1].number
		const diff = +(lastMonthNumber - avgLastThreeMonths).toFixed(2)

		const result = diff
		// console.log('calculateAverage: ', result)
		return result
	}

	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const combined = combineObjects(group)
	const average = calculateAverage(combined)

	const result = average
	console.log('calculateLastMonthDiffToLastThreeMonthAverage: ', result)
	return result
}

function calculateLastMonthDiffToTotalAverage(data: DataFromRedux[]): number {
	function calculateAverage(input) {
		let total = 0
		for (let i = 0; i < input.length - 1; i++) {
			total += input[i].number
		}
		const avg = total / (input.length - 1)
		const lastMonthDiff = input[input.length - 1].number - avg
		const rounded = Math.round(lastMonthDiff * 100) / 100
		const result = rounded
		// console.log('calculateAverage: ', result)
		return result
	}

	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const combined = combineObjects(group)
	const average = calculateAverage(combined)

	const result = average
	console.log('calculateLastMonthDiffToTotalAverage: ', result)
	return result
}

function pipeDiffDataFromRedux(data: DataFromRedux[]): { number: number; date: Date }[] {
	function getDifferenceArray(input: DataPoint[]): { number: number; date: Date }[] {
		const output = []

		for (let i = 0; i < input.length; i++) {
			const currentNumber = input[i].number
			const previousNumber = i > 0 ? input[i - 1].number : 0
			const diff = (currentNumber - previousNumber).toFixed(2)

			output.push({ number: Number(diff), date: new Date(input[i].date) })
		}

		const result = output
		// console.log('getDifferenceArray: ', result)
		return result
	}

	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const combined = combineObjects(group)
	const diff = getDifferenceArray(combined)

	const result = diff
	console.log('pipeDiffDataFromRedux: ', result)
	return result
}

function pipeSumDataFromRedux(data: DataFromRedux[], resample: number = 1) {
	function sumNumbers(data: DataPoint[]) {
		let sum = 0
		const result = data.map(item => {
			sum += item.number
			const roundedSum = Math.round(sum * 100) / 100
			return { number: roundedSum, date: item.date }
		})
		// console.log('sumNumbers: ', result)
		return result
	}

	function resampleData(data: DataPoint[], factor: number) {
		let newData = []
		for (let i = 0; i < data.length; i += factor) {
			let sum = 0
			let count = 0
			for (let j = i; j < i + factor; j++) {
				if (j >= data.length) {
					break
				}
				sum += data[j].number
				count++
			}
			newData.push({ number: sum / count, date: data[i].date })
		}
		const result = newData
		// console.log('resampleData: ', result)
		return result
	}

	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const sum = sumNumbers(group)
	const resampled = resampleData(sum, resample)

	const result = resampled
	console.log('pipeSumDataFromRedux: ', result)
	return result
}

function pipeNormalDataFromRedux(data: DataFromRedux[]): { number: number; date: Date }[] {
	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const combined = combineObjects(group)

	const result = combined
	console.log('pipeNormalDataFromRedux: ', result)
	return result
}

// Memoized hooks
export function useCalculateLastMonthDiffToLastThreeMonthAverage(data: DataFromRedux[]): number {
	const result = useMemo(() => calculateLastMonthDiffToLastThreeMonthAverage(data), [data])
	return result
}

export function useCalculateLastMonthDiffToTotalAverage(data: DataFromRedux[]): number {
	const result = useMemo(() => calculateLastMonthDiffToTotalAverage(data), [data])
	return result
}

export function getMostRecentRoundup(data: DataFromRedux[]): DataPoint {
	const format = formatDataFromRedux(data)
	const sort = sortData(format)
	const group = groupData(sort)
	const combined = combineObjects(group)
	const array = combined
	const getLastItem = array[array.length - 1]
	const rounded = Math.round(getLastItem.number * 100) / 100
	const result = { number: rounded, date: getLastItem.date }
	console.log('getMostRecentRoundup: ', result)
	return result
}

export function useGetMostRecentRoundup(data: DataFromRedux[]) {
	return useMemo(() => getMostRecentRoundup(data), [data])
}

function conditionalPipe(data: DataFromRedux[], condition: ConditionTypes, resample: number = 1) {
	switch (condition) {
		case 'diff':
			return pipeDiffDataFromRedux(data)
		case 'sum':
			return pipeSumDataFromRedux(data, resample)
		case 'normal':
			return pipeNormalDataFromRedux(data)
		default:
			return pipeNormalDataFromRedux(data)
	}
}

export function useConditionalPipe(data: DataFromRedux[], condition: ConditionTypes, resample: number = 1) {
	return useMemo(() => conditionalPipe(data, condition, resample), [data, condition, resample])
}
