import React from 'react'
import ReactDOM from 'react-dom'

// * Styles
import * as S from './styled'

// * Components
import { IconPlay } from 'assets'

export const PlayerContext = React.createContext()

export default function Player({ children }) {
	const [showPlayer, setShowPlayer] = React.useState(false)

	return (
		<PlayerContext.Provider value={{ showPlayer, setShowPlayer }}>
			<S.Container data-testid="player-container">{children}</S.Container>
		</PlayerContext.Provider>
	)
}

Player.Icon = function PlayerIcon() {
	const { setShowPlayer } = React.useContext(PlayerContext)

	return (
		<S.Icon data-testid="player-icon" onClick={() => setShowPlayer(showPlayer => !showPlayer)}>
			<IconPlay />
		</S.Icon>
	)
}

Player.Video = function PlayerVideo({ src }) {
	const { showPlayer, setShowPlayer } = React.useContext(PlayerContext)

	return showPlayer
		? ReactDOM.createPortal(
				<>
					<S.Overlay data-testid="player-overlay-wrap" onClick={() => setShowPlayer(false)}>
						<S.Inner data-testid="player-inner">
							<S.InnerContent data-testid="player-inner-content">
								<S.InnerScaler data-testid="player-inner-scaler">
									<iframe title="Play Video" frameBorder="0" autoPlay="1" allowFullScreen="" src={src} enablejsapi="true"></iframe>
									<S.Close data-testid="player-inner-close" onClick={() => setShowPlayer(false)} src="/images/icons/close-white.svg">
										×
									</S.Close>
								</S.InnerScaler>
							</S.InnerContent>
						</S.Inner>
					</S.Overlay>
				</>,
				document.body
		  )
		: null
}
