import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Maintenance = styled.div`
	background-color: var(--COLOR_YELLOW_BG);
	border: 2px var(--COLOR_YELLOW) solid;
	padding: 10px 20px;
	color: var(--COLOR_YELLOW_TEXT);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: row;
	gap: 10px;

	img {
		width: 30px;
	}

	:hover {
		cursor: pointer;
	}

	& > p {
		font-size: var(--TYPE_SP);
		line-height: 1.6em;
		max-width: 90ch;
		text-align: center;
	}
`

export const Section = styled.section`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	min-height: 100vh;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`

export const Contain = styled.div`
	width: 100%;
	max-width: 940px;
	padding: 0 20px;
	display: grid;
	gap: 50px;
	@media (max-width: ${BREAK._1100}) {
		display: flex;
		flex-direction: column;
		gap: 60px;
	}
`

export const Head = styled.div``

export const Body = styled.div`
	display: flex;
	/* grid-template-columns: 0.72fr 1fr; */
	gap: 40px;
	@media (max-width: ${BREAK._1100}) {
		display: flex;
		flex-direction: column;
		gap: 60px;
	}
`
export const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 60px;
`
