import React from 'react'
import { IconArmLetter } from 'assets'
import { Description, Email, Title } from './styled'
import { useAppSelector } from 'store/combinedReducer'

export const GreetContent = () => {
	const auth = useAppSelector(state => state.auth)
	return (
		<>
			<Title>
				<IconArmLetter />
				<h1>{auth.user.name?.split(' ')[0]}</h1>
			</Title>
			<Email>{auth.user.email}</Email>
			<Description>Transactions from your linked accounts will be evenly distributed between fundraisers you pick below.</Description>
		</>
	)
}
