import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import LogRocket from 'logrocket'
import reducer from './combinedReducer'

const store = configureStore({
	reducer,
	middleware: [...getDefaultMiddleware(), LogRocket.reduxMiddleware()],
})

export type AppDispatch = typeof store.dispatch | any
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
