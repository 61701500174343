import React from 'react'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Components
import { Members } from '.'

const Section = styled.section`
	padding: 80px 20px 40px 20px;
	@media (max-width: ${BREAK._768}) {
		padding: 40px 20px 20px 20px;
	}
`

const LOCAL = {
	memberRow1: [
		{
			name: 'Will Melikyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Grigor Sargsyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Galstyan Harutyun',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Narek Fidanyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Mikayel Khacatryan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Zaven Keuroghlian',
			descr: 'Members since 2020',
			slug: 'profile',
		},
	],
	memberRow2: [
		{
			name: 'Will Melikyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Grigor Sargsyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Galstyan Harutyun',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Narek Fidanyan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Mikayel Khacatryan',
			descr: 'Members since 2020',
			slug: 'profile',
		},
		{
			name: 'Zaven Keuroghlian',
			descr: 'Members since 2020',
			slug: 'profile',
		},
	],
}

export default function MembersSection() {
	const [scrollY, setScrollY] = React.useState(0)

	React.useEffect(() => {
		const handleScroll = () => {
			// const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
			// const scrollValue = Math.round(window.scrollY)
			setScrollY(window.scrollY / 20)
		}
		handleScroll()

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<Section id="members">
			<Members>
				<Members.Group scrollDistance={scrollY} row1Data={LOCAL.memberRow1} row2Data={LOCAL.memberRow2} />
			</Members>
		</Section>
	)
}
