import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const Title = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: flex-end;
	& svg {
		height: 50px;
	}

	& > h1 {
		line-height: 56px;
	}

	@media (max-width: ${BREAK._1100}) {
		gap: 6px;

		& > h1 {
			font-size: var(--TYPE_H3);
			line-height: 33px;
		}

		& svg {
			height: 30px;
		}
	}
`

export const Email = styled.div`
	font-family: var(--FONT_TEXT);
	font-size: var(--TYPE_MP);
	color: var(--COLOR_GREY_DARK);
	margin-bottom: 10px;
	@media (max-width: ${BREAK._1100}) {
		font-size: var(--TYPE_SP);
		margin-bottom: 4px;
		margin-top: -6px;
	}
`

export const Description = styled.p`
	max-width: 50ch;
	@media (max-width: ${BREAK._1100}) {
		padding: 0;
		font-size: var(--TYPE_SP);
		max-width: 50ch;
	}
`
