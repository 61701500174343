import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as G from 'components/onboarding/globalStyled'
import * as S from './styled'

export default function Roundup({ children, ...props }) {
	return <G.Container {...props}>{children}</G.Container>
}

Roundup.Navbar = function RoundupNavbar({ to, logo = '/images/brand/zark-logo.svg' }) {
	return (
		<G.Navbar data-testid="roundup-navbar" logo={logo}>
			<ReachRouterLink to={to} />
		</G.Navbar>
	)
}

//** Header Children */
Roundup.Header = function RoundupHeader({ children, ...props }) {
	return (
		<G.Header data-testid="roundup-header" {...props}>
			{children}
		</G.Header>
	)
}

Roundup.Title = function RoundupTitle({ children, ...props }) {
	return (
		<G.Title data-testid="roundup-title" {...props}>
			{children}
		</G.Title>
	)
}

Roundup.Description = function RoundupDescription({ children, ...props }) {
	return (
		<G.Description data-testid="roundup-description" {...props}>
			{children}
		</G.Description>
	)
}

//** Body Children */
Roundup.Body = function RoundupBody({ children, ...props }) {
	return (
		<G.Body data-testid="roundup-body" {...props}>
			{children}
		</G.Body>
	)
}

Roundup.LinkWrapper = function RoundupLinkWrapper({ children, ...props }) {
	return (
		<S.FundLinkWrapper data-testid="roundup-link-wrapper" {...props}>
			{children}
		</S.FundLinkWrapper>
	)
}

Roundup.Link = function RoundupLink({ websiteLink, title, icon, abbr, children, ...props }) {
	return (
		<S.FundLink data-testid="roundup-link" rel="noopener noreferrer" target="_blank" href={websiteLink} title={title} {...props}>
			<img data-testid="roundup-link-image" src={`/images/assets/${icon}`} alt={title} />
			{abbr}{' '}
		</S.FundLink>
	)
}

Roundup.Image = function RoundupImage({ webp, png, ...props }) {
	return (
		<S.Image data-testid="roundup-image" {...props}>
			<div className="plate">
				<picture>
					<source srcSet={webp} type="image/webp" />
					<source srcSet={png} type="image/png" />
					<img src={''} alt="feature" />
				</picture>
			</div>
		</S.Image>
	)
}

Roundup.SecureWrapper = function RoundupSecureWrapper({ children, ...props }) {
	return (
		<S.SecureWrapper data-testid="roundup-secure-wrapper" {...props}>
			{children}
		</S.SecureWrapper>
	)
}

Roundup.SecureText = function RoundupSecureText({ firstLine, children, ...props }) {
	return (
		<S.SecureText data-testid="roundup-secure-text" {...props}>
			{firstLine && <img id="lock-icon" src="/images/icons/icon-lock.svg" alt="lock icon" />}
			{children}
		</S.SecureText>
	)
}

Roundup.SecureImage = function RoundupSecureText({ ...props }) {
	return <S.SecureImage data-testid="roundup-secure-image" {...props} />
}

Roundup.ButtonWrapper = function RoundupButtonWrapper({ children, ...props }) {
	return (
		<S.ButtonWrapper data-testid="roundup-button-wrapper" {...props}>
			{children}
		</S.ButtonWrapper>
	)
}

Roundup.Button = function RoundupButton({ children, ...props }) {
	return (
		<G.Button data-testid="roundup-button" {...props}>
			{children}
		</G.Button>
	)
}

//** Footer Children */
Roundup.Footer = function RoundupFooter({ children, ...props }) {
	return (
		<G.Footer data-testid="roundup-footer" {...props}>
			{children}
		</G.Footer>
	)
}

Roundup.TextWithLink = function RoundupTextWithLink({ children, ...props }) {
	return (
		<G.TextWithLink data-testid="roundup-text-with-link" {...props}>
			{children}
		</G.TextWithLink>
	)
}
