import React from 'react'

// * Styles
import * as G from 'components/onboarding/globalStyled'
import * as S from './styled'

export default function Features({ children, ...props }) {
	return <G.Container {...props}>{children}</G.Container>
}

//** Header Children */
Features.Header = function FeaturesHeader({ children, ...props }) {
	return (
		<G.Header data-testid="features-header" {...props}>
			{children}
		</G.Header>
	)
}

Features.Tag = function FeaturesTag({ children, ...props }) {
	return (
		<G.Tag data-testid="features-tag" {...props}>
			<span>{children}</span>
		</G.Tag>
	)
}

Features.Title = function FeaturesTitle({ children, ...props }) {
	return (
		<G.Title data-testid="features-title" {...props}>
			{children}
		</G.Title>
	)
}

Features.Description = function FeaturesDescription({ children, ...props }) {
	return (
		<G.Description data-testid="features-description" {...props}>
			{children}
		</G.Description>
	)
}

//** Body Children */
Features.Body = function FeaturesBody({ children, ...props }) {
	return (
		<G.Body data-testid="features-body" {...props}>
			{children}
		</G.Body>
	)
}

Features.ButtonWrapper = function FeaturesButtonWrapper({ children, ...props }) {
	return <G.ButtonWrapper {...props}>{children}</G.ButtonWrapper>
}

Features.Button = function FeaturesButton({ children, ...props }) {
	return (
		<G.Button data-testid="features-button" {...props}>
			{children}
		</G.Button>
	)
}

//** Item Selection */
Features.Selection = function FeaturesSelection({ children, ...props }) {
	return (
		<S.Selection data-testid="features-selection" {...props}>
			{children}
		</S.Selection>
	)
}

Features.Item = function FeaturesItem({ active, children, ...props }) {
	return (
		<S.Item className={active ? 'active' : 'inactive'} {...props}>
			{children}
		</S.Item>
	)
}

Features.ItemTitle = function FeaturesItemTitle({ children, ...props }) {
	return <S.ItemTitle {...props}>{children}</S.ItemTitle>
}

Features.ItemSubtitle = function FeaturesItemSubtitle({ children, ...props }) {
	return <S.ItemSubtitle {...props}>{children}</S.ItemSubtitle>
}

Features.ItemRadio = function FeaturesItemRadio({ ...props }) {
	return <S.ItemRadio id="item-radio" {...props} />
}
