import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import { Section, Navbar } from 'components/onboarding/globalStyled'

// * Components
export { default as Funds } from './Funds'
export { default as Features } from './Features'
export { default as Roundup } from './Roundup'
export { default as Monthly } from './Monthly'

export { default as LoginFlow } from './Form/flow/login.flow'
export { default as RegisterFlow } from './Form/flow/register.flow'
export { default as ResetPasswordFlow } from './Form/flow/resetpassword.flow'

export { default as MonthlyFlow } from './Details/flow/monthly.flow'
export { default as RoundupFlow } from './Details/flow/roundup.flow'
export { default as FeaturesFlow } from './Features/flow/features.flow'
export { default as FundsFlow } from './Funds/flow/funds.flow'

export default function Onboarding({ children, ...props }) {
	return (
		<Section data-testid="onboarding-section" {...props}>
			{children}
		</Section>
	)
}

Onboarding.Navbar = function onboardingNavbar({ to, logo = '/images/brand/zark-logo.svg' }) {
	return (
		<Navbar data-testid="onboarding-navbar" logo={logo}>
			<ReachRouterLink to={to}></ReachRouterLink>
		</Navbar>
	)
}
