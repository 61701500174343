import styled from 'styled-components'

export const Container = styled.article`
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	padding: 0 20px;
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-width: 60ch;
`

export const Loop = styled.div`
	animation-duration: 3s;
	animation-name: loop-animation;

	@keyframes loop-animation {
		from {
			transform: translateY(100px);
			opacity: 0;
		}

		50% {
			transform: translateY(0px);
			opacity: 1;
		}
	}
`

export const Quote = styled.p`
	font-size: var(--TYPE_LP);
	font-weight: 600;
	text-align: center;

	animation-duration: 3s;
	animation-name: quote-animation;

	@keyframes quote-animation {
		from {
			transform: translateY(10px);
			opacity: 0;
		}

		60% {
			transform: translateY(10px);
			opacity: 0;
		}

		to {
			transform: translateY(0px);
			opacity: 1;
		}
	}
`

export const Author = styled.p`
	color: var(--COLOR_GREY_DARK);
	font-size: var(--TYPE_LP);
	font-weight: 600;
	text-align: center;

	animation-duration: 3s;
	animation-name: author-animation;

	@keyframes author-animation {
		from {
			transform: translateY(10px);
			opacity: 0;
		}

		80% {
			transform: translateY(10px);
			opacity: 0;
		}

		to {
			transform: translateY(0px);
			opacity: 1;
		}
	}
`
