import React from 'react'

// * Styles
import * as S from './styled'

export default function Preview({ children }) {
	return <S.Container data-testid="preview-container">{children}</S.Container>
}

Preview.Header = function PreviewHeader({ children }) {
	return <S.Header data-testid="preview-header">{children}</S.Header>
}

Preview.Headline = function PreviewHeadline({ children }) {
	return <S.Headline data-testid="preview-headline">{children}</S.Headline>
}
Preview.Description = function PreviewDescription({ children, ...props }) {
	return <S.Description data-testid="preview-description">{children}</S.Description>
}
Preview.Main = function PreviewMain({ children }) {
	return <S.Main data-testid="preview-main">{children}</S.Main>
}
Preview.Image = function PreviewImage({ webp, jpg, alt, ...props }) {
	return (
		<S.Image data-testid="preview-image" {...props}>
			<picture>
				<source srcSet={webp} type="image/webp" />
				<source srcSet={jpg} type="image/jpg" />
				<img src={''} alt={alt} />
			</picture>
		</S.Image>
	)
}
