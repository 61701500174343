import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	z-index: 1400;
	width: 100%;
	height: 100%;
	background-color: #f4f3f099;
	padding: 0 20px;

	.backdrop {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		z-index: 1401;
		position: fixed;
	}

	.card {
		overflow: auto;
		max-height: 90vh;
		width: 100%;
		z-index: 4002;
		max-width: 520px;
		min-height: 200px;
		background-color: var(--COLOR_WHITE);
		border-radius: 12px;
		padding: 50px 55px 40px 55px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: center;
		box-shadow: var(--SHADOW_SOFT);

		@media (max-width: ${BREAK._768}) {
			padding: 25px 10px 10px 10px;
		}
	}
`

export const Title = styled.h3`
	margin-bottom: 10px;
	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H4);
		margin-bottom: 5px;
	}
`

export const Text = styled.p`
	color: var(--COLOR_GREY_DARK);
	margin-bottom: 10px;
	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_SP);
	}
`

export const ButtonWrapper = styled.div`
	position: relative;
	display: grid;
	flex-direction: row;
	grid-template-columns: 0.6fr 1fr;
	gap: 10px;
`

export const Button = styled.button`
	position: relative;
	font-size: var(--TYPE_LP);
	font-family: var(--FONT_ZARK);
	width: 100%;
	height: 80px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	:disabled {
		color: var(--COLOR_GREY_DARK);
		background-color: var(--COLOR_GREY);
		cursor: not-allowed;
	}

	& > button {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		opacity: 0;
		z-index: 1;
	}

	${({ outline }) =>
		outline
			? `color: var(--COLOR_PRIMARY);
	        background-color: var(--COLOR_WHITE);
	        border: 2px solid var(--COLOR_PRIMARY);`
			: `color: var(--COLOR_SECONDARY);
	        background-color: var(--COLOR_PRIMARY);
	        `}
`
