import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// * Components
import Navbar from '..'

// * Constants
import * as ROUTES from 'constants/routes'

// * Redux
import { logoutUser } from 'store/authSlice'

// * Utils
import { checkToken } from 'utils/setAuthToken'

// * Types
import * as T from '../index.types'

export default function NavbarComp(props: T.NavbarCompProps) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleLogout = () => {
		dispatch(logoutUser())
		navigate(ROUTES.HOMEPAGE)
	}

	return (
		<>
			<Navbar>
				<Navbar.Leading logo="/images/brand/zark-logo.svg" icon="/images/brand/zark-icon.svg" to={ROUTES.HOMEPAGE} />
				{props.hasAnchor && (
					<Navbar.Center>
						<Navbar.Link href="/#feature">Round Up</Navbar.Link>
						<Navbar.Link href="/#fund">Fundraisers</Navbar.Link>
						<Navbar.Link href="/#team">Team</Navbar.Link>
					</Navbar.Center>
				)}

				<Navbar.Trailing>
					{
						{
							logout: (
								<Navbar.Profile to={ROUTES.DASHBOARD}>
									<Navbar.Dropdown>
										<Navbar.DropdownLink onClick={() => handleLogout()}>Log Out</Navbar.DropdownLink>
									</Navbar.Dropdown>
								</Navbar.Profile>
							),
							dropdown: checkToken() ? (
								<Navbar.Profile to={ROUTES.DASHBOARD}>
									<Navbar.Dropdown>
										<Navbar.DropdownLink to={ROUTES.DASHBOARD}>Go to Dashboard</Navbar.DropdownLink>
										<Navbar.DropdownLine />
										<Navbar.DropdownLink onClick={() => handleLogout()}>Log Out</Navbar.DropdownLink>
									</Navbar.Dropdown>
								</Navbar.Profile>
							) : (
								<>
									<Navbar.Button styleType="primary" to={ROUTES.REGISTER}>
										Activate
									</Navbar.Button>

									<Navbar.Button styleType="secondary" to={ROUTES.LOGIN}>
										Log In
									</Navbar.Button>
								</>
							),
							normal: (
								<>
									<Navbar.Button styleType="primary" to={ROUTES.REGISTER}>
										Activate
									</Navbar.Button>

									<Navbar.Button styleType="secondary" to={ROUTES.LOGIN}>
										Log In
									</Navbar.Button>
								</>
							),
						}[props.trailing]
					}
				</Navbar.Trailing>
			</Navbar>
		</>
	)
}
