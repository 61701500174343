import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.div``

export const Wrapper = styled.div`
	position: relative;
	background-color: var(--COLOR_WHITE);
	box-shadow: var(--SHADOW_SOFT);
	border-radius: 12px;
	padding: 60px;
	max-width: 500px;
	@media (max-width: ${BREAK._1100}) {
		max-width: 100%;
		padding: 30px 20px 30px 20px;
	}
	&::before {
		content: ' ';
		position: absolute;
		border-radius: 0;
		background-color: white;
		box-shadow: 0px -30px 20px #ffffff;
		height: 60px;
		bottom: 0px;
		left: 10px;
		right: 10px;
		z-index: 1;
	}
`

export const Title = styled.h3`
	margin-bottom: 20px;
`

export const Head = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
`

export const HeadTitle = styled.p`
	font-size: var(--TYPE_LP);
	font-weight: 500;
	margin-bottom: 15px;
`

export const HeadTotal = styled.h4`
	font-family: var(--FONT_ZARK);
	font-size: var(--TYPE_XLD);
	border-bottom: 7px solid var(--COLOR_SECONDARY);
	margin-bottom: 40px;

	@media (max-width: ${BREAK._1100}) {
		font-size: var(--TYPE_LD);
	}

	.dollar {
		color: var(--COLOR_GREY);
	}
`

export const MonthBadge = styled.div`
	width: 100%;
	text-align: center;
	background-color: var(--COLOR_GREY_LIGHT);
	padding: 15px 0;
	color: var(--COLOR_GREY_DARK);
	font-size: var(--TYPE_MP);
	font-weight: 500;
	border-radius: 40px;
	margin-bottom: 20px;
`

export const Group = styled.div`
	margin-bottom: 20px;
`

export const TransactionsSkeletonWrapper = styled.div`
	.parent-skeleton {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		margin-top: 60px;
		padding-right: 45px;
		width: 420px;

		@media (max-width: ${BREAK._1100}) {
			width: 100%;
			right: 0px;
			padding-right: 0px;
			margin-top: 30px;
		}
	}

	.badge-skeleton {
		width: 100%;
	}

	.list-skeleton {
		width: 100%;
	}
	.list-item {
		display: flex;
		gap: 20px;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;
	}

	.left-side {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.right-side {
	}

	.separator-line {
		width: 100%;
		height: 2px;
	}
`

export const Transactions = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 420px;
	margin-top: 60px;
	max-height: 700px;
	right: -2px;
	padding-right: 45px;
	overflow-x: hidden;
	overflow-y: scroll;

	@media (max-width: ${BREAK._1100}) {
		width: 100%;
		right: 0px;
		padding-right: 0px;
		margin-top: 30px;
	}

	&::-webkit-scrollbar {
		width: 7px;
		background: transparent;

		@media (max-width: ${BREAK._1100}) {
			width: 0px;
		}
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 7px;
		background-color: var(--COLOR_GREY_LIGHT);
	}
`

export const TransactionItem = styled.div`
	position: relative;
	font-size: var(--TYPE_MP);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	border-bottom: 1px solid var(--COLOR_GREY);

	& .line-body {
		width: 250px;
	}

	& .first-line {
		color: var(--COLOR_PRIMARY);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (max-width: ${BREAK._1100}) {
			white-space: pre-wrap;
			font-size: var(--TYPE_SL);
		}

		& .amount {
			font-weight: 600;
		}
	}
	& .second-line {
		color: var(--COLOR_GREY_DARK);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@media (max-width: ${BREAK._1100}) {
			white-space: pre-wrap;
			font-size: var(--TYPE_SP);
		}
	}
	& .roundup-amount {
		background-color: var(--COLOR_SECONDARY);
		color: var(--COLOR_PRIMARY);
		font-weight: 600;
		padding: 8px 18px 8px 16px;
		border-radius: 30px;
		flex-shrink: 0;

		@media (max-width: ${BREAK._1100}) {
			font-size: var(--TYPE_SL);
		}
	}

	& .monthly-amount {
		background-color: var(--COLOR_GREEN_BG_FLAT);
		color: var(--COLOR_GREEN_TEXT);
		font-weight: 600;
		padding: 8px 18px 8px 16px;
		border-radius: 30px;
		flex-shrink: 0;

		@media (max-width: ${BREAK._1100}) {
			font-size: var(--TYPE_SL);
		}
	}
`

export const TextBlur = styled.span`
	color: transparent;
	text-shadow: 0 0 8px rgb(0 0 0 / 50%);
	cursor: pointer;
	user-select: none;
	transition: all 0.5s 2s;

	:active {
		transition: all 0.5s 0.2s;
		color: var(--COLOR_PRIMARY);
		text-shadow: none;
	}
`
