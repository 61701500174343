import { createSlice } from '@reduxjs/toolkit'
import ZARK from '../../utils/api'
import { enqueueSnackbar } from '../snackbarSlice'
import { AxiosResponse } from 'axios'
import { StripeCardResponse, StripeSubscriptionResponse } from './types'
import moment from 'moment'

const slice = createSlice({
	name: 'stripe',
	initialState: {
		card: {},
		cardLoading: null,
		cardRemoving: null,
		subscriptions: {
			amount: undefined,
			renews: undefined,
			isActive: undefined,
		},
		subscriptionsLoading: null,
		transactions: [],
		transactionsLoading: null,
	},
	reducers: {
		cardRequested: (_, action) => {
			_.cardLoading = true
		},
		cardReceived: (_, action) => {
			_.card = action.payload
			_.cardLoading = false
		},
		cardRequestFailed: (_, action) => {
			_.cardLoading = false
		},
		cardAdded: (_, action) => {
			_.card = action.payload
			_.cardLoading = false
		},
		cardRemoving: (_, action) => {
			_.cardRemoving = true
		},
		cardRemoved: (_, action) => {
			_.card = {}
			_.cardRemoving = false
		},
		cardRemovalFailed: (_, action) => {
			_.cardRemoving = false
		},
		subscriptionsRequested: (_, action) => {
			_.subscriptionsLoading = true
		},
		subscriptionsReceived: (_, action) => {
			_.subscriptions.amount = action.payload.amount
			_.subscriptions.renews = moment(action.payload.renews).format('MMM DD')
			_.subscriptions.isActive = action.payload.isActive
			_.subscriptionsLoading = false
		},
		subscriptionsRequestFailed: (_, action) => {
			_.subscriptionsLoading = false
		},
		subscriptionsAdded: (_, action) => {
			_.subscriptions.amount = action.payload.amount
			_.subscriptions.renews = moment(action.payload.renews).format('MMM DD')
			_.subscriptions.isActive = action.payload.isActive
			_.subscriptionsLoading = false
		},
		subscriptionsRemoved: (_, action) => {
			_.subscriptions.amount = undefined
			_.subscriptions.renews = undefined
			_.subscriptions.isActive = undefined
		},
		transactionsRequested: (_, action) => {
			_.transactionsLoading = true
		},
		transactionsReceived: (_, action) => {
			_.transactions = action.payload
			_.transactionsLoading = false
		},
		transactionsRequestFailed: (_, action) => {
			_.transactionsLoading = false
		},
	},
})

const {
	cardRequested,
	cardReceived,
	cardRequestFailed,
	cardRemoving,
	cardRemoved,
	cardRemovalFailed,
	cardAdded,
	subscriptionsRequested,
	subscriptionsReceived,
	subscriptionsRequestFailed,
	subscriptionsRemoved,
	subscriptionsAdded,
	transactionsRequested,
	transactionsReceived,
	transactionsRequestFailed,
} = slice.actions

export default slice.reducer

const root = 'stripe'

const route = {
	v1: `${root}/v1`,
	v2: `${root}/v2`,
}

// Action Creators
export const getCard = () => dispatch => {
	dispatch({ type: cardRequested.type })
	ZARK.get(`${route.v2}/card`)
		.then((res: AxiosResponse<StripeCardResponse>) => dispatch({ type: cardReceived.type, payload: res.data }))
		.catch(err => {
			dispatch({ type: cardRequestFailed.type })
			if (err.response.status !== 400)
				dispatch(enqueueSnackbar(`Something went wrong when getting cards. Please try again later or contact support.`, { variant: 'error' }))
		})
}

export const addCard = id => dispatch => {
	dispatch({ type: cardRequested.type })
	dispatch(enqueueSnackbar('Linking your card...', { variant: 'info' }))
	ZARK.post(`${route.v2}/card`, { cardId: id })
		.then((res: AxiosResponse<StripeCardResponse>) => {
			dispatch({ type: cardAdded.type, payload: res.data })
			dispatch(enqueueSnackbar('New card added, roundups will be charged from this card. You can also enable monthly payments', { variant: 'success' }))
		})
		.catch(() => {
			dispatch({ type: cardRequestFailed.type })
			dispatch(enqueueSnackbar(`Card could not be added. Please try again later or contact support.`, { variant: 'error' }))
		})
}

export const addCardWithSubscription = (id, amount) => dispatch => {
	dispatch({ type: cardRequested.type })
	dispatch(enqueueSnackbar('Linking your card...', { variant: 'info' }))
	ZARK.post(`${route.v2}/card`, { cardId: id })
		.then((res: AxiosResponse<StripeCardResponse>) => {
			dispatch({ type: cardAdded.type, payload: res.data })
			dispatch({ type: subscriptionsRequested.type })
			dispatch(enqueueSnackbar('Monthly payments are being activated', { variant: 'info' }))
			ZARK.post(`${route.v2}/subscription`, { amount })
				.then((res: AxiosResponse<StripeSubscriptionResponse>) => {
					dispatch({ type: subscriptionsAdded.type, payload: res.data })
					dispatch(enqueueSnackbar('Monthly payments activated', { variant: 'success' }))
				})
				.catch(() => {
					dispatch({ type: subscriptionsRequestFailed.type })
					dispatch(enqueueSnackbar('Subscription could not be added. Please try again later or contact support.', { variant: 'error' }))
				})
		})
		.catch(() => {
			dispatch({ type: cardRequestFailed.type })
			dispatch(enqueueSnackbar(`Card could not be added. Please try again later or contact support.`, { variant: 'error' }))
		})
}

export const removeCard = () => dispatch => {
	dispatch({ type: cardRemoving.type })
	dispatch(enqueueSnackbar('Card is being removed', { variant: 'info' }))
	ZARK.delete(`${route.v2}/card`)
		.then(() => {
			dispatch({ type: cardRemoved.type })
			dispatch(enqueueSnackbar('Card has been removed', { variant: 'success' }))
		})
		.catch(() => {
			dispatch({ type: cardRemovalFailed.type })
			dispatch(enqueueSnackbar('Card could not be removed. Please try again later or contact support.', { variant: 'error' }))
		})
}

export const getSubscriptions = () => dispatch => {
	dispatch({ type: subscriptionsRequested.type })
	ZARK.get(`${route.v2}/subscription`)
		.then((res: AxiosResponse<StripeSubscriptionResponse>) => dispatch({ type: subscriptionsReceived.type, payload: res.data }))
		.catch(() => {
			dispatch({ type: subscriptionsRequestFailed.type })
			dispatch(enqueueSnackbar(`Something went wrong when getting subscriptions. Please try again later or contact support.`, { variant: 'error' }))
		})
}

export const addSubscriptions = amount => dispatch => {
	dispatch({ type: subscriptionsRequested.type })
	dispatch(enqueueSnackbar('Monthly payments are being activated', { variant: 'info' }))
	ZARK.post(`${route.v2}/subscription`, { amount })
		.then((res: AxiosResponse<StripeSubscriptionResponse>) => {
			dispatch({ type: subscriptionsAdded.type, payload: res.data })
			dispatch(enqueueSnackbar('Monthly payments activated', { variant: 'success' }))
		})
		.catch(() => {
			dispatch({ type: subscriptionsRequestFailed.type })
			dispatch(enqueueSnackbar('Subscription could not be added. Please try again later or contact support.', { variant: 'error' }))
		})
}

export const removeSubscriptions = () => dispatch => {
	dispatch(enqueueSnackbar('Monthly payments are being deactivated', { variant: 'info' }))
	ZARK.delete(`${route.v2}/subscription`)
		.then((res: AxiosResponse<StripeSubscriptionResponse>) => {
			dispatch({ type: subscriptionsRemoved.type, payload: res.data })
			dispatch(enqueueSnackbar('Monthly payments deactivated', { variant: 'success' }))
		})
		.catch(() => {
			dispatch(enqueueSnackbar('Subscription could not be removed. Please try again later or contact support.', { variant: 'error' }))
		})
}

export const getSubscriptionTransactions = () => dispatch => {
	dispatch({ type: transactionsRequested.type })
	ZARK.get(`${route.v2}/donorbox-transactions`)
		.then(donorboxData => {
			ZARK.get(`${route.v2}/transactions`)
				.then(res => dispatch({ type: transactionsReceived.type, payload: [...res.data, ...donorboxData.data] }))
				.catch(() => dispatch({ type: transactionsRequestFailed.type }))
		})
		.catch(() => {
			ZARK.get(`${route.v2}/transactions`)
				.then(res => dispatch({ type: transactionsReceived.type, payload: res.data }))
				.catch(() => dispatch({ type: transactionsRequestFailed.type }))
		})
}

export const getDonorboxSubscriptionTransactions = () => dispatch => {
	dispatch({ type: transactionsRequested.type })
	ZARK.get(`${route.v2}/donorbox-transactions`)
		.then(res => dispatch({ type: transactionsReceived.type, payload: res.data }))
		.catch(() => dispatch({ type: transactionsRequestFailed.type }))
}

export const prepareMonthlyTransactionsForChart = transactions => {
	const modifiedArr = transactions.map(transaction => ({
		chartAmount: Math.round(transaction.amount * 100) / 100,
		...transaction,
	}))

	return modifiedArr
}

export const makeMonthlyTransactionsPrettier = transactions => {
	const modifiedArr = transactions.map(transaction => ({
		prettyDate: moment(transaction.date).format('MMM DD YYYY'),
		...transaction,
	}))

	const result = modifiedArr.sort((a, b) => (a.date > b.date ? -1 : 1))

	return result
}

export const getTotalMonthlyTransactions = transactions => {
	const total = transactions.reduce((acc, transaction) => acc + parseInt(transaction.amount), 0)
	return total
}
