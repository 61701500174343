import React from 'react'
import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Components
import { Preview } from '.'

const Section = styled.section`
	background-color: var(--COLOR_WHITE);
	padding: 100px 20px 50px 20px;

	@media (max-width: ${BREAK._768}) {
		padding: 50px 20px 50px 20px;
	}
`
const LOCAL = {
	defaultAssetPath: '/images/assets',
	imgDashPreview: 'dashboard-preview',
}

export default function PreviewSection() {
	return (
		<Section id="preview">
			<Preview>
				<Preview.Header>
					<Preview.Headline>Get to know your dashboard</Preview.Headline>
					<Preview.Description>
						You can find the total amount of your contribution and the breakdown right when you log into your Zark dashboard. The dashboard will also show your linked
						accounts on the left hand-side with fundraisers available to be selected underneath.
					</Preview.Description>
				</Preview.Header>
				<Preview.Main>
					<Preview.Image
						webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgDashPreview}.webp`}
						jpg={`${LOCAL.defaultAssetPath}/${LOCAL.imgDashPreview}.jpg`}
						alt={`dashboard image preview`}
					/>
				</Preview.Main>
			</Preview>
		</Section>
	)
}
