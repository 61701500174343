import React from 'react'

// * Components
import { FooterComp } from 'components/global'
import { HeaderSection, FeatureSection, FundSection, PreviewSection, TeamSection, FaqSection } from 'components/homepage/'

export default function Homepage() {
	return (
		<>
			<HeaderSection />
			{/* <MembersSection /> */}
			<FeatureSection />
			<FundSection />
			<PreviewSection />
			<TeamSection />
			<FaqSection />
			<FooterComp hasAnchor />
		</>
	)
}
