import React from 'react'
import { useRef, useState } from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import { Player } from '@lottiefiles/react-lottie-player'
import { IMaskMixin } from 'react-imask'

// * Styles
import * as G from 'components/onboarding/globalStyled'
import * as S from './styled'

export default function Form({ children, ...props }) {
	return <G.Container {...props}>{children}</G.Container>
}

Form.Navbar = function FormNavbar({ to, logo = '/images/brand/zark-logo.svg' }) {
	return (
		<G.Navbar data-testid="register-navbar" logo={logo}>
			<ReachRouterLink to={to} />
		</G.Navbar>
	)
}

//** Header Children */
Form.Header = function FormHeader({ children, ...props }) {
	return (
		<G.Header data-testid="register-header" {...props}>
			{children}
		</G.Header>
	)
}

Form.Title = function FormTitle({ children, ...props }) {
	return (
		<G.Title data-testid="register-title" {...props}>
			{children}
		</G.Title>
	)
}

Form.Description = function FormDescription({ children, ...props }) {
	return (
		<G.Description data-testid="register-description" {...props}>
			{children}
		</G.Description>
	)
}

Form.InfoBox = function FormInfoBox({ children, ...props }) {
	return (
		<G.InfoBox data-testid="register-info-box" {...props}>
			{children}
		</G.InfoBox>
	)
}

//** Body Children */
Form.Body = function FormBody({ children, ...props }) {
	return (
		<G.Body data-testid="register-body" {...props}>
			{children}
		</G.Body>
	)
}

Form.NameInput = function FormNameInput({ ...props }) {
	return (
		<G.InputWrapper data-testid="register-name-input-wrapper">
			<G.Input data-testid="register-name-input" type="text" placeholder="Name" {...props} />
			<div id="input-background"></div>
		</G.InputWrapper>
	)
}

Form.EmailInput = function FormEmailInput({ ...props }) {
	return (
		<G.InputWrapper data-testid="register-email-input-wrapper">
			<G.Input data-testid="register-email-input" type="email" placeholder="Email" {...props} />

			<div id="input-background"></div>
		</G.InputWrapper>
	)
}

Form.PhoneInput = function FormPhoneInput({ value, mask = '(###) ###-####', ...props }) {
	return (
		<G.InputWrapper data-testid="register-phone-input-wrapper">
			<G.Input data-testid="register-phone-input" type="text" placeholder="Phone" value={Form.PhoneInput.Format(value, mask)} {...props} />
			<div id="input-background"></div>
		</G.InputWrapper>
	)
}

Form.PhoneInput.Format = (value, mask) => {
	let i = 0
	let lastReplacedIndex = -1
	const filledMask = mask.replace(/#/g, (_, j) => {
		if (i >= value.length) {
			return '#'
		}
		lastReplacedIndex = j
		return value[i++]
	})
	return filledMask.substring(0, lastReplacedIndex + 1)
}

Form.PasswordInput = function FormPasswordInput({ ...props }) {
	const [field, meta] = useField(props)

	const [showPassword, setShowPassword] = useState(true)
	const passwordInput = useRef(null)

	return (
		<G.OutsideWrapper error={meta.touched && meta.error}>
			<G.InputWrapper data-testid="register-password-input-wrapper">
				<G.Input
					error={meta.touched && meta.error}
					data-testid="register-password-input"
					id="password"
					ref={passwordInput}
					type={showPassword ? 'password' : 'text'}
					{...field}
					{...props}
				/>

				{field.value && (
					<G.ShowPassword
						data-testid="register-password-show"
						id="input-show"
						title={showPassword ? 'Show Password' : 'Hide Password'}
						onClick={e => {
							e.preventDefault()
							setShowPassword(!showPassword)
							passwordInput.current.focus()
						}}
					>
						{showPassword ? 'SHOW' : 'HIDE'}
					</G.ShowPassword>
				)}

				<div id="input-background"></div>
			</G.InputWrapper>
			<ErrorMessage component={'div'} className={'error'} name={field.name} />
		</G.OutsideWrapper>
	)
}

Form.Input = function FormInput({ ...props }) {
	const [field, meta] = useField(props)
	return (
		<G.OutsideWrapper>
			<G.InputWrapper data-testid="register-input-wrapper">
				<G.Input error={meta.touched && meta.error} data-testid="input" {...field} {...props} />

				<div id="input-background"></div>
			</G.InputWrapper>
			<ErrorMessage component={'div'} className={'error'} name={field.name} />
		</G.OutsideWrapper>
	)
}

Form.Checkbox = function FormCheckbox({ to, ...props }) {
	const [field] = useField(props)
	return (
		<S.CheckboxLabel>
			<input {...field} {...props} />
			<span>
				{' '}
				Get event coupon. <ReachRouterLink to={to}>Learn more</ReachRouterLink>
			</span>
		</S.CheckboxLabel>
	)
}

Form.MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
	const [field, meta] = useField(props)

	return (
		<G.OutsideWrapper>
			<G.InputWrapper data-testid="register-input-wrapper">
				<G.Input error={meta.touched && meta.error} {...props} {...field} ref={inputRef} />
				<div id="input-background"></div>
			</G.InputWrapper>
			<ErrorMessage component={'div'} className={'error'} name={field.name} />
		</G.OutsideWrapper>
	)
})

Form.CodeInput = function FormCodeInput({ ...props }) {
	const [field, meta] = useField(props)

	return (
		<G.OutsideWrapper>
			<G.InputWrapper data-testid="register-phone-input-wrapper">
				<G.CodeInput data-testid="register-phone-input" id="phoneCode" error={meta.touched && meta.error} pattern="[0-9]*" type="text" {...field} {...props} />
				<div id="input-background"></div>
			</G.InputWrapper>
			<ErrorMessage component={'div'} className={'error'} name={field.name} />
		</G.OutsideWrapper>
	)
}

Form.Button = function FormButton({ children, ...props }) {
	const { handleSubmit, isValid, isSubmitting } = useFormikContext()
	return (
		<G.Button disabled={!isValid || isSubmitting} onClick={handleSubmit} data-testid="register-button" type="submit" {...props}>
			<S.PlayerContainer>
				{children}
				{isSubmitting && (
					<S.PlayerWrapper>
						<Player
							autoplay
							loop
							src="/images/lotties/fun-loader.json"
							style={{
								height: '30px',
								width: '30px',
							}}
						/>
					</S.PlayerWrapper>
				)}
			</S.PlayerContainer>
		</G.Button>
	)
}

//** Footer Children */
Form.Footer = function FormFooter({ children, ...props }) {
	return (
		<G.Footer data-testid="register-footer" {...props}>
			{children}
		</G.Footer>
	)
}

Form.TextWithLink = function FormFooterText({ children, ...props }) {
	return (
		<G.TextWithLink data-testid="register-text-with-link" {...props}>
			{children}
		</G.TextWithLink>
	)
}
