import styled from 'styled-components'

export const Select = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 8px;
	margin-bottom: 10px;
`

export const Option = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	padding: 0 25px 0 30px;
	font-size: var(--TYPE_LP);
	font-family: var(--FONT_ZARK);
	background-color: var(--COLOR_WHITE);
	border: 2px solid var(--COLOR_GREY);
	border-radius: 12px;
	cursor: pointer;

	${({ active }) =>
		active &&
		`
	        background-color: var(--COLOR_SECONDARY_LIGHT);
	        border: 2px solid var(--COLOR_SECONDARY);`}
`

export const InputWrapper = styled.div`
	height: 70px;
	position: relative;
	display: flex;
	margin-bottom: 20px;

	#input-background {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: var(--COLOR_SECONDARY_NEUTRAL);
		z-index: 0;
	}
`

export const Input = styled.input`
	width: 100%;
	padding: 0 25px 0 30px;
	font-size: var(--TYPE_H2);
	text-align: center;
	font-family: var(--FONT_ZARK);
	background: transparent;
	border: none;
	outline: 0;
	z-index: 1;

	::placeholder {
		color: var(--COLOR_PRIMARY);
	}

	:focus ~ #input-background {
		outline: 2px solid var(--COLOR_SECONDARY);
	}
`

export const Button = styled.button`
	position: relative;
	font-size: var(--TYPE_LP);
	font-family: var(--FONT_ZARK);
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	:disabled {
		color: var(--COLOR_GREY_DARK);
		background-color: var(--COLOR_GREY);
		cursor: not-allowed;
	}

	& > button {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		opacity: 0;
		z-index: 1;
	}

	${({ outline }) =>
		outline
			? `color: var(--COLOR_PRIMARY);
	        background-color: var(--COLOR_WHITE);
	        border: 2px solid var(--COLOR_PRIMARY);`
			: `color: var(--COLOR_SECONDARY);
	        background-color: var(--COLOR_PRIMARY);
	        `}
`
