import React from 'react'

// * Styles
import { Container, Title, Text } from '../globalStyled'
import * as S from './styled'

export default function FundPopup({ onClose, children, ...props }) {
	return (
		<Container data-testid="fund-popup-container" {...props}>
			<div data-testid="fund-popup-container-backdrop" className="backdrop" onClick={onClose}></div>
			<div data-testid="fund-popup-container-card" className="card">
				{children}
			</div>
		</Container>
	)
}

FundPopup.Title = function FundPopupTitle({ children, ...props }) {
	return (
		<Title data-testid="fund-popup-title" {...props}>
			{children}
		</Title>
	)
}

FundPopup.Text = function FundPopupText({ children, ...props }) {
	return (
		<Text data-testid="fund-popup-text" {...props}>
			{children}
		</Text>
	)
}

FundPopup.Card = function FundPopupCard({ children, ...props }) {
	return (
		<S.Card data-testid="fund-popup-card" {...props}>
			{children}
		</S.Card>
	)
}

FundPopup.LinkWrapper = function FundPopupLinkWrapper({ children, ...props }) {
	return (
		<S.LinkWrapper data-testid="fund-popup-link-wrapper" {...props}>
			{children}
		</S.LinkWrapper>
	)
}

FundPopup.FundLink = function FundPopupFundLink({ websiteLink, title, icon, abbr, children, ...props }) {
	return (
		<S.FundLink data-testid="fund-popup-fund-link" rel="noopener noreferrer" target="_blank" href={websiteLink} title={title} {...props}>
			<img data-testid="roundup-link-image" src={`/images/assets/${icon}`} alt={title} />
			{abbr}{' '}
		</S.FundLink>
	)
}

FundPopup.Item = function FundPopupItem({ active, children, ...props }) {
	return (
		<S.Item data-testid="fund-popup-item" className={active ? 'active' : 'inactive'} {...props}>
			{children}
		</S.Item>
	)
}

FundPopup.ItemTitleGroup = function FundPopupItemTitleGroup({ children, ...props }) {
	return (
		<S.ItemTitleGroup data-testid="fund-popup-item-title-group" {...props}>
			{children}
		</S.ItemTitleGroup>
	)
}

FundPopup.ItemRadio = function FundPopupItemRadio({ children, ...props }) {
	return (
		<S.ItemRadio id="item-radio" {...props}>
			{children}
		</S.ItemRadio>
	)
}

FundPopup.ItemLogo = function FundPopupItemLogo({ children, ...props }) {
	return (
		<S.ItemLogo data-testid="fund-popup-item-logo" {...props}>
			{children}
		</S.ItemLogo>
	)
}

FundPopup.ItemFundLogo = function FundPopupFundLogo({ children, ...props }) {
	return (
		<S.ItemFundLogo data-testid="fund-popup-item-fund-logo" {...props}>
			{children}
		</S.ItemFundLogo>
	)
}

FundPopup.ItemTitle = function FundPopupItemTitle({ children, ...props }) {
	return (
		<S.ItemTitle data-testid="fund-popup-item-title" {...props}>
			{children}
		</S.ItemTitle>
	)
}

FundPopup.SquareWrapper = function FundPopupSquareWrapper({ children, ...props }) {
	return (
		<S.SquareWrapper data-testid="fund-popup-square-wrapper" {...props}>
			{children}
		</S.SquareWrapper>
	)
}

FundPopup.CategoryGroup = function FundPopupCategoryGroup({ children, ...props }) {
	return (
		<S.CategoryGroup data-testid="fund-popup-category-group" {...props}>
			{children}
		</S.CategoryGroup>
	)
}

FundPopup.CategoryTitle = function FundPopupCategoryTitle({ children, ...props }) {
	return (
		<>
			<S.CategoryTitle data-testid="fund-popup-category-title" {...props}>
				{children}
			</S.CategoryTitle>
		</>
	)
}

FundPopup.Square = function FundPopupSquare({ active, children, ...props }) {
	return (
		<S.Square data-testid="fund-popup-square" className={active ? 'active' : 'inactive'} {...props}>
			{children}
		</S.Square>
	)
}

FundPopup.SquareContent = function FundPopupSquareContent({ children, ...props }) {
	return (
		<S.SquareContent data-testid="fund-popup-square-content" {...props}>
			{children}
		</S.SquareContent>
	)
}

FundPopup.SquareLogoWrapper = function FundPopupSquareLogoWrapper({ children, ...props }) {
	return (
		<S.SquareLogoOutline data-testid="fund-popup-square-logo-outline" {...props}>
			<S.SquareLogo data-testid="fund-popup-square-logo" {...props}>
				{children}
			</S.SquareLogo>
		</S.SquareLogoOutline>
	)
}

FundPopup.SquareTitle = function FundPopupSquareTitle({ link, children, ...props }) {
	return (
		<S.SquareTitle data-testid="fund-popup-square-title" {...props}>
			{link ? (
				<a data-testid="fund-popup-link" rel="noopener noreferrer" target="_blank" href={link}>
					{children}
					<img src="/images/icons/icon-link.svg" alt="icon-link" />
				</a>
			) : (
				children
			)}
		</S.SquareTitle>
	)
}

FundPopup.SquareSubtitle = function FundPopupSquareSubtitle({ children, ...props }) {
	return <S.SquareSubtitle {...props}>{children}</S.SquareSubtitle>
}

FundPopup.SquareTag = function FundPopupSquareTag({ children, ...props }) {
	return <S.SquareTag {...props}>{children}</S.SquareTag>
}
