import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const ChartContainer = styled.div`
	position: relative;
	width: 100%;
	min-width: 300px;
	height: 200px;
	opacity: 0; /* start with 0 opacity */
	animation: fadeIn 2s ease-in-out forwards; /* apply the fadeIn animation with 0.5s duration */

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`

export const TooltipWrapper = styled.div`
	padding: 15px 20px;
	color: var(--COLOR_PRIMARY);
	font-family: var(--FONT_TEXT);
`

export const TooltipDate = styled.p`
	font-size: var(--TYPE_MP);
	font-weight: 500;
`

export const TooltipRoundups = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	& svg {
		margin-left: 2px;
		margin-right: -6px;
	}

	& b {
		margin-left: 4px;
	}

	& p {
		font-size: 14px;
		font-weight: 500;
	}
`
