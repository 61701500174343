import React from 'react'
import { Link as ReachRouterLink, useNavigate } from 'react-router-dom'
import { Formik as FormikWrapper, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

// * Constants
import * as ROUTES from 'constants/routes'

// * Redux
import { validateUser } from 'store/authSlice'
import { useAppSelector } from 'store/combinedReducer'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Form from '..'

const validateEmailPass = Yup.object({
	email: Yup.string().email('Must be a valid email').required().label('Email'),
	password: Yup.string().required().label('Password'),
})

const validateCode = Yup.object({
	phoneCode: Yup.string().required().length(4, 'Must be 4 digits').label('Code'),
})

export default function LoginFlow() {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const auth = useAppSelector(state => state.auth)
	const [showError, setShowError] = React.useState(false)
	const [showCode, setShowCode] = React.useState(false)

	React.useEffect(() => {
		auth.authenticated && navigate(ROUTES.DASHBOARD)
	}, [auth, navigate])

	const handleSubmit = (values, { setSubmitting }) => {
		dispatch(validateUser(values, 'login', setSubmitting, setShowError, setShowCode))
	}

	return showCode ? (
		<Form>
			<Form.Header>
				<Form.Title>Enter Code</Form.Title>
				<Form.Description>A text message with a 4-digit verification code was sent to your phone</Form.Description>
				{showError && <Form.InfoBox log="error">Sorry, the pin you entered is old or incorrect.</Form.InfoBox>}
			</Form.Header>
			<FormikWrapper
				initialValues={{
					phoneCode: '',
				}}
				validationSchema={validateCode}
				onSubmit={handleSubmit}
			>
				<FormikForm>
					<Form.CodeInput name="phoneCode" placeholder="####" maxLength={4} autoComplete="off" autoFocus />
					<Form.Button>Verify and Log In</Form.Button>
				</FormikForm>
			</FormikWrapper>
		</Form>
	) : (
		<Form>
			<Form.Header>
				<Form.Title>Log in to Zark</Form.Title>
				{showError && (
					<Form.InfoBox log="error">
						Sorry, we can’t find an account with this email address or password. Please try again or{' '}
						<ReachRouterLink to={ROUTES.REGISTER}>create a new account</ReachRouterLink>.
					</Form.InfoBox>
				)}
			</Form.Header>
			<FormikWrapper
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={validateEmailPass}
				onSubmit={handleSubmit}
			>
				<FormikForm>
					<Form.Input name="email" type="email" placeholder="Email" />
					<Form.PasswordInput name="password" placeholder="Password" />
					<Form.Button>Log In</Form.Button>
					<Form.TextWithLink>
						Don't have an account? <ReachRouterLink to={ROUTES.REGISTER}>Sign up</ReachRouterLink>
					</Form.TextWithLink>
					<Form.TextWithLink>
						<ReachRouterLink to={ROUTES.RESET_PASSWORD}>Forgot your password?</ReachRouterLink>
					</Form.TextWithLink>
				</FormikForm>
			</FormikWrapper>
		</Form>
	)
}
