import React from 'react'

// * Components
import Details from '..'
import { StripeSubscriptionPopupComp } from 'components/dashboard/cards'

const LOCAL = {
	defaultAssetPath: '/images/assets',
	imgMonthly: 'feature-monthly-short',
	logoPlaid: 'logo-plaid',
	logoStripe: 'logo-stripe',
}

const MonthlyFlow = ({ onPrevStep }) => {
	const [stripePopupIsOpen, setStripePopupIsOpen] = React.useState(false)

	return (
		<>
			{stripePopupIsOpen && <StripeSubscriptionPopupComp onClose={() => setStripePopupIsOpen(false)} />}

			<Details>
				<Details.Header>
					<Details.Tag>Step 3 of 3</Details.Tag>
					<Details.Title>{`Activate Fixed Monthly`}</Details.Title>
					<Details.Description>{`Contribute a fixed amount every month.`}</Details.Description>
				</Details.Header>
				<Details.Body>
					<Details.Image webp={`${LOCAL.defaultAssetPath}/${LOCAL.imgMonthly}.webp`} png={`${LOCAL.defaultAssetPath}/${LOCAL.imgMonthly}.png`} />
					<Details.SecureWrapper>
						<Details.SecureText firstLine>We use bank-level security provided</Details.SecureText>
						<Details.SecureText>
							by
							<Details.SecureImage src={`${LOCAL.defaultAssetPath}/${LOCAL.logoStripe}.svg`} />
						</Details.SecureText>
					</Details.SecureWrapper>
					<Details.ButtonWrapper>
						<Details.Button
							outline
							onClick={() => {
								onPrevStep()
							}}
						>
							Go Back
						</Details.Button>
						<Details.Button
							onClick={e => {
								e.preventDefault()
								setStripePopupIsOpen(true)
							}}
						>
							Continue
						</Details.Button>
					</Details.ButtonWrapper>
				</Details.Body>
			</Details>
			<Details.Footer>
				<Details.FootLink>
					Your information is safely encrypted.
					<br />
					See{' '}
					<a rel="noopener noreferrer" target="_blank" href="/documents/Zark Terms of Use.pdf">
						Terms & Conditions
					</a>
					.
				</Details.FootLink>
			</Details.Footer>
		</>
	)
}

export default MonthlyFlow
