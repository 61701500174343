import styled from 'styled-components'

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 30px;
	height: 70px;
	background-color: var(--COLOR_SECONDARY_NEUTRAL);
	margin-bottom: 20px;
`
