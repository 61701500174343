import React from 'react'
import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const GreetIcon = () => {
	return (
		<Icon>
			<Dropdown>
				<DropdownLink
					mailto
					email="lao+requesttaxdocument@zarkinow.com"
					subject={'Tax Document Request'}
					body={'Please enter the details below to get your tax document.\n\nFull Name:\nZark Account Email:\nPhone Number:'}
				>
					Request Tax Documents
				</DropdownLink>
			</Dropdown>
		</Icon>
	)
}

const Icon = ({ children }) => {
	const [open, setOpen] = React.useState(false)

	return (
		<StyleIcon data-testid="greet-hover-icon" onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
			<img src="/images/icons/icon-more.svg" alt="icon-more" />
			{open && children}
		</StyleIcon>
	)
}

const Dropdown = ({ children }) => {
	return (
		<StyleDropdown data-testid="greet-dropdown">
			<div id="dropdown-background">{children}</div>
		</StyleDropdown>
	)
}

const DropdownLink = ({ mailto, email, subject = '', body = '', children }) => {
	let params = subject || body ? '?' : ''
	if (subject) params += `subject=${encodeURIComponent(subject)}`
	if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`
	return <StyleDropdownLink data-testid="greet-dropdown-link">{mailto && <a href={`mailto:${email}${params}`}>{children}</a>}</StyleDropdownLink>
}

const DropdownLine = () => {
	return <StyleDropdownLine data-testid="greet-dropdown-line"></StyleDropdownLine>
}

const StyleIcon = styled.div`
	position: absolute;
	top: 40px;
	right: 40px;
	border-radius: 100%;
	height: 40px;
	width: 40px;
	flex-shrink: 0;
	margin-left: auto;
	cursor: pointer;
	background-color: var(--COLOR_GREY_LIGHT);

	img {
		width: 100%;
	}

	@media (max-width: ${BREAK._1100}) {
		position: absolute;
		right: 20px;
		height: 30px;
		width: 30px;
	}
`

const StyleDropdown = styled.div`
	position: absolute;
	z-index: 100;
	right: 3px;

	margin-top: -70px;
	width: 240px;

	#dropdown-background {
		position: relative;
		top: 70px;
		margin-bottom: 20px;
		padding: 5px;
		border-radius: 12px;
		height: 100%;
		box-shadow: var(--SHADOW_SOFT);
		background-color: var(--COLOR_WHITE);
		display: grid;
		gap: 3px;
		z-index: 0;
	}
`

const StyleDropdownLine = styled.div`
	position: relative;
	justify-self: center;
	width: calc(100% + 10px);
	height: 2px;
	background-color: var(--COLOR_GREY);
	opacity: 0.3;
`

const StyleDropdownLink = styled.div`
	position: relative;
	height: 52px;
	padding: 15px;
	font-size: var(--TYPE_SL);
	font-weight: 600;
	border-radius: 10px;
	text-transform: capitalize;
	display: flex;
	align-items: center;

	:hover {
		background-color: var(--COLOR_SECONDARY_LIGHT);
	}
`
