import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

// GroupedFundPick
export const Logo = styled.div<{ src: string }>`
	margin-left: -20px;
	height: 35px;
	width: 35px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 1px white;
	outline: solid 1px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
`

// GroupedFundPick
export const GroupWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 110%;
	gap: 10px;
	align-self: center;

	@media (max-width: ${BREAK._768}) {
		width: 100%;
	}
`

// GroupedFundPick
export const GroupItem = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 20px;
	gap: 15px;
	border-radius: 12px;
	cursor: pointer;
	user-select: none;

	outline: ${props => (props.active ? '2px solid var(--COLOR_SECONDARY)' : 'none')};
	background-color: ${props => (props.active ? 'var(--COLOR_GREY_WARM_LIGHT);' : 'var(--COLOR_SECONDARY_NEUTRAL)')};

	:hover {
		background-color: var(--COLOR_GREY_LIGHT);

		#radio {
			border: 6px solid var(--COLOR_SECONDARY);
		}
	}

	#radio {
		width: 22px;
		height: 22px;
		flex-shrink: 0;
		border: ${props => (props.active ? '6px solid var(--COLOR_SECONDARY);' : '2px solid var(--COLOR_GREY);')};
		background-color: var(--COLOR_WHITE);
		border-radius: 100%;
		display: flex;
	}

	#content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		#logo-wrapper {
			display: flex;
			flex-direction: row;

			& :nth-child(1) {
				margin-left: 0px;
			}
		}

		#title {
			font-size: var(--TYPE_MP);
			font-weight: 600;
		}
	}
`
