import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.div`
	z-index: 1;
`

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4001;
	background: rgba(0, 0, 0, 0.75);
`

export const Inner = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

export const InnerContent = styled.div`
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	z-index: 4003;
`

export const InnerScaler = styled.div`
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;

	& iframe {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: none;
		background: #000;
	}
`

export const Close = styled.button`
	/* Close Icon */
	background-image: url(${({ src }) => src});
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 50px 50px;
	color: white;
	text-indent: 200%;
	overflow: hidden;
	position: absolute;
	right: 0px;
	top: -60px;
	width: 64px;
	height: 64px;
	background-color: transparent;

	@media screen and (min-width: 71.875em) {
		right: -60px;
	}
`

export const Icon = styled.button`
	width: 80px;
	height: 80px;
	border-radius: 100%;
	background-color: var(--COLOR_SECONDARY);
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: var(--SHADOW_SOFT);
	margin: 30px;
	transition: 300ms;

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	svg {
		width: 36px;
		height: 36px;
		fill: var(--COLOR_WHITE);
		transition: 300ms;
	}

	&:hover svg {
		transform: scale(0.9);
		transition: 300ms;
	}

	@media (max-width: ${BREAK._950}) {
		background-size: 100px 100px;
		width: 65px;
		height: 65px;
		margin: 10px;

		svg {
			width: 30px;
			height: 30px;
			fill: var(--COLOR_WHITE);
			transition: 300ms;
		}
	}
`
