import React from 'react'

// * Style Injection
import '../cropperStyle.css'

// * Components
import ProfilePicturePopup from '..'

export default function ProfilePicturePopupComp({ onClose }) {
	const [file, setFile] = React.useState(null)

	const fileHandler = e => {
		if (e.target.files?.length > 0) {
			const reader = new FileReader()
			reader.readAsDataURL(e.target.files[0])
			reader.addEventListener('load', () => {
				console.log(reader.result)
				setFile(reader.result)
			})
		}
	}

	return (
		<ProfilePicturePopup onClose={onClose}>
			<ProfilePicturePopup.Title>Update profile picture</ProfilePicturePopup.Title>
			<ProfilePicturePopup.Text>Add a personal touch to your account</ProfilePicturePopup.Text>
			{file ? (
				<ProfilePicturePopup.ActivateCrop onClose={onClose} file={file} fileHandler={fileHandler} />
			) : (
				<ProfilePicturePopup.ButtonUpload setFile={setFile} fileHandler={fileHandler} />
			)}
			<input style={{ display: 'none' }} type="file" id="file" accept=".png,.jpg,.jpeg" onChange={fileHandler} />
		</ProfilePicturePopup>
	)
}
