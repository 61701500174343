import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'

// * Redux
import { useAppDispatch } from 'store/configureStore'
import { addCardWithSubscription } from 'store/stripeSlice'

// * Components
import StripePopup from '..'
import MonthlyPopup from '../../MonthlyPopup'

export default function StripeSubscriptionPopupComp({ onClose }) {
	const dispatch = useAppDispatch()
	const stripePromise = loadStripe('pk_live_7QsJUcLPtH7dY5uJXr1z1mfK')
	const [subscriptionAmount, setSubscriptionAmount] = React.useState('')

	const handleStripe = cardId => {
		dispatch(addCardWithSubscription(cardId, subscriptionAmount))
	}

	return (
		<StripePopup onClose={onClose}>
			<StripePopup.Title>Activate Fixed Monthly</StripePopup.Title>
			<Elements stripe={stripePromise}>
				<StripeFormWrapper
					addCardToStripe={handleStripe}
					closePopup={onClose}
					subscriptionAmount={subscriptionAmount}
					setSubscriptionAmount={setSubscriptionAmount}
				/>
			</Elements>
		</StripePopup>
	)
}

const StripeFormWrapper = ({ closePopup, addCardToStripe, subscriptionAmount, setSubscriptionAmount }) => {
	const elements = useElements()
	const stripe = useStripe()

	const handleSubmit = async event => {
		event.preventDefault()

		const cardElement = elements.getElement(CardElement)
		const stripeResult = await stripe.createToken(cardElement)
		if (stripeResult && stripeResult.token) {
			addCardToStripe(stripeResult.token.id)
			closePopup()
		}
	}

	const CARD_OPTIONS = {
		style: {
			base: {
				padding: '15px 20px',
				color: '#32325d',
				fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '18px',
				marginTop: '35px',
				marginBottom: '35px',
				letterSpacing: '0.025em',

				'::placeholder': {
					color: '#aab7c4',
				},
				':-webkit-autofill': {
					color: '#32325d',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
				':-webkit-autofill': {
					color: '#fa755a',
				},
			},
		},
	}

	return (
		<form onSubmit={handleSubmit}>
			<StripePopup.Text>Step 1: Enter your card information</StripePopup.Text>

			<StripePopup.InputWrapper>
				<CardElement options={CARD_OPTIONS} />
			</StripePopup.InputWrapper>
			<StripePopup.Text>Step 2: Set desired amount to contribute</StripePopup.Text>
			<MonthlyPopup.Select>
				<MonthlyPopup.Option active={subscriptionAmount === '25'} onClick={() => setSubscriptionAmount('25')}>
					25
				</MonthlyPopup.Option>
				<MonthlyPopup.Option active={subscriptionAmount === '50'} onClick={() => setSubscriptionAmount('50')}>
					50
				</MonthlyPopup.Option>
				<MonthlyPopup.Option active={subscriptionAmount === '100'} onClick={() => setSubscriptionAmount('100')}>
					100
				</MonthlyPopup.Option>
			</MonthlyPopup.Select>
			<MonthlyPopup.Input
				value={subscriptionAmount}
				onChange={({ target: { value } }) => {
					if (value === '' || /^((?!(0))[0-9]{1,3})$/.test(value)) {
						setSubscriptionAmount(value)
					}
				}}
			/>
			<StripePopup.ButtonWrapper>
				<StripePopup.Button outline onClick={closePopup}>
					Go Back
				</StripePopup.Button>
				<StripePopup.Button type="submit" disabled={!stripe || subscriptionAmount.length === 0}>
					Activate
				</StripePopup.Button>
			</StripePopup.ButtonWrapper>
		</form>
	)
}
