import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

// * Styles
import { Container, Loop, Content, Quote, Author } from './styled'

// * Data
import quoteData from 'fixtures/quotes.json'

export default function () {
	const [quote, setQuote] = React.useState('')

	React.useEffect(() => {
		let mounted = true
		if (mounted) {
			setQuote(quoteData[Math.floor(Math.random() * quoteData.length)])
		}

		return () => {
			mounted = false
		}
	}, [quote])

	return (
		<Container>
			<Loop>
				<Player autoplay loop src="/images/lotties/zark-beat-dark.json" style={{ height: '100px', width: '100px' }} />
			</Loop>

			<Content>
				<Quote>{quote.quote}</Quote>
				<Author>- {quote.author} ✊</Author>
			</Content>
		</Container>
	)
}
