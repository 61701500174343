import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

export const Container = styled.article`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	max-width: 1200px;
	justify-content: center;
	margin: 60px auto 80px auto;
	padding: 0 20px;
	gap: 70px;

	@media (max-width: ${BREAK._768}) {
		margin: 20px auto 0 auto;
		flex-direction: column;
	}
`

export const TeamContainer = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-width: 1000px;
	margin: 60px auto 0 auto;
	padding: 0 20px;

	@media (max-width: ${BREAK._768}) {
		margin: 20px auto 0 auto;
	}
`

export const Pill = styled.div`
	background-color: ${({ color }) => color};
	padding: 5px 20px;
	text-transform: uppercase;
	font-size: var(--TYPE_SL);
	font-weight: 600;
	width: fit-content;
	border-radius: 40px;
	color: white;
	margin-bottom: 20px;
`

export const Content = styled.div`
	text-align: ${({ isSafari }) => (isSafari ? 'center' : 'start')};

	${({ isSafari }) =>
		isSafari &&
		`display: flex;
	flex-direction: column;
	align-items: center;`}

	@media (max-width: ${BREAK._768}) {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		order: 1;
	}
`

export const Underline = styled.span`
	border-bottom: 5px solid var(--COLOR_SECONDARY);
	@media (max-width: ${BREAK._768}) {
		border-bottom: 4px solid var(--COLOR_SECONDARY);
	}
`

export const Title = styled.h2`
	max-width: 880px;

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_H2);
	}

	@media (max-width: ${BREAK._395}) {
		font-size: var(--TYPE_H3);
	}
`
export const Text = styled.p`
	max-width: 500px;
	margin-top: 10px;
	margin-bottom: 30px;
	font-size: var(${({ size }) => (size ? size : '--TYPE_LP')});

	@media (max-width: ${BREAK._768}) {
		font-size: var(--TYPE_MP);
		max-width: 32ch;
	}
`

export const PageLink = styled.div`
	margin: 20px 0;
	color: var(--COLOR_GREY_DARK);
	a {
		color: var(--COLOR_PRIMARY);
		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Image = styled.div`
	margin-top: -130px;
	max-width: 900px;
	img {
		width: 100%;
	}

	source {
		width: 100px;
	}
	@media (max-width: ${BREAK._768}) {
		margin-top: -50px;
	}
`

export const Button = styled.button`
	font-size: var(--TYPE_ML);
	font-family: var(--FONT_ZARK);
	padding: 0 40px;
	height: 80px;
	background-color: var(--COLOR_PRIMARY);
	color: var(--COLOR_SECONDARY);
	display: flex;
	justify-content: center;
	border-radius: 8px;
	align-items: center;

	@media (max-width: ${BREAK._768}) {
		height: 60px;
		font-size: calc(var(--TYPE_ML)-5);
	}

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}
`
