import React from 'react'

// * Components
import FundPopup from '..'
import { FundPicker } from 'components/global'
import { useAppDispatch } from 'store/configureStore'
import { selectFund } from 'store/authSlice'
import { STORAGE_KEYS } from 'constants/storageKeys'
import { ButtonWrapper, Button } from 'components/onboarding/globalStyled'

export default function FundPopupComp({ setFundPopupIsOpen }) {
	const [selected, setSelected] = React.useState('')

	const dispatch = useAppDispatch()

	function handleClosePopup() {
		setFundPopupIsOpen(false)
	}

	function handleSave() {
		if (selected.length === 0) {
			console.log('nothing selected')
			return setFundPopupIsOpen(false)
		}
		const selection = sessionStorage.getItem(STORAGE_KEYS.SELECTED_FUND)
		console.log('selection was: ', selection)
		dispatch(selectFund(selection))

		return setFundPopupIsOpen(false)
	}

	function handleFundSelection(selectedFund) {
		setSelected(selectedFund)
	}
	return (
		<FundPopup onClose={handleClosePopup}>
			<FundPopup.Title>Select new fund</FundPopup.Title>

			<FundPopup.Text>Pick a category to contribute your round-ups</FundPopup.Text>

			<FundPicker onFundSelection={handleFundSelection} />

			<ButtonWrapper>
				<Button outline onClick={handleClosePopup}>
					Close
				</Button>
				<Button onClick={handleSave}>Save</Button>
			</ButtonWrapper>
		</FundPopup>
	)
}
