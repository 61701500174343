import React from 'react'
import { Link as ReachRouterLink } from 'react-router-dom'

// * Styles
import * as G from 'components/onboarding/globalStyled'
import * as S from './styled'

export default function Monthly({ children, ...props }) {
	return <G.Container {...props}>{children}</G.Container>
}

Monthly.Navbar = function MonthlyNavbar({ to, logo = '/images/brand/zark-logo.svg' }) {
	return (
		<G.Navbar data-testid="monthly-navbar" logo={logo}>
			<ReachRouterLink to={to} />
		</G.Navbar>
	)
}

//** Header Children */
Monthly.Header = function MonthlyHeader({ children, ...props }) {
	return (
		<G.Header data-testid="monthly-header" {...props}>
			{children}
		</G.Header>
	)
}

Monthly.Title = function MonthlyTitle({ children, ...props }) {
	return (
		<G.Title data-testid="monthly-title" {...props}>
			{children}
		</G.Title>
	)
}

Monthly.Description = function MonthlyDescription({ children, ...props }) {
	return (
		<G.Description data-testid="monthly-description" {...props}>
			{children}
		</G.Description>
	)
}

//** Body Children */
Monthly.Body = function MonthlyBody({ children, ...props }) {
	return (
		<G.Body data-testid="monthly-body" {...props}>
			{children}
		</G.Body>
	)
}

Monthly.InfoBox = function MonthlyInfoBox({ children, ...props }) {
	return (
		<G.InfoBox data-testid="monthly-info-box" {...props}>
			{children}
		</G.InfoBox>
	)
}

Monthly.Embed = function MonthlyEmbed({ children, ...props }) {
	return (
		<S.Embed data-testid="monthly-embed" {...props}>
			{children}
		</S.Embed>
	)
}

Monthly.Button = function MonthlyButton({ children, ...props }) {
	return (
		<G.Button data-testid="monthly-button" {...props}>
			{children}
		</G.Button>
	)
}

//** Footer Children */
Monthly.Footer = function MonthlyFooter({ children, ...props }) {
	return (
		<G.Footer data-testid="monthly-footer" {...props}>
			{children}
		</G.Footer>
	)
}

Monthly.TextWithLink = function MonthlyTextWithLink({ children, ...props }) {
	return (
		<G.TextWithLink data-testid="monthly-text-with-link" {...props}>
			{children}
		</G.TextWithLink>
	)
}
