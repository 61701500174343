import { ArrowButton } from 'components/global'
import { determineType } from 'components/global/FundPicker/utilities'
import React from 'react'
import { useAppSelector } from 'store/combinedReducer'
import { FundGroup, FundItem, FundWrapper, PickFunds } from './styled'
import fundData from 'fixtures/funds.json'
// import { useSelectedGroupedFunds as selectedGroupFunds, useSelectedIndividualFunds as selectedIndividualFunds } from 'components/global/FundPicker/hooks'

export const GreetPickFunds = ({ setFundPopupIsOpen }) => {
	const [fund, setFund] = React.useState(null)
	const [fundType, setFundType] = React.useState<'category' | 'individual' | 'unknown'>(null)
	// const [getGroupedFunds, setGroupedFunds] = React.useState({}) as any
	// const [getIndividualFunds, setIndividualFunds] = React.useState([])
	const auth = useAppSelector(state => state.auth)

	function selectedIndividualFunds(state) {
		const groupedFundsArray = fundData.map(nested => ({
			meta: {
				categoryTitle: nested.title,
				categorySlug: nested.slug,
				categoryHex: nested.hex,
			},
			funds: nested.funds.map(item => ({ ...item })),
		}))
		const result = groupedFundsArray?.flatMap(item => item.funds)?.filter(item => state?.includes(item.abbr))
		return result
	}

	function selectedGroupFunds(state) {
		const result = fundData?.find(item => item.slug === state)
		return result
	}

	const getGroupedFunds = selectedGroupFunds(fund)
	const getIndividualFunds = selectedIndividualFunds(fund)
	console.log('getGroupedFunds', getGroupedFunds)
	console.log('getIndividualFunds', getIndividualFunds)

	// React.useEffect(() => {
	// 	setGroupedFunds(selectedGroupFunds(fund))
	// 	setIndividualFunds(selectedIndividualFunds(fund))
	// }, [fund])

	React.useEffect(() => {
		setFund(auth.user.selectedFund)
		setFundType(determineType(auth.user.selectedFund, fundData))
	}, [auth.user.selectedFund])

	return (
		<PickFunds>
			<FundWrapper onClick={() => setFundPopupIsOpen(true)}>
				{fundType === 'category' ? (
					<>
						<FundGroup>
							{getGroupedFunds.funds.map(fund => (
								<FundItem key={fund.id} src={`/images/assets/${fund.icon}`} />
							))}
						</FundGroup>
						<ArrowButton>Pick a Fund</ArrowButton>
					</>
				) : fundType === 'individual' ? (
					<>
						<FundGroup>
							{getIndividualFunds.map(fund => (
								<FundItem key={fund.id} src={`/images/assets/${fund.icon}`} />
							))}
						</FundGroup>
						<ArrowButton>Pick a Fund</ArrowButton>
					</>
				) : (
					<ArrowButton>Pick a New Fund</ArrowButton>
				)}
			</FundWrapper>
		</PickFunds>
	)
}
