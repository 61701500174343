import { createSlice } from '@reduxjs/toolkit'
import ZARK from '../../utils/api'
import { enqueueSnackbar } from '../snackbarSlice'
import { AxiosResponse } from 'axios'
import { PlaidAccountsResponse, PlaidAccountsRemovedResponse, PlaidAccountsAddedResponse } from './types'

const slice = createSlice({
	name: 'plaid',
	initialState: {
		accounts: [],
		accountsLoading: null,
		transactions: [],
		transactionsLoading: null,
		totalRoundups: 0,
		totalRoundupsLoading: null,
		plaidToken: '',
	},
	reducers: {
		accountsRequested: (_, action) => {
			_.accountsLoading = true
		},
		accountsReceived: (_, action) => {
			_.accounts = action.payload
			_.accountsLoading = false
		},
		accountsRequestFailed: (_, action) => {
			_.accountsLoading = false
		},
		accountRemoving: (_, action) => {
			const idToRemove = action.payload.id
			const indexToRemove = _.accounts.findIndex(account => account._id === idToRemove)
			if (indexToRemove !== -1) {
				_.accounts[indexToRemove].cardRemoving = true
			}
		},
		accountsRemoved: (_, action) => {
			const idToRemove = action.payload.id
			const indexToRemove = _.accounts.findIndex(account => account._id === idToRemove)
			if (indexToRemove !== -1) {
				_.accounts = _.accounts.filter(account => account._id !== idToRemove)
			}
		},
		accountsAdded: (_, action) => {
			_.accounts = action.payload.accounts
			_.accountsLoading = false
		},
		transactionsRequested: (_, action) => {
			_.transactionsLoading = true
		},
		transactionsReceived: (_, action) => {
			_.transactions = action.payload
			_.transactionsLoading = false
		},
		transactionsRequestFailed: (_, action) => {
			_.transactionsLoading = false
		},
		plaidTokenRequested: (_, action) => {
			/** */
		},
		plaidTokenReceived: (_, action) => {
			_.plaidToken = action.payload
		},
		plaidTokenRequestFailed: (_, action) => {
			_.accountsLoading = false
		},
	},
})

const {
	accountsRequested,
	accountsReceived,
	accountsRequestFailed,
	accountRemoving,
	accountsRemoved,
	accountsAdded,
	transactionsRequested,
	transactionsReceived,
	transactionsRequestFailed,
	plaidTokenRequested,
	plaidTokenReceived,
	plaidTokenRequestFailed,
} = slice.actions

export default slice.reducer

const root = 'plaid'

const route = {
	v1: `${root}/v1`,
	v2: `${root}/v2`,
}

// Action Creators
export const getAccounts = () => dispatch => {
	dispatch({ type: accountsRequested.type })
	ZARK.get(`${route.v1}/accounts`)
		.then((res: AxiosResponse<PlaidAccountsResponse[]>) => dispatch({ type: accountsReceived.type, payload: res.data }))
		.catch(() => dispatch({ type: accountsRequestFailed.type }))
}

export const addAccounts = plaidData => dispatch => {
	dispatch({ type: accountsRequested.type })
	dispatch(enqueueSnackbar('Setting up your accounts...', { variant: 'info' }))
	ZARK.post(`${route.v1}/accounts`, plaidData.metadata)
		.then((res: AxiosResponse<PlaidAccountsAddedResponse>) => {
			res.data?.messages.forEach(message => dispatch(enqueueSnackbar(message, { variant: 'warning' })))
			dispatch({ type: accountsAdded.type, payload: res.data })
			dispatch(enqueueSnackbar('New accounts have been set up!', { variant: 'success' }))
		})
		.catch(err => {
			dispatch({ type: accountsRequestFailed.type })
			err.response?.status === 409
				? dispatch(enqueueSnackbar('This institution is already connected to your account, try to remove and reconnect again', { variant: 'error' }))
				: dispatch(enqueueSnackbar('Accounts could not be set up', { variant: 'error' }))
		})
}

export const removeAccounts = id => dispatch => {
	dispatch({ type: accountRemoving.type, payload: { id } })
	dispatch(enqueueSnackbar('Account is being removed', { variant: 'info' }))
	ZARK.delete(`${route.v2}/accounts/${id}`)
		.then((res: AxiosResponse<PlaidAccountsRemovedResponse>) => {
			dispatch({ type: accountsRemoved.type, payload: res.data })
			dispatch(enqueueSnackbar('Account has been removed', { variant: 'success' }))
		})
		.catch(() => {
			dispatch(enqueueSnackbar('Account could not be removed', { variant: 'error' }))
		})
}

export const getRoundupTransactions = () => dispatch => {
	dispatch({ type: transactionsRequested.type })
	ZARK.get(`${route.v2}/transactions`)
		.then(res => dispatch({ type: transactionsReceived.type, payload: res.data }))
		.catch(() => dispatch({ type: transactionsRequestFailed.type }))
}

export const getLinkToken = id => dispatch => {
	dispatch({ type: plaidTokenRequested.type })
	ZARK.post(`${route.v2}/token`, { userId: id })
		.then(res => dispatch({ type: plaidTokenReceived.type, payload: res.data.token }))
		.catch(() => dispatch({ type: plaidTokenRequestFailed.type }))
}

// Selectors
export const makeAccountsPrettier = (transactions, account) => {
	// let output = []
	const flatMapped = transactions.flatMap(flatTransactionArr => flatTransactionArr)
	const output = flatMapped.map(eachTransaction => ({ ...eachTransaction, accId: account[eachTransaction.account_id]?.account_id }))
	// console.log(output)
	// console.log(transactions.flatMap(flatTransactionArr => flatTransactionArr.map(transaction => ({ accId: account[transaction.account_id] }))))

	// let output = []
	// account.forEach(a => {
	// 	// console.log(a)
	// 	output[a.accountId] = {
	// 		accName: a.accountName,
	// 		accInstitutionName: a.institutionName,
	// 		accOfficialName: a.accountOfficialName,
	// 		accSubType: a.accountSubtype,
	// 	}
	// 	console.log(output)
	// })
	// return output

	// link bank accounts to each transaction
	// transactions.forEach(t => {
	// 	t.account = accounts[t.account_id]
	// })
}

export const prepareRoundupTransactionsForChart = transactions => {
	const modifiedArr = transactions.map(transaction => ({
		chartAmount: Math.round(transaction.roundUpAmount * 100) / 100,
		...transaction,
	}))

	return modifiedArr
}

export const getTotalRoundupTransactions = transaction => {
	const totalRoundups = transaction.reduce((acc, transaction) => {
		if (transaction.amount > 0) {
			return acc + transaction.roundUpAmount
		}
		return acc
	}, 0)
	return totalRoundups
}

export const makeRoundupTransactionsPrettier = transactions => {
	const linkBankAccountToEachTransaction = transactions.map(transaction => ({
		YYYYMM: `${new Date(transaction.date).getFullYear()}${
			['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'][new Date(transaction.date).getMonth()]
		}`,
		YYYYMonth: `${new Date(transaction.date).getFullYear()} ${
			['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][
				new Date(transaction.date).getMonth()
			]
		}`,
		...transaction,
	}))

	// group transactions by YYYYMM as key
	const groupedTransactions = linkBankAccountToEachTransaction.reduce((acc, transaction) => {
		const key = transaction.YYYYMM
		if (!acc[key]) {
			acc[key] = []
		}
		acc[key].push(transaction)
		return acc
	}, {})

	// sort grouped transactions from oldest to newest
	const sortTransactionsByDate = Object.keys(groupedTransactions)
		.map(key => ({
			YYYYMM: key,
			YYYYMonth: `${key.slice(0, 4)} ${
				['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][
					new Date(key.slice(4, 6)).getMonth()
				]
			}`,
			transactions: groupedTransactions[key].sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()),
		}))
		.reverse()

	const output = sortTransactionsByDate
	return output
}
