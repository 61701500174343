import React from 'react'

// * Constants
import * as ROUTES from 'constants/routes'

// * Components
import Onboarding, { RegisterFlow } from 'components/onboarding'

export default function Register() {
	return (
		<Onboarding>
			<Onboarding.Navbar to={ROUTES.HOMEPAGE} />
			<RegisterFlow />
		</Onboarding>
	)
}
