import React from 'react'
import styled from 'styled-components'

// * Constants
import { STORAGE_KEYS } from 'constants/storageKeys'

// * Redux
import { selectFund } from 'store/authSlice'
import { useAppDispatch } from 'store/configureStore'

// * Components
import Funds from '..'
import { FundPicker } from 'components/global'

export default function FundsFlow({ onNextStep }) {
	const [selected, setSelected] = React.useState('')

	function handleFundSelection(selectedFund) {
		setSelected(selectedFund)
	}

	const isButtonDisabled = selected.length === 0 ? true : false

	const dispatch = useAppDispatch()

	return (
		<Funds>
			<Funds.Header>
				<Funds.Tag>Step 1 of 3</Funds.Tag>
				<Funds.Title>Pick Organizations</Funds.Title>
				<Funds.Description>{'Pick the organizations you want to support\n(You can change this later)'}</Funds.Description>
			</Funds.Header>

			<Funds.Body>
				<FundPicker onFundSelection={handleFundSelection} />
				<Funds.Button
					disabled={isButtonDisabled}
					onClick={e => {
						e.preventDefault()
						const selection = sessionStorage.getItem(STORAGE_KEYS.SELECTED_FUND)
						onNextStep()
						dispatch(selectFund(selection))
					}}
				>
					Continue
				</Funds.Button>
			</Funds.Body>
		</Funds>
	)
}
