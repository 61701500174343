import React from 'react'

// * Styles
import * as S from './styled'

export default function Contributions({ children, ...props }) {
	return (
		<S.Container data-testid="contributions-container" {...props}>
			{children}
		</S.Container>
	)
}

Contributions.Wrapper = function ContributionsWrapper({ children, ...props }) {
	return <S.Wrapper {...props}>{children}</S.Wrapper>
}

Contributions.Title = function ContributionsTitle({ children, ...props }) {
	return (
		<S.Title data-testid="contributions-title" {...props}>
			{children}
		</S.Title>
	)
}

Contributions.Head = function ContributionsHead({ children, ...props }) {
	return (
		<S.Head data-testid="contributions-head" {...props}>
			{children}
		</S.Head>
	)
}

Contributions.HeadTitle = function ContributionsHeadTitle({ children, ...props }) {
	return (
		<S.HeadTitle data-testid="contributions-head-title" {...props}>
			{children}
		</S.HeadTitle>
	)
}

Contributions.HeadTotal = function ContributionsHeadTotal({ children, ...props }) {
	return (
		<S.HeadTotal data-testid="contributions-head-total">
			<span className="dollar">$</span>
			{children}
		</S.HeadTotal>
	)
}

Contributions.Group = function ContributionsGroup({ children, ...props }) {
	return (
		<S.Group data-testid="contributions-group" {...props}>
			{children}
		</S.Group>
	)
}

Contributions.MonthBadge = function ContributionsMonthBadge({ children, ...props }) {
	return (
		<S.MonthBadge data-testid="contributions-month-badge" {...props}>
			{children}
		</S.MonthBadge>
	)
}

Contributions.Transactions = function ContributionsTransactions({ children, ...props }) {
	return (
		<S.Transactions data-testid="contributions-transactions" {...props}>
			{children}
		</S.Transactions>
	)
}

Contributions.TransactionItem = function ContributionsTransactionItem({ children, ...props }) {
	return (
		<S.TransactionItem data-testid="contributions-transaction-item" {...props}>
			{children}
		</S.TransactionItem>
	)
}

Contributions.TextBlur = function ContributionsTextBlur({ children, ...props }) {
	return <S.TextBlur {...props}>{children}</S.TextBlur>
}
