import styled from 'styled-components'

// * Constants
import * as BREAK from 'constants/breakpoints'

// * Types
import * as T from './index.types'

export const Container = styled.nav`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 140px;
	padding: 0 40px;
	margin-right: auto;
	margin-left: auto;
	justify-content: space-between;

	@media (max-width: ${BREAK._768}) {
		padding: 0 20px;
		height: 100px;
	}

	@media (max-width: ${BREAK._318}) {
		display: grid;
		gap: 10px;
		justify-items: center;
		justify-content: center;
		height: 100%;
		padding: 20px 10px;
	}
`

export const Profile = styled.div<T.StyledNavbarProfileProps>`
	position: relative;
	background-color: var(--COLOR_GREY_WARM_LIGHT);
	border: 3px solid var(--COLOR_PRIMARY16);
	border-radius: 100%;
	height: 70px;
	width: 70px;
	outline: 0;

	cursor: pointer;

	:hover {
		border: 3px solid var(--COLOR_SECONDARY);
		transition: 300ms;
	}

	& > a {
		position: absolute;
		top: 4px;
		bottom: 4px;
		left: 4px;
		right: 4px;
		border-radius: 100%;
		background-color: var(--COLOR_WHITE);
		box-shadow: var(--SHADOW_SOFT);
		background-image: url(${({ icon }) => icon});
		background-position: 0% 50%;
		background-size: contain;
		background-repeat: no-repeat;
		opacity: 1;
		transition: 300ms;

		:hover {
			opacity: 0.8;
			transition: 300ms;
		}
	}
`

export const Leading = styled.div<T.StyledNavbarLeadingProps>`
	position: relative;
	height: 60px;
	width: 200px;
	margin-right: 10px;
	z-index: 1;

	:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	& a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: url(${({ logo }) => logo});
		background-position: 0% 50%;
		background-size: contain;
		background-repeat: no-repeat;
	}

	@media (max-width: ${BREAK._768}) {
		height: 60px;
		width: 60px;
		margin-right: 0px;

		& a {
			background-image: url(${({ icon }) => icon});
		}
	}
`
export const Center = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	display: grid;
	gap: 40px;
	grid-auto-flow: column;
	justify-content: center;

	@media (max-width: ${BREAK._1400}) {
		position: relative;
	}

	@media (max-width: ${BREAK._1100}) {
		display: none;
	}
`
export const Trailing = styled.div`
	position: relative;
	display: grid;
	gap: 10px;
	grid-auto-flow: column;
	z-index: 1;
`

export const ButtonHrefWrapper = styled.a``

export const Button = styled.button<T.StyledNavbarButtonProps>`
	font-family: var(--FONT_ZARK);
	padding: 0 40px;
	height: 80px;
	border-radius: 8px;
	font-size: var(--TYPE_ML);
	${({ styleType }) => {
		const primaryStyle = `background-color: var(--COLOR_PRIMARY); color: var(--COLOR_SECONDARY);`
		const secondaryStyle = `background-color: var(--COLOR_SECONDARY); color: var(--COLOR_PRIMARY);`
		const outlineStyle = `background-color: var(--COLOR_WHITE); color: var(--COLOR_PRIMARY); border: solid 2px var(--COLOR_PRIMARY);`
		const transparentWhiteStyle = `background-color: transparent; color: var(--COLOR_WHITE);`
		const transparentPrimaryStyle = `background-color: transparent; color: var(--COLOR_PRIMARY);`
		const customEventStyle = `background-color: var(--COLOR_EVENT); color: var(--COLOR_SECONDARY_LIGHT);`

		switch (styleType) {
			case 'primary':
				return primaryStyle
			case 'secondary':
				return secondaryStyle
			case 'outline':
				return outlineStyle
			case 'transparent-white':
				return transparentWhiteStyle
			case 'transparent-primary':
				return transparentPrimaryStyle
			case 'custom-event':
				return customEventStyle
			default:
				return primaryStyle
		}
	}}
	display: flex;
	justify-content: center;
	align-items: center;

	&:disabled {
		background-color: var(--COLOR_GREY);
		color: var(--COLOR_GREY_DARK);
		cursor: not-allowed;
	}

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}

	@media (max-width: ${BREAK._768}) {
		width: auto;
		height: 60px;
		padding: 0 20px;
		font-size: calc(var(--TYPE_ML)-5);
	}
`

export const Link = styled.a`
	font-family: var(--FONT_ZARK);
	font-size: var(--TYPE_ML);
	display: flex;

	&:hover {
		opacity: 0.8;
		transition: 300ms;
	}
`

export const Dropdown = styled.div`
	right: 3px;
	position: absolute;
	width: 240px;
	z-index: -1;
	padding-top: 28px;

	#dropdown-background {
		position: relative;
		top: 42px;
		padding: 5px;
		border-radius: 12px;
		height: 100%;
		box-shadow: var(--SHADOW_SOFT);
		background-color: var(--COLOR_WHITE);
		display: grid;
		gap: 3px;
		z-index: 0;
	}
`

export const DropdownLine = styled.div`
	position: relative;
	justify-self: center;
	width: calc(100% + 10px);
	height: 2px;
	background-color: var(--COLOR_GREY);
	opacity: 0.3;
`

export const DropdownLink = styled.div`
	position: relative;
	height: 52px;
	padding: 15px;
	font-size: var(--TYPE_SL);
	font-weight: 600;
	border-radius: 10px;

	& a {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	:hover {
		background-color: var(--COLOR_SECONDARY_LIGHT);
	}
`

export const VersionApp = styled.p`
	font-family: var(--FONT_TEXT);
	font-size: var(--TYPE_SP);
	color: var(--COLOR_GREY_DARK);
	padding: 0 15px 10px 15px;
	display: flex;
	justify-content: flex-end;
`
