import styled from 'styled-components'
import * as BREAK from 'constants/breakpoints'

export const PickFunds = styled.div`
	margin: 20px 0 10px 0;
	@media (max-width: ${BREAK._1100}) {
		margin: 10px 0 0 0;
	}
`

export const FundWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
	gap: 15px;
	cursor: pointer;
	:hover {
		transition: opacity 300ms ease;
		opacity: 0.8;

		& > a {
			gap: 10px;
		}
	}

	@media (min-width: ${BREAK._1100}) {
		:hover {
			& > div {
				& > div {
					margin-left: -18px;
				}
			}
		}
	}

	@media (max-width: ${BREAK._1100}) {
		gap: 8px;
	}
`

export const FundGroup = styled.div`
	display: flex;
	flex-direction: row;
	& :nth-child(1) {
		margin-left: 0px !important;
	}
`

export const FundItem = styled.div<{ src: string }>`
	margin-left: -20px;
	height: 50px;
	width: 50px;
	box-shadow: var(--SHADOW_SOFT), inset var(--SHADOW_SOFT);
	border: solid 3px white;
	border-radius: 50%;
	background-image: url(${({ src }) => src});
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 300ms ease;

	@media (max-width: ${BREAK._1100}) {
		height: 35px;
		width: 35px;
		border: solid 2px white;
		margin-left: -10px;
	}
`
