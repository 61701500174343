import React, { useContext } from 'react'
import GlobalContext from '../context/Global'

// StoryProps {
//     story: Story;
//     action: Action;
//     playState: boolean;
//     mutedState: boolean;
//     getVideoDuration: Function;
//     bufferAction: boolean;
// }
export const Story = props => {
	const globalContext = useContext(GlobalContext)

	const { width, height, loader, header, storyStyles } = globalContext

	const rendererMessageHandler = (type, data) => {
		switch (type) {
			case 'UPDATE_VIDEO_DURATION':
				props.getVideoDuration(data.duration)
				return { ack: 'OK' }
		}
	}

	const getStoryContent = () => {
		let InnerContent = props.story.content
		let config = { width, height, loader, header, storyStyles }
		return (
			<InnerContent
				action={props.action}
				isMuted={props.mutedState}
				isPaused={props.playState}
				story={props.story}
				config={config}
				messageHandler={rendererMessageHandler}
			/>
		)
	}

	return (
		<div className="StoryMainClass" style={{ ...styles.story, width: '100%', height: '100%' }}>
			{getStoryContent()}
		</div>
	)
}

const styles = {
	story: {
		display: 'flex',
		position: 'relative',
		overflow: 'hidden',
		alignItems: 'center',
		alignSelf: 'center',
	},
	storyContent: {
		width: 'auto',
		maxWidth: '100%',
		maxHeight: '100%',
		margin: 'auto',
	},
}
