import styled from 'styled-components'

export const PlayerContainer = styled.div`
	position: relative;
`

export const PlayerWrapper = styled.div`
	position: absolute;
	left: 100%;
	top: 50%;
	transform: translate(50%, -50%);
`

export const CheckboxLabel = styled.label`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: max-content;
	min-height: 30px;
	margin-bottom: 20px;

	& [type='checkbox'] {
		align-items: center;
		appearance: none;
		background-color: #fff;
		border-radius: 20%;
		border: 1px solid var(--COLOR_GREY);
		cursor: pointer;
		display: flex;
		flex-shrink: 0;
		height: 24px;
		justify-content: center;
		margin: 0;
		width: 24px;

		:checked {
			border: 1px solid var(--COLOR_PRIMARY70);
			&::before {
				background-color: var(--COLOR_SECONDARY);
				border-radius: 20%;
				content: '';
				display: block;
				flex-shrink: 0;
				height: 16px;
				width: 16px;
			}
		}
	}

	& span {
		& a {
			color: var(--COLOR_SECONDARY);
			font-weight: 700;
		}
	}
`
