import React from 'react'

// * Data
import fundData from 'fixtures/funds.json'
import { Square, SquareWrapper } from './styled'

export function useGroupedFunds() {
	return React.useMemo(() => {
		const result = fundData.map(nested => ({
			meta: {
				categoryTitle: nested.title,
				categorySlug: nested.slug,
				categoryHex: nested.hex,
			},
			funds: nested.funds.map(item => ({ ...item })),
		}))
		return result
	}, [])
}

const IndividualFundPick = ({ individualFunds, setIndividualFunds }) => {
	const groupedFundsArray = useGroupedFunds()

	React.useEffect(() => {
		setIndividualFunds([])
	}, [])

	const onFundClick = fundAbbr => {
		const utilFundAbbrArray = groupedFundsArray.flatMap(nested => nested.funds).flatMap(item => item.abbr)

		const fundExistsInArray = individualFunds.includes(fundAbbr)
		const hasUnexpectedItems = individualFunds.some(item => !utilFundAbbrArray.includes(item))
		const hasExpectedItems = individualFunds.some(item => utilFundAbbrArray.includes(item))

		const removeFund = () => setIndividualFunds(individualFunds.filter(item => item !== fundAbbr))
		const setInitialFund = () => setIndividualFunds([fundAbbr])
		const addFund = () => setIndividualFunds([...individualFunds, fundAbbr])

		if (fundExistsInArray) {
			removeFund()
		} else if (hasUnexpectedItems) {
			setInitialFund()
		} else if (hasExpectedItems) {
			addFund()
		} else {
			setInitialFund()
		}
	}

	return (
		<SquareWrapper>
			{groupedFundsArray.map(nested =>
				nested.funds.map(fund => (
					<Square
						key={fund.abbr}
						active={individualFunds.includes(fund.abbr)}
						color={nested.meta.categoryHex}
						src={`/images/assets/${fund.icon}`}
						onClick={() => onFundClick(fund.abbr)}
						title={fund.title}
					>
						<div id="content">
							<div id="header">
								{fund.websiteLink ? (
									<Title link={fund.websiteLink} title={fund.websiteLink}>
										{fund.abbr}
									</Title>
								) : (
									<Title title={fund.websiteLink}>{fund.abbr}</Title>
								)}
								<div id="logo" />
							</div>

							<div id="description">{fund.subtitle}</div>
						</div>
						<div id="tag" color={nested.meta.categoryHex}>
							{nested.meta.categoryTitle}
						</div>
					</Square>
				))
			)}
		</SquareWrapper>
	)
}

// IndividualFundPick
const Title = ({ link, title, children }: { link?: string; title: string; children: React.ReactNode }) => {
	return (
		<div id="title">
			{link ? (
				<a title={title} rel="noopener noreferrer" target="_blank" href={link}>
					{children}
					<img src="/images/icons/icon-link.svg" alt="icon-link" />
				</a>
			) : (
				children
			)}
		</div>
	)
}

export default IndividualFundPick
