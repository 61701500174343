import axios from 'axios'

const setAuthToken = (token: string): void => {
	if (token) {
		// Apply authorization token to every request if logged in
		axios.defaults.headers.common['x-auth-token'] = token
	} else {
		// Delete auth header
		delete axios.defaults.headers.common['x-auth-token']
	}
}

export function checkToken(): boolean {
	const token = localStorage.jwtToken
	if (!token) return false

	token ? setAuthToken(token) : setAuthToken('')
	return true
}
