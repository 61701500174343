import React, { useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import Skeleton from 'react-loading-skeleton'

// * Styles
import * as S from './styled'

// * Components
import { IconMore, IconBank, IconCard, IconAddBank, IconCardRepeat, IconLeftArrow } from 'assets'

export default function Card({ children, ...props }) {
	return (
		<S.Container data-testid="card-container" {...props}>
			{children}
		</S.Container>
	)
}

Card.Heading = function CardHeading({ children, ...props }) {
	return (
		<S.Heading data-testid="card-heading" {...props}>
			{children}
		</S.Heading>
	)
}

Card.Wrapper = function CardWrapper({ children, ...props }) {
	return (
		<S.Wrapper data-testid="card-wrapper" {...props}>
			{children}
		</S.Wrapper>
	)
}

Card.Item = function CardItem({ icon, children, ...props }) {
	return (
		<S.Item data-testid="card-item" {...props}>
			{children}
		</S.Item>
	)
}

Card.InstitutionLogo = function CardInstitutionLogo({ children, ...props }) {
	return <S.InstitutionLogo data-testid="card-institution-logo" {...props} />
}

Card.Leading = function CardLeading({ icon, children, ...props }) {
	return (
		<S.Leading data-testid="card-leading" {...props}>
			{
				{
					bank: <IconBank />,
					add_bank: <IconAddBank />,
					card: <IconCard />,
					card_repeat: <IconCardRepeat />,
				}[icon]
			}
		</S.Leading>
	)
}

Card.Header = function CardHeader({ children, ...props }) {
	return (
		<S.Header data-testid="card-header" {...props}>
			{children}
		</S.Header>
	)
}

Card.Footer = function CardFooter({ children, ...props }) {
	return (
		<S.Footer data-testid="card-footer" {...props}>
			{children}
		</S.Footer>
	)
}

Card.SubscriptionDetails = function CardSubscriptionDetails({ children, ...props }) {
	return <S.SubscriptionDetails {...props}>{children}</S.SubscriptionDetails>
}

Card.SubscriptionInfo = function CardSubscriptionInfo({ icon, children, ...props }) {
	return (
		<S.SubscriptionInfo {...props}>
			<img src={icon} alt={''} /> {children}
		</S.SubscriptionInfo>
	)
}

Card.SubscriptionAmount = function CardSubscriptionAmount({ children, ...props }) {
	return (
		<S.SubscriptionAmount {...props}>
			<span className="dollar">$</span>
			{children}
		</S.SubscriptionAmount>
	)
}

Card.Separator = function CardSeparator({ children, ...props }) {
	return (
		<S.Separator data-testid="card-separator" {...props}>
			{children}
		</S.Separator>
	)
}

Card.Group = function CardGroup({ children, ...props }) {
	return (
		<S.Group data-testid="card-group" {...props}>
			{children}
		</S.Group>
	)
}

Card.Title = function CardTitle({ children, ...props }) {
	return (
		<S.Title data-testid="card-title" {...props}>
			{children}
		</S.Title>
	)
}

Card.Subtitle = function CardSubtitle({ children, ...props }) {
	return (
		<S.Subtitle data-testid="card-subtitle" {...props}>
			{children}
		</S.Subtitle>
	)
}

Card.NestedLink = function CardNestedLink({ children, ...props }) {
	return (
		<S.NestedLink data-testid="card-nested-link" {...props}>
			{children}
		</S.NestedLink>
	)
}

Card.Trailing = function CardTrailing({ hover, children, ...props }) {
	const [open, setOpen] = useState(false)

	return hover ? (
		<S.Trailing data-testid="card-icon-hover" onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)} {...props}>
			<IconMore />
			{open && children}
		</S.Trailing>
	) : (
		<S.Trailing data-testid="card-icon" {...props}>
			<img src="/images/icons/icon-right-chevron.svg" alt="icon-right-chevron" />
		</S.Trailing>
	)
}

Card.Dropdown = function CardDropdown({ children, ...props }) {
	return (
		<S.Dropdown data-testid="card-dropdown" {...props}>
			<div id="dropdown-background">{children}</div>
		</S.Dropdown>
	)
}

Card.DropdownLink = function CardDropdownLink({ children, ...props }) {
	return (
		<S.DropdownLink data-testid="card-dropdown-link" {...props}>
			{children}
		</S.DropdownLink>
	)
}

Card.DropdownLine = function CardDropdownLine({ children, ...props }) {
	return (
		<S.DropdownLine data-testid="card-dropdown-line" {...props}>
			{children}
		</S.DropdownLine>
	)
}

Card.Warning = function CardWarning({ children, ...props }) {
	return (
		<S.Warning data-testid="card-warning" {...props}>
			{children}
		</S.Warning>
	)
}

Card.Button = function CardButton({ children, ...props }) {
	return (
		<S.Button {...props}>
			{children}
			<IconLeftArrow height="20px" />
		</S.Button>
	)
}

Card.Loading = function CardLoading() {
	return <Skeleton inline width={'100%'} height={96} style={{ borderRadius: '12px' }} />
}

Card.ThreeLoading = function CardThreeLoading() {
	return (
		<Player
			autoplay
			loop
			src="/images/lotties/three-dots-loader.json"
			style={{
				height: '100px',
				width: '100px',
			}}
		/>
	)
}

Card.Removing = function CardRemoving() {
	return (
		<Player
			autoplay
			loop
			src="/images/lotties/trash-to-tick.json"
			style={{
				height: '46px',
				width: '40px',
			}}
		/>
	)
}
