import React from 'react'
import { Container, Separator, Tab } from './styled'
import { TabToggleProps } from './types'

const TabToggle = (props: TabToggleProps) => {
	const { options, onChange, styleOption = 'normal', isMaxWidth } = props
	const [selectedIndex, setSelectedIndex] = React.useState(0)

	const handleClick = index => {
		setSelectedIndex(index)
		onChange(options[index])
	}

	return (
		<Container styleOption={styleOption} isMaxWidth={isMaxWidth}>
			{options.map((option, index) => (
				<React.Fragment key={option}>
					<Tab isActive={index === selectedIndex} index={index} styleOption={styleOption} isMaxWidth={isMaxWidth} onClick={() => handleClick(index)}>
						{option}
					</Tab>
					{index !== options.length - 1 && <Separator styleOption={styleOption} />}
				</React.Fragment>
			))}
		</Container>
	)
}

export default TabToggle
