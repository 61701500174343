import { useGroupedFunds } from './PickIndividualFunds'
import fundData from 'fixtures/funds.json'

export function useSelectedIndividualFunds(state) {
	const groupedFundsArray = useGroupedFunds()
	const result = groupedFundsArray?.flatMap(item => item.funds)?.filter(item => state?.includes(item.abbr))
	return result
}

export function useSelectedGroupedFunds(state) {
	const result = fundData?.find(item => item.slug === state)
	return result
}
